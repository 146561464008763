'use client'

import styled from 'styled-components'
import { useState, useEffect, useRef } from 'react'
import hexToRgba from 'hex-to-rgba'
import axios from 'axios'
import PopoverModal from '../Components/ui/PopoverModal'
import { Box, Flex } from '../ui/Layout'
import { Span, Text } from '../ui/Typography'
import Image from '../ui/Image'
import { SmallSpinnerColored } from '../ui/Spinner'
import api from '../api'

const StyledContentEditable = styled.div`
  border: none;
  width: 100%;
  max-height: 120px;
  min-height: 40px;
  height: auto;
  padding: 8px 16px;
  font-size: 16px;
  outline: none;
  background-color: transparent;
  font-family: ${(props) => props.secondaryFontFamily};
  color: ${(props) => props.secondaryFontColor};
  overflow-y: auto;
  line-height: 24px;
  display: block;
  white-space: pre-wrap;
  word-wrap: break-word;

  &[contenteditable]:empty:before {
    content: attr(placeholder);
    color: ${(props) => hexToRgba(props.secondaryFontColor, 0.7)};
    pointer-events: none;
    display: block;
  }
`

const StyledInput = styled.input`
  display: none;
`

const ImageContainer = styled(Flex)`
  width: 100px;
  min-width: 100px;
  height: 100px;
  min-height: 100px;
  border: ${(props) => (props.hasImage ? 'none' : '1px dashed #718096')};
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  margin-right: 16px;
`

const ImageButton = styled(Flex)`
  width: 24px;
  height: 24px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(68, 68, 68, 0.5);
`

const SubmitButton = styled(Flex)`
  opacity: 0;
  transition: opacity 0.2s ease-in;

  ${(props) =>
    props.isVisible &&
    `
    opacity: 1;
  `}
`

export default function WriteComment({
  config,
  inputValue,
  handleTextAreaChange,
  placeholder,
  submit,
  imageUrls,
  setImageUrls,
  replyingTo,
  setReplyingTo,
  threadId
}) {
  const [showPopover, setShowPopover] = useState(false)
  const [files, setFiles] = useState([])
  const [id, setId] = useState()
  const [uploadingImages, setUploadingImages] = useState([])

  const textareaRef = useRef(null)

  useEffect(() => {
    setId(Math.round(Math.random() * 100000))
  }, [])

  useEffect(() => {
    if (threadId) {
      textareaRef.current.innerText = ''
    }
  }, [threadId])

  useEffect(() => {
    if (replyingTo && textareaRef.current) {
      textareaRef.current.focus()
    }
  }, [replyingTo])

  const handleSubmit = async (e) => {
    e.preventDefault()
    await submit()
    if (textareaRef?.current) {
      textareaRef.current.innerText = ''
      textareaRef.current.blur()
    }
    setImageUrls([])
  }

  const uploadImage = async () => {
    const filePicker = document.getElementById(`chat-file-picker-${id}`)
    filePicker.click()
  }

  useEffect(() => {
    const func = async () => {
      if (files && files[0]) {
        const file = files[0]
        const tempUrl = URL.createObjectURL(file)
        setUploadingImages((prev) => [...prev, tempUrl])

        let formData = new FormData()
        formData.append('file', file)
        formData.append('name', file.name)
        formData.append('path', 'clubb-comment-images')

        try {
          const res = await api.post('/comments/image-upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          const url = res.data.payload.url
          setImageUrls([...imageUrls, url])
        } finally {
          setUploadingImages((prev) => prev.filter((u) => u !== tempUrl))
          URL.revokeObjectURL(tempUrl)
        }
      }
    }
    func()
  }, [files])

  return (
    <Flex flexDirection="column">
      {(imageUrls?.length > 0 || uploadingImages.length > 0) && (
        <Flex mb="16px">
          {imageUrls.map((imageUrl) => (
            <ImageContainer hasImage={!!imageUrl} key={imageUrl}>
              <Image width="100px" height="100px" objectFit="cover" src={imageUrl + '?width=300&height=300'} />
              <ImageButton
                position="absolute"
                bottom="2px"
                right="2px"
                onClick={(e) => {
                  e.stopPropagation()
                  setImageUrls(imageUrls.filter((url) => url !== imageUrl))
                }}
              >
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M4.64423 5.13333C4.90444 5.13333 5.11538 5.34227 5.11538 5.6V11.2C5.11538 11.4577 4.90444 11.6667 4.64423 11.6667C4.38402 11.6667 4.17308 11.4577 4.17308 11.2V5.6C4.17308 5.34227 4.38402 5.13333 4.64423 5.13333Z"
                    fill="white"
                  />
                  <path
                    d="M7 5.13333C7.26021 5.13333 7.47115 5.34227 7.47115 5.6V11.2C7.47115 11.4577 7.26021 11.6667 7 11.6667C6.73979 11.6667 6.52885 11.4577 6.52885 11.2V5.6C6.52885 5.34227 6.73979 5.13333 7 5.13333Z"
                    fill="white"
                  />
                  <path
                    d="M9.82692 5.6C9.82692 5.34227 9.61598 5.13333 9.35577 5.13333C9.09556 5.13333 8.88462 5.34227 8.88462 5.6V11.2C8.88462 11.4577 9.09556 11.6667 9.35577 11.6667C9.61598 11.6667 9.82692 11.4577 9.82692 11.2V5.6Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13.125 2.8C13.125 3.31547 12.7031 3.73333 12.1827 3.73333H11.7115V12.1333C11.7115 13.1643 10.8678 14 9.82692 14H4.17308C3.13223 14 2.28846 13.1643 2.28846 12.1333V3.73333H1.81731C1.29689 3.73333 0.875 3.31547 0.875 2.8V1.86667C0.875 1.3512 1.29689 0.933333 1.81731 0.933333H5.11538C5.11538 0.417868 5.53727 0 6.05769 0H7.94231C8.46273 0 8.88462 0.417868 8.88462 0.933333H12.1827C12.7031 0.933333 13.125 1.3512 13.125 1.86667V2.8ZM3.34199 3.73333L3.23077 3.78842V12.1333C3.23077 12.6488 3.65265 13.0667 4.17308 13.0667H9.82692C10.3473 13.0667 10.7692 12.6488 10.7692 12.1333V3.78842L10.658 3.73333H3.34199ZM1.81731 2.8V1.86667H12.1827V2.8H1.81731Z"
                    fill="white"
                  />
                </svg>
              </ImageButton>
            </ImageContainer>
          ))}
          {uploadingImages.map((tempUrl) => (
            <ImageContainer hasImage key={tempUrl}>
              <Image width="100px" height="100px" objectFit="cover" src={tempUrl} style={{ opacity: 0.5 }} />
              <Flex
                position="absolute"
                top="50%"
                left="50%"
                style={{ transform: 'translate(-50%, -50%)' }}
                alignItems="center"
                justifyContent="center"
              >
                <SmallSpinnerColored color={config.primaryFontColor} lightColor={`${config.primaryFontColor}33`} />
              </Flex>
            </ImageContainer>
          ))}
        </Flex>
      )}
      {replyingTo ? (
        <Flex alignItems="center" mb="16px" justifyContent="space-between">
          <Text light fontSize="12px" lineHeight="14px">
            Replying to <Span fontWeight="700">{replyingTo.chatName || replyingTo.firstName}</Span>
          </Text>
          <Box cursor="pointer" onClick={() => setReplyingTo(null)}>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L7 5.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L8.41421 7L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L7 8.41421L1.70711 13.7071C1.31658 14.0976 0.683417 14.0976 0.292893 13.7071C-0.0976311 13.3166 -0.0976311 12.6834 0.292893 12.2929L5.58579 7L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
                fill={config.secondaryFontColor}
              />
            </svg>
          </Box>
        </Flex>
      ) : null}
      <Flex>
        <Flex position="relative" alignItems="center" width="auto">
          <Flex cursor="pointer" alignItems="center" width="auto" mr="8px" onClick={() => setShowPopover(!showPopover)}>
            <svg width="15" minWidth="15px" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.00003 0.642822C6.47341 0.642822 6.85717 1.02658 6.85717 1.49997V6.64282H12C12.4734 6.64282 12.8572 7.02658 12.8572 7.49996C12.8572 7.97335 12.4734 8.35711 12 8.35711H6.85717V13.5C6.85717 13.9734 6.47341 14.3571 6.00003 14.3571C5.52664 14.3571 5.14288 13.9734 5.14288 13.5V8.35711H2.61068e-05C-0.473361 8.35711 -0.857117 7.97335 -0.857117 7.49996C-0.857117 7.02658 -0.473361 6.64282 2.61068e-05 6.64282H5.14288V1.49997C5.14288 1.02658 5.52664 0.642822 6.00003 0.642822Z"
                fill={config.secondaryFontColor}
              />
            </svg>
          </Flex>
          <PopoverModal
            isOpen={showPopover}
            setIsOpen={setShowPopover}
            width="170px"
            bottom
            borderRadius="16px"
            leftOveride="-28px"
            bottomOveride="26px"
            config={config}
          >
            <Flex
              p="12px"
              cursor="pointer"
              alignItems="center"
              _hover={{ backgroundColor: config.primaryFontColor, color: config.backgroundColor }}
              onClick={() => {
                uploadImage()
                setShowPopover(false)
              }}
            >
              <Flex alignItems="center" ml="4px">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M14.0015 2H2.00146C1.44918 2 1.00146 2.44772 1.00146 3V12L3.64791 9.64646C3.81644 9.47793 4.0805 9.45178 4.27882 9.58399L6.93776 11.3566L10.6479 7.64646C10.8001 7.49428 11.0326 7.45655 11.2251 7.5528L15.0015 9.50001V3C15.0015 2.44772 14.5537 2 14.0015 2ZM2.00146 1C0.896895 1 0.00146484 1.89543 0.00146484 3V13C0.00146484 14.1046 0.896895 15 2.00146 15H14.0015C15.106 15 16.0015 14.1046 16.0015 13V3C16.0015 1.89543 15.106 1 14.0015 1H2.00146ZM6.00146 5.5C6.00146 6.32843 5.32989 7 4.50146 7C3.67304 7 3.00146 6.32843 3.00146 5.5C3.00146 4.67157 3.67304 4 4.50146 4C5.32989 4 6.00146 4.67157 6.00146 5.5Z"
                    fill={config.primaryFontColor}
                  />
                </svg>

                <Text ml="12px" fontSize="16px" fontWeight="500">
                  Photo
                </Text>
              </Flex>
            </Flex>
          </PopoverModal>
        </Flex>

        <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: 'calc(100% - 23px);' }}>
          <Flex alignItems="center" width="100%">
            <Flex alignItems="center" width="100%" borderRadius="22px" border={`1px solid rgba(62, 91, 96, 0.10);`} pr="6px">
              <StyledContentEditable
                ref={textareaRef}
                contentEditable
                placeholder={placeholder}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault()
                    handleSubmit(e)
                  }
                }}
                onInput={() => {
                  const rawContent = textareaRef.current.innerHTML

                  // Handle plain text line breaks (when using Shift+Enter)
                  if (!rawContent.includes('<')) {
                    handleTextAreaChange(rawContent)
                    return
                  }

                  // Handle HTML line breaks
                  const text = rawContent
                    .replace(/<div><br><\/div>/gi, '\n') // Empty lines
                    .replace(/<div>/gi, '\n') // Replace opening div tags with newlines
                    .replace(/<\/div>/gi, '') // Remove closing div tags
                    .replace(/<br>/gi, '\n') // Replace <br> with newlines
                    .replace(/&nbsp;/g, ' ') // Replace non-breaking spaces
                    .replace(/\n+/g, '\n') // Collapse multiple newlines into one
                    .trim() // Remove extra whitespace

                  // Remove any HTML tags that might remain
                  const decodedText = new DOMParser().parseFromString(text, 'text/html').body.textContent || ''
                  handleTextAreaChange(decodedText)
                }}
                primaryFontColor={config.primaryFontColor}
                secondaryFontColor={config.secondaryFontColor}
                secondaryFontFamily={config.secondaryFontFamily}
              />

              <SubmitButton
                isVisible={!!inputValue?.trim()}
                cursor="pointer"
                alignItems="center"
                justifyContent="center"
                onClick={handleSubmit}
                backgroundColor={config.primaryButtonColor}
                borderRadius="22px"
                height="30px"
                width="30px"
                minWidth="30px"
              >
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1 8C1 7.72386 1.22386 7.5 1.5 7.5L13.2929 7.5L10.1464 4.35355C9.95118 4.15829 9.95118 3.84171 10.1464 3.64645C10.3417 3.45118 10.6583 3.45118 10.8536 3.64645L14.8536 7.64645C15.0488 7.84171 15.0488 8.15829 14.8536 8.35355L10.8536 12.3536C10.6583 12.5488 10.3417 12.5488 10.1464 12.3536C9.95118 12.1583 9.95118 11.8417 10.1464 11.6464L13.2929 8.5H1.5C1.22386 8.5 1 8.27614 1 8Z"
                    fill={config.primaryButtonFontColor}
                  />
                </svg>
              </SubmitButton>
            </Flex>

            <StyledInput
              type="file"
              id={`chat-file-picker-${id}`}
              name="file-picker"
              accept={'image/jpg, image/png, image/jpeg'}
              multiple={false}
              onChange={(e) => {
                const filePicker = document.getElementById(`chat-file-picker-${id}`)
                if (filePicker.files[0]) {
                  setFiles(Array.from(filePicker.files))
                }
              }}
            />
          </Flex>
        </form>
      </Flex>
    </Flex>
  )
}
