import React, { useState } from 'react'
import styled from 'styled-components'
import { Flex, Box } from '../../ui/Layout.js'
import { Text } from '../../ui/Typography.js'
import Button from '../../ui/Button.js'
import ModalContainer from '../../ui/ModalContainer.js'
import Icon from '../../ui/Icon.js'
import { ReactSortable } from 'react-sortablejs'
import ToggleTwoOptions from '../../ui/ToggleTwoOptions.js'
import { colors } from '../../ui/helpers.js'
import { Input, TextArea } from '../../ui/Input.js'
import FeatureSvgs from './featureSvgs.js'
import FeatureImage from './FeatureImage.js'
import { useTheme } from '../../ThemeProvider.js'

const FeatureCard = styled(Flex)`
  height: 60px;
  padding: 16px;
  margin-bottom: 8px;
  cursor: pointer;
  border-radius: 6px;
  box-shadow: 0px 0px 1px 0px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  background-color: ${(props) => (props.isActive ? 'white' : '#F7FAFC')};
  &:hover {
    background-color: ${(props) => (props.isActive ? 'rgba(235, 242, 250, 1)' : '#F7FAFC')};
  }
`

const GrabIcon = styled(Icon)`
  cursor: grab;
`

const FeatureIcon = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 4px;
  background: transparent;
`

export const features = [
  {
    id: 1,
    type: 'build-your-own',
    title: 'Build your own',
    subtitle: 'Personalised meal plans and automated shopping lists.',
    isActive: true
  },
  {
    id: 2,
    type: 'newsletter',
    title: 'Newsletter',
    subtitle: 'Recipes and content all about foot, straight into your inbox.',
    isActive: true
  },
  {
    id: 3,
    type: 'serving-adjustments',
    title: 'Serving adjustments',
    subtitle: 'Make every recipe and shopping list perfect for you.',
    isActive: true
  },
  {
    id: 4,
    type: 'meal-plans',
    title: 'Meal Plans',
    subtitle: 'Pre-made meal plans to make your week and shopping easier.',
    isActive: true
  },
  {
    id: 5,
    type: 'nutrition',
    title: 'Nutrition',
    subtitle: 'Stay on track and pick the right meals to hit your goals.',
    isActive: true
  },
  {
    id: 6,
    type: 'favorites',
    title: 'Favorites',
    subtitle: 'Create a collection of the recipes you love to make.',
    isActive: true
  },
  {
    id: 7,
    type: 'shopping-lists',
    title: 'Shopping lists',
    subtitle: 'Build your own or generate automated shopping lists.',
    isActive: true
  },
  {
    id: 8,
    type: 'community',
    title: 'Community',
    subtitle: 'Join the community chat and share your content.',
    isActive: true
  },
  {
    id: 9,
    type: 'recipes',
    title: 'Recipes',
    subtitle: 'Hundreds of recipes and new recipes every week.',
    isActive: true
  }
]

const FeaturesSection = ({ isOpen, setIsOpen, section, sections, setSections, sectionIndex }) => {
  const { theme } = useTheme()

  const config = {
    backgroundColor: theme.backgroundColor,
    primaryButtonColor: theme.primaryButtonColor,
    primaryButtonFontColor: theme.primaryButtonFontColor,
    primaryFontFamily: theme.primaryFontFamily,
    secondaryFontFamily: theme.secondaryFontFamily,
    primaryFontColor: theme.primaryFontColor,
    secondaryFontColor: theme.secondaryFontColor
  }

  const [editingFeature, setEditingFeature] = useState(null)
  const [showEditView, setShowEditView] = useState(false)

  const handleToggle = (id) => {
    const updatedFeatures = section.features.map((feature) =>
      feature.id === id ? { ...feature, isActive: !feature.isActive } : feature
    )

    const newSections = [...sections]
    newSections[sectionIndex] = { ...section, features: updatedFeatures }
    setSections(newSections)
  }

  const handleSave = () => {
    setIsOpen(false)
  }

  const handleFeatureClick = (feature) => {
    setEditingFeature(feature)
    setShowEditView(true)
  }

  const handleFeatureUpdate = (field, value) => {
    setEditingFeature({
      ...editingFeature,
      [field]: value
    })
  }

  const saveFeatureEdit = () => {
    const updatedFeatures = section.features.map((feature) => (feature.id === editingFeature.id ? editingFeature : feature))

    const newSections = [...sections]
    newSections[sectionIndex] = { ...section, features: updatedFeatures }
    setSections(newSections)

    setShowEditView(false)
    setEditingFeature(null)
  }

  const handleBackToList = () => {
    setShowEditView(false)
    setEditingFeature(null)
  }

  return (
    <ModalContainer width="640px" autoHeight isOpen={isOpen} setIsOpen={setIsOpen} p="0px" interiorClose>
      {!showEditView ? (
        <Flex flexDirection="column" width="100%" maxHeight="600px" height="600px">
          <Box position="sticky" top="0" bg="white" zIndex="1" borderBottom="1px solid #E4ECF7" minHeight="64px" height="64px">
            <Flex alignItems="center" height="100%">
              <Text fontSize="18px" fontWeight="600" px="24px" lineHeight="normal">
                Features Carousel
              </Text>
            </Flex>
          </Box>

          <Flex flexDirection="column" px="24px" overflow="auto" pt="16px">
            <ReactSortable
              list={section?.features || []}
              setList={(newFeatures) => {
                const newSections = [...sections]
                newSections[sectionIndex] = { ...section, features: newFeatures }
                setSections(newSections)
              }}
              animation={200}
            >
              {section?.features &&
                section.features.map((feature) => {
                  return (
                    <FeatureCard
                      key={feature.id}
                      alignItems="center"
                      justifyContent="space-between"
                      isActive={!!feature.isActive}
                      onClick={() => handleFeatureClick(feature)}
                    >
                      <Flex alignItems="center" flex={1}>
                        <GrabIcon mr="16px" icon="drag-circles-grey" width="24px" height="24px" alt="drag icon" />
                        <FeatureIcon mr="12px">
                          {FeatureSvgs[feature.type] ? FeatureSvgs[feature.type](config) : null}
                        </FeatureIcon>
                        <Flex flexDirection="column" flex={1} opacity={feature.isActive ? 1 : 0.5}>
                          <Text fontWeight="600">{feature.title}</Text>
                        </Flex>
                      </Flex>
                      <Flex alignItems="center" width="auto">
                        <ToggleTwoOptions
                          value1={false}
                          value2={true}
                          selected={!!feature.isActive}
                          setSelected={(value) => handleToggle(feature.id)}
                          customActiveColor={colors.green}
                          onClick={(e) => e.stopPropagation()}
                        />
                        <Icon icon="chevron-right-black" width="16px" height="16px" ml="16px" />
                      </Flex>
                    </FeatureCard>
                  )
                })}
            </ReactSortable>
          </Flex>

          <Box position="sticky" bottom="0" bg="white" zIndex="1">
            <Flex justifyContent="flex-end" height="64px" borderTop="1px solid #E4ECF7" alignItems="center" px="24px">
              <Button width="100%" height="46px" borderRadius="8px" variant="green" label="Done" onClick={handleSave} />
            </Flex>
          </Box>
        </Flex>
      ) : (
        <Flex flexDirection="column" width="100%">
          <Box position="sticky" top="0" bg="white" zIndex="1" borderBottom="1px solid #E4ECF7" minHeight="64px" height="64px">
            <Flex alignItems="center" height="100%">
              <Icon
                icon="chevron-left-black"
                width="16px"
                height="16px"
                ml="24px"
                mr="16px"
                cursor="pointer"
                onClick={handleBackToList}
              />
              <Text fontSize="18px" fontWeight="600" lineHeight="normal">
                Content
              </Text>
            </Flex>
          </Box>

          <Flex my="24px" justifyContent="center">
            <FeatureImage
              type={editingFeature.type}
              title={editingFeature.title}
              subtitle={editingFeature.subtitle}
              config={config}
            />
          </Flex>

          <Flex flexDirection="column" p="24px">
            <Input
              dark
              label="Title"
              placeholder="Feature title"
              value={editingFeature ? editingFeature.title : ''}
              onChange={(e) => handleFeatureUpdate('title', e.target.value)}
              mb="16px"
            />
            <Input
              dark
              label="Body"
              placeholder="Feature subtitle"
              value={editingFeature ? editingFeature.subtitle : ''}
              onChange={(e) => handleFeatureUpdate('subtitle', e.target.value)}
              mb="16px"
            />
          </Flex>

          <Box position="sticky" bottom="0" bg="white" zIndex="1">
            <Flex justifyContent="flex-end" height="64px" borderTop="1px solid #E4ECF7" alignItems="center" px="24px">
              <Button width="100%" height="46px" borderRadius="8px" variant="green" label="Done" onClick={saveFeatureEdit} />
            </Flex>
          </Box>
        </Flex>
      )}
    </ModalContainer>
  )
}

export default FeaturesSection
