import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { colors } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle, Label } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import formikFormWrapper from '../formikFormWrapper.js'
import { Formik, Field } from 'formik'
import SelectComponent from '../ui/Select.js'
import { currencyOptions } from './types.js'
import { Input, TextArea } from '../ui/Input.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import Button from '../ui/Button.js'
import ImageUploadModal from '../ui/ImageUploadModal.js'
import Image from '../ui/Image.js'
import Icon from '../ui/Icon.js'
import { useTheme } from '../ThemeProvider.js'
import Preview from './Preview.js'
import usePrompt from '../ui/BlockerPrompt.js'
import PreviewPaywall from './PreviewPaywall.js'
import Toggle from '../ui/Toggle.js'
const FormInput = formikFormWrapper(Input)
const FormTextArea = formikFormWrapper(TextArea)

const TABS = {
  MONTHLY: 'MONTHLY',
  ANNUAL: 'ANNUAL',
  PREMIUM: 'PREMIUM'
}

const TabText = styled(Text)`
  ${(props) => !props.isActive && `color: ${colors.textLight};`}
  padding-bottom: 20px;
  ${(props) => props.isActive && `border-bottom: 1px solid ${colors.green};`}
  font-weight: 600;
  margin-right: 30px;
  cursor: pointer;
  position: relative;
`

const ErrorDot = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${colors.error};
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: -10px;
`

const Tab = ({ onClick, title, isActive, hasError }) => {
  return (
    <TabText onClick={onClick} isActive={isActive} light={isActive ? undefined : true}>
      {title}
      {hasError && <ErrorDot />}
    </TabText>
  )
}

document.addEventListener('wheel', function (event) {
  if (document.activeElement.type === 'number') {
    document.activeElement.blur()
  }
})

const Plans = (props) => {
  let { user, setUser } = useAuth()
  const { theme } = useTheme()
  const [inputPlans, setInputPlans] = useState()
  const [inputPaywall, setInputPaywall] = useState({})
  const [imageUploadOpen, setImageUploadOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [activeTab, setActiveTab] = useState(TABS.MONTHLY)
  const [showPlansPreview, setShowPlansPreview] = useState(true)

  const refresh = async () => {
    const res = await api.get(`/plans/get?organisationId=${user.organisationId}`)
    setInputPlans(res.data.payload.plans)
    setInputPaywall(res.data.payload.paywall)
    setIsLoading(false)
  }

  useEffect(() => {
    refresh()
  }, [])

  const maybeMonthly = (inputPlans || []).find((plan) => plan.type === 'MONTHLY')
  const maybeAnnual = (inputPlans || []).find((plan) => plan.type === 'ANNUAL')
  const maybePremium = (inputPlans || []).find((plan) => plan.type === 'PREMIUM')
  return (
    <>
      <Flex flexDirection="column" alignItems="center">
        {!isLoading ? (
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={
              inputPlans && inputPlans.length
                ? {
                    currency: inputPlans[0]?.currency || inputPlans[1]?.currency || inputPlans[2]?.currency,
                    freeTrialDays: inputPlans[0]?.freeTrialDays || inputPlans[1]?.freeTrialDays || inputPlans[2]?.freeTrialDays,
                    monthlyPrice: maybeMonthly?.price,
                    annualPrice: maybeAnnual?.price,
                    premiumPrice: maybePremium?.price,
                    premiumName: maybePremium?.name,
                    monthly: {
                      1: maybeMonthly?.features[0],
                      2: maybeMonthly?.features[1],
                      3: maybeMonthly?.features[2]
                    },
                    annual: {
                      1: maybeAnnual?.features[0],
                      2: maybeAnnual?.features[1],
                      3: maybeAnnual?.features[2]
                    },
                    premium: {
                      1: maybePremium?.features[0],
                      2: maybePremium?.features[1],
                      3: maybePremium?.features[2]
                    },
                    paywall: {
                      title: inputPaywall?.title,
                      1: inputPaywall?.features[0],
                      2: inputPaywall?.features[1],
                      3: inputPaywall?.features[2],
                      imageUrl: inputPaywall?.imageUrl
                    }
                  }
                : {
                    currency: 'GBP',
                    monthlyPrice: 4.99,
                    annualPrice: 50,
                    monthly: {
                      1: 'Tell them something amazing',
                      2: 'And something even better',
                      3: "They'll be sure to join!"
                    },
                    annual: {
                      1: 'Tell them something amazing',
                      2: 'And something even better',
                      3: "They'll be sure to join!"
                    },
                    paywall: {
                      title: 'Join the Clubb',
                      1: 'Be clear as to why people should sign up.',
                      2: 'Let them know the benefits.',
                      3: 'And make them feel good!',
                      imageUrl: 'https://clipara.b-cdn.net/clubb-images/9a9b7eaf-3ca9-4b88-b452-bf87bda9d6ef.jpg'
                    }
                  }
            }
            validate={(values) => {
              const errors = {}
              if (values.monthlyPrice === undefined || values.monthlyPrice === null || values.monthlyPrice === '') {
                errors.monthlyPrice = 'Required'
              } else if (values.monthlyPrice <= 0) {
                errors.monthlyPrice = 'Price must be greater than 0'
              } else if (values.monthlyPrice < 2.5) {
                errors.monthlyPrice = 'Minimum monthly price is 2.5'
              } else if (!/^\d+(\.\d{1,2})?$/.test(values.monthlyPrice)) {
                errors.monthlyPrice = 'Maximum 2 decimal places allowed'
              }

              if (values.annualPrice !== undefined && values.annualPrice !== null && values.annualPrice !== '') {
                if (values.annualPrice <= 0) {
                  errors.annualPrice = 'Price must be greater than 0'
                } else if (values.annualPrice < 10) {
                  errors.annualPrice = 'Minimum annual price is 10'
                } else if (!/^\d+(\.\d{1,2})?$/.test(values.annualPrice)) {
                  errors.annualPrice = 'Maximum 2 decimal places allowed'
                }
              }

              if (values.premiumPrice !== undefined && values.premiumPrice !== null && values.premiumPrice !== '') {
                if (values.premiumPrice <= 0) {
                  errors.premiumPrice = 'Price must be greater than 0'
                } else if (!/^\d+(\.\d{1,2})?$/.test(values.premiumPrice)) {
                  errors.premiumPrice = 'Maximum 2 decimal places allowed'
                }
                if (!values.premiumName) {
                  errors.premiumName = 'Required'
                }
              }

              return errors
            }}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true)
              try {
                const plans = [
                  {
                    currency: values.currency,
                    freeTrialDays: values.freeTrialDays || 0,
                    type: 'MONTHLY',
                    price: values.monthlyPrice,
                    features: [
                      values.monthly && values.monthly['1'],
                      values.monthly && values.monthly['2'],
                      values.monthly && values.monthly['3']
                    ].filter((el) => el)
                  },
                  {
                    currency: values.currency,
                    freeTrialDays: values.freeTrialDays || 0,
                    type: 'ANNUAL',
                    price: values.annualPrice,
                    features: [
                      values.annual && values.annual['1'],
                      values.annual && values.annual['2'],
                      values.annual && values.annual['3']
                    ].filter((el) => el)
                  },
                  {
                    currency: values.currency,
                    freeTrialDays: values.freeTrialDays || 0,
                    type: 'PREMIUM',
                    name: values.premiumName,
                    price: values.premiumPrice,
                    features: [
                      values.premium && values.premium['1'],
                      values.premium && values.premium['2'],
                      values.premium && values.premium['3']
                    ].filter((el) => el)
                  }
                ]
                const res = await api.post('/plans/update', {
                  plans,
                  paywall: {
                    title: values.paywall && values.paywall.title,
                    imageUrl: values.paywall && values.paywall.imageUrl,
                    features: [
                      values.paywall && values.paywall['1'],
                      values.paywall && values.paywall['2'],
                      values.paywall && values.paywall['3']
                    ].filter((el) => el)
                  }
                })
                setTimeout(() => resetForm({ values: values }), 500)
                console.log(res)
              } catch (e) {
                console.log(e)
              }

              setSubmitting(false)
            }}
          >
            {({ values, dirty, errors, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => {
              console.log(errors)
              console.log(values)
              usePrompt("Are you sure you want to leave this page? You'll lose your changes", dirty)
              const isDisabled = !dirty
              const hasFormErrors = Object.keys(errors).length > 0

              return (
                <Flex position="relative">
                  <Flex
                    px="32px"
                    pl="16px"
                    pt="40px"
                    position="relative"
                    flexDirection="column"
                    borderRight={user.stripeConnectAccountId ? '1px solid rgba(166, 183, 212, 1)' : 'none'}
                    width="60%"
                    alignItems="center"
                  >
                    <Flex flexDirection="column" maxWidth="640px">
                      <Flex position="relative" flexDirection="column">
                        <Flex>
                          <H1 mb="24px" width="100%">
                            Plans
                          </H1>
                        </Flex>
                      </Flex>
                      {!user.stripeConnectAccountId ? (
                        <Container p="24px" justifyContent="space-between" mb="32px" position="relative" maxWidth="640px">
                          <H3 mb="16px">Pricing</H3>
                          <Text light mb="16px">
                            Set your pricing for all plans you want to offer. Leaving plan prices empty will mean they are not
                            available.
                          </Text>
                          <Flex
                            px="20px"
                            py="16px"
                            borderRadius="16px"
                            backgroundColor={colors.green}
                            alignItems="center"
                            justifyContent="space-between"
                            boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
                          >
                            <Text color="white" fontWeight="500">
                              Connect your Stripe account first to get started.
                            </Text>
                            <Link to="/settings">
                              <Button label="Connect Stripe" backgroundColor="white" fontColorOveride={colors.green} />
                            </Link>
                          </Flex>
                        </Container>
                      ) : (
                        <>
                          <Container p="24px" justifyContent="space-between" mb="32px">
                            <H3 mb="16px">Currency</H3>
                            <SelectComponent
                              width="200px"
                              value={values.currency}
                              onChange={(option) => {
                                setFieldValue(`currency`, option.value)
                              }}
                              options={currencyOptions}
                              placeholder="Select"
                            />
                          </Container>
                          <Container p="24px" justifyContent="space-between" mb="32px" position="relative">
                            <H3 mb="8px">Pricing</H3>
                            <Text light mb="32px">
                              Set your pricing for all plans you want to offer. Leaving plan prices empty will mean they are not
                              available.
                            </Text>

                            <Flex mb="32px">
                              <Tab
                                onClick={() => setActiveTab(TABS.MONTHLY)}
                                title="Monthly"
                                isActive={activeTab === TABS.MONTHLY}
                                hasError={errors.monthlyPrice}
                              />
                              <Tab
                                onClick={() => setActiveTab(TABS.ANNUAL)}
                                title="Annual"
                                isActive={activeTab === TABS.ANNUAL}
                                hasError={errors.annualPrice}
                              />
                              <Tab
                                onClick={() => setActiveTab(TABS.PREMIUM)}
                                title="Premium Annual"
                                isActive={activeTab === TABS.PREMIUM}
                                hasError={errors.premiumPrice || errors.premiumName}
                              />
                            </Flex>
                            {activeTab === TABS.MONTHLY ? (
                              <>
                                <Flex>
                                  <Field
                                    large
                                    name="monthlyPrice"
                                    label="Price"
                                    mb="32px"
                                    min={2.5}
                                    type="number"
                                    component={FormInput}
                                    dark
                                  />
                                </Flex>
                                <Text light mb="8px">
                                  Use features to showcase why people should subscribe to your site.
                                </Text>
                                <Field
                                  label="Feature One"
                                  maxLength={100}
                                  large
                                  name="monthly.1"
                                  mb="16px"
                                  component={FormInput}
                                  dark
                                />
                                <Field
                                  label="Feature Two"
                                  maxLength={100}
                                  large
                                  name="monthly.2"
                                  mb="16px"
                                  component={FormInput}
                                  dark
                                />
                                <Field
                                  label="Feature Three"
                                  maxLength={100}
                                  large
                                  name="monthly.3"
                                  mb="16px"
                                  component={FormInput}
                                  dark
                                />
                              </>
                            ) : null}

                            {activeTab === TABS.ANNUAL ? (
                              <>
                                <Flex>
                                  <Field
                                    large
                                    name="annualPrice"
                                    label="Price"
                                    mb="32px"
                                    min={10}
                                    type="number"
                                    component={FormInput}
                                    dark
                                  />
                                </Flex>
                                <Text light mb="8px">
                                  Use features to showcase why people should subscribe to your site.
                                </Text>
                                <Field
                                  label="Feature One"
                                  maxLength={100}
                                  large
                                  name="annual.1"
                                  mb="16px"
                                  component={FormInput}
                                  dark
                                />
                                <Field
                                  label="Feature Two"
                                  maxLength={100}
                                  large
                                  name="annual.2"
                                  mb="16px"
                                  component={FormInput}
                                  dark
                                />
                                <Field
                                  label="Feature Three"
                                  maxLength={100}
                                  large
                                  name="annual.3"
                                  mb="16px"
                                  component={FormInput}
                                  dark
                                />
                              </>
                            ) : null}

                            {activeTab === TABS.PREMIUM ? (
                              <>
                                <Field large name="premiumName" label="Name" mb="16px" component={FormInput} dark />
                                <Flex>
                                  <Field
                                    large
                                    name="premiumPrice"
                                    label="Price"
                                    min={1}
                                    mb="32px"
                                    type="number"
                                    component={FormInput}
                                    dark
                                  />
                                </Flex>
                                <Text light mb="8px">
                                  Use features to showcase why people should subscribe to your site.
                                </Text>
                                <Field
                                  label="Feature One"
                                  maxLength={100}
                                  large
                                  name="premium.1"
                                  mb="16px"
                                  component={FormInput}
                                  dark
                                />
                                <Field
                                  label="Feature Two"
                                  maxLength={100}
                                  large
                                  name="premium.2"
                                  mb="16px"
                                  component={FormInput}
                                  dark
                                />
                                <Field
                                  label="Feature Three"
                                  maxLength={100}
                                  large
                                  name="premium.3"
                                  mb="16px"
                                  component={FormInput}
                                  dark
                                />
                              </>
                            ) : null}
                          </Container>

                          <Container p="24px" justifyContent="space-between" mb="32px">
                            <H3 mb="16px">Free Trial</H3>
                            <Field
                              large
                              name="freeTrialDays"
                              label="Free Trial Days"
                              type="number"
                              min="0"
                              max="30"
                              component={FormInput}
                              dark
                            />
                            <Text light fontSize="12px">
                              Set to zero for no free trial
                            </Text>
                          </Container>
                          <Container p="24px" justifyContent="space-between" mb="32px">
                            <H3 mb="16px">Paywall</H3>
                            <Field large name="paywall.title" label="Title" mb="32px" component={FormInput} dark />
                            <Text light mb="8px">
                              Use features to showcase why people should subscribe to your site.
                            </Text>
                            <Field
                              label="Feature One"
                              maxLength={70}
                              large
                              name="paywall.1"
                              mb="16px"
                              component={FormInput}
                              dark
                            />
                            <Field
                              label="Feature Two"
                              maxLength={70}
                              large
                              name="paywall.2"
                              mb="16px"
                              component={FormInput}
                              dark
                            />
                            <Field
                              label="Feature Three"
                              maxLength={70}
                              large
                              name="paywall.3"
                              mb="16px"
                              component={FormInput}
                              dark
                            />
                          </Container>
                          <Container p="24px" justifyContent="space-between" mb="32px">
                            <H3 mb="16px">Media</H3>
                            <Text light mb="8px">
                              Add an image for your payment modals and upsells.
                            </Text>
                            <ImageUploadModal
                              isOpen={imageUploadOpen}
                              setIsOpen={setImageUploadOpen}
                              saveFunction={(url) => {
                                setFieldValue('paywall.imageUrl', url[0])
                                setImageUploadOpen(false)
                              }}
                            />
                            {values.paywall.imageUrl ? (
                              <Box width="120px" height="120px" onClick={() => setImageUploadOpen(true)} cursor="pointer">
                                <Image
                                  src={values.paywall.imageUrl + '?width=400&height=400'}
                                  width="120px"
                                  height="120px"
                                  objectFit="cover"
                                  borderRadius="16px"
                                />
                              </Box>
                            ) : (
                              <Flex
                                borderRadius="16px"
                                width="120px"
                                height="120px"
                                cursor="pointer"
                                justifyContent="center"
                                alignItems="center"
                                backgroundColor="rgba(235, 242, 250, 1)"
                                onClick={() => setImageUploadOpen(true)}
                              >
                                <Icon icon="plus-dark-grey" width="20px" height="20px" />
                              </Flex>
                            )}
                          </Container>
                          <Flex justifyContent="flex-end" alignItems="center">
                            {hasFormErrors && (
                              <Error mr="16px" mb="32px">
                                Please check form errors
                              </Error>
                            )}
                            <Button
                              isLoading={isSubmitting}
                              isDisabled={!dirty}
                              variant="green"
                              label="Save"
                              onClick={handleSubmit}
                              mb="32px"
                            />
                          </Flex>
                        </>
                      )}
                    </Flex>
                  </Flex>

                  {user.stripeConnectAccountId ? (
                    <Flex px="16px" flexDirection="column" pt="16px" alignItems="center" width="40%" minHeight="100vh">
                      <Flex position="sticky" top="16px" flexDirection="column" alignItems="center">
                        <Flex justifyContent="flex-end" alignItems="center">
                          {hasFormErrors && (
                            <Error mr="16px" mb="32px">
                              Please check form errors
                            </Error>
                          )}
                          <Button
                            mr="16px"
                            isLoading={isSubmitting}
                            isDisabled={!dirty}
                            variant="green"
                            label="Save"
                            onClick={handleSubmit}
                            mb="32px"
                          />
                          <a href={`https://${user.subdomain}.theclubb.co/`} target="_blank" rel="noreferrer">
                            <Button
                              renderLeftIcon={() => <Icon icon="diagonal-arrow-grey" width="12px" height="12px" />}
                              variant="secondary"
                              label="Open"
                              mb="32px"
                            />
                          </a>
                        </Flex>
                        {!showPlansPreview ? <PreviewPaywall values={values} /> : <Preview values={values} />}

                        <Flex width="auto" mt="24px">
                          <Toggle
                            label1="Plans"
                            label2="Paywall"
                            value1={true}
                            value2={false}
                            selected={showPlansPreview}
                            setSelected={(x) => setShowPlansPreview(x)}
                          />
                        </Flex>
                      </Flex>
                    </Flex>
                  ) : null}
                </Flex>
              )
            }}
          </Formik>
        ) : (
          <Flex height="500px" justifyContent="center" alignItems="center">
            <BigDarkSpinner />
          </Flex>
        )}
      </Flex>
    </>
  )
}

export default Plans
