import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import ReactTooltip from 'react-tooltip'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import { colors, numberWithCommas, shorten } from '../ui/helpers.js'
import { msToTime } from '../ui/formatters'
import { H1, H2, H3, Text, Span, GradientFont, Error, SmallTitle } from '../ui/Typography.js'
import { Container, Flex, Box } from '../ui/Layout.js'

export const HTr = styled.tr`
  height: 52px;
  padding-left: 32px;
  padding-right: ${(props) => (!props.isLast ? '32px' : '0px')};
`

export const Th = styled.th`
  min-width: ${(props) => props.minWidth || '0px'};
  height: 52px;
  vertical-align: middle;
  border-bottom: 1px solid #edf2f7;
  border-top: 1px solid #edf2f7;
  background: #fafafb;
  padding-left: ${(props) => (!props.isLast ? props.paddingLeft || '12px' : '0px')};
  padding-right: ${(props) => (!props.isLast ? props.paddingRight || '12px' : '0px')};
`

export const HeaderText = styled(Text)`
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.06em;
  color: ${colors.textGrey};
  text-align: right;
  ${(props) => props.left && 'text-align: left;'}
`

export const Tr = styled.tr`
  height: ${(props) => props.height || '52px'};
  ${(props) => props.onClick && 'cursor: pointer;'}
`

export const Td = styled.td`
  word-break: break-word;
  vertical-align: middle;
  border-bottom: 1px solid #edf2f7;
  min-width: ${(props) => props.minWidth || '0px'};
  padding-left: ${(props) => (!props.isLast ? props.paddingLeft || '12px' : '0px')};
  padding-right: ${(props) => (!props.isLast ? props.paddingRight || '12px' : '0px')};
  font-size: 14px;
  text-align: right;
  ${(props) => props.left && 'text-align: left;'}
`

export const Table = styled.table`
  border-collapse: collapse;
  table-layout: fixed;
`
