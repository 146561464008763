import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { colors } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle, Label } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import formikFormWrapper from '../formikFormWrapper.js'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import baseUrl from '../baseUrl.js'
import PaymentsTab from './PaymentsTab.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import { Td, Tr, HTr, HeaderText, Table, Th } from '../ui/Table.js'
import { currencySymbols } from '../ui/formatters.js'
import Icon from '../ui/Icon.js'
import { mkConfig, generateCsv, download } from 'export-to-csv'
import CreateSubscriberModal from './CreateSubscriberModal.js'
import Button from '../ui/Button.js'
import { InputContainer, InputComponent, FullWidthForm } from './index.js'
import { useTheme } from '../ThemeProvider.js'
import { DeleteConfirmationModal, CustomerActionsMenu, useCustomerActions } from './CustomerActions.js'

const LineClamp = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const PAGESIZE = 50

const ContactsTab = (props) => {
  let { user, setUser } = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [allCustomers, setAllCustomers] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [showCreateSubscriberModal, setShowCreateSubscriberModal] = useState(false)
  const [tempSearch, setTempSearch] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  let { theme } = useTheme()

  const { deleteModalOpen, setDeleteModalOpen, selectedSubscriber, handleDeleteUser, confirmDelete } = useCustomerActions(() =>
    fetchCustomers()
  )

  const fetchCustomers = async () => {
    setIsLoading(true)
    try {
      const res = await api.get(`/customers/get-customers`)
      setAllCustomers(res.data.payload)
      setIsLoading(false)
    } catch (e) {
      console.log(e)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchCustomers()
  }, [])

  const filteredCustomers = allCustomers.filter((customer) => {
    if (!searchTerm) return true

    const searchLower = searchTerm.toLowerCase()
    return (
      (customer.firstName && customer.firstName.toLowerCase().includes(searchLower)) ||
      (customer.lastName && customer.lastName.toLowerCase().includes(searchLower)) ||
      (customer.email && customer.email.toLowerCase().includes(searchLower))
    )
  })

  const totalPages = Math.ceil(filteredCustomers.length / PAGESIZE)
  const paginatedCustomers = filteredCustomers.slice((currentPage - 1) * PAGESIZE, currentPage * PAGESIZE)

  const currencySymbol = currencySymbols[theme.plans?.[0]?.currency]

  const renderPageNumbers = () => {
    const pageNumbers = []
    const maxVisiblePages = 5
    const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2))
    const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1)

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Text
          key={i}
          onClick={() => setCurrentPage(i)}
          light={i !== currentPage}
          fontWeight={i === currentPage ? '600' : '500'}
          px="8px"
          cursor="pointer"
        >
          {i}
        </Text>
      )
    }
    return pageNumbers
  }

  const submit = async (e) => {
    e?.preventDefault()
    setCurrentPage(1)
    setSearchTerm(tempSearch)
  }

  useEffect(() => {
    if (searchTerm !== tempSearch) {
      setTempSearch(searchTerm)
    }
  }, [searchTerm])

  return (
    <Flex px="24px">
      {isLoading ? (
        <Flex height="300px" alignItems="center" justifyContent="center">
          <BigDarkSpinner />
        </Flex>
      ) : (
        <Flex flexDirection="column" pb="100px">
          <Flex mb="10px" justifyContent="space-between">
            <InputContainer>
              <Icon icon="search-grey" width={14} height={14} />
              <FullWidthForm onSubmit={submit}>
                <InputComponent placeholder="Search ..." onChange={(e) => setTempSearch(e.target.value)} value={tempSearch} />
              </FullWidthForm>
              {tempSearch || searchTerm ? (
                <Icon
                  cursor="pointer"
                  icon="circle-cross-grey"
                  width={12}
                  height={12}
                  onClick={() => {
                    setSearchTerm('')
                    setTempSearch('')
                  }}
                />
              ) : null}
            </InputContainer>
            <Flex width="auto">
              <Button
                fontSize="12px"
                key="contacts-csv"
                label="Add contact"
                variant="white"
                backgroundColor="white"
                shadow
                height="36px"
                mr="16px"
                boxShadow="0px 0px 1px 0px rgba(12, 26, 75, 0.20), 0px 1px 3px 0px rgba(50, 50, 71, 0.10)"
                renderLeftIcon={() => <Icon icon="plus-black" width="12px" height="12px" />}
                onClick={() => {
                  setShowCreateSubscriberModal(true)
                }}
              />
              <Button
                fontSize="12px"
                key="contacts-csv"
                label="Export"
                variant="white"
                backgroundColor="white"
                shadow
                height="36px"
                boxShadow="0px 0px 1px 0px rgba(12, 26, 75, 0.20), 0px 1px 3px 0px rgba(50, 50, 71, 0.10)"
                renderLeftIcon={() => <Icon icon="box-arrow-black" width="14px" height="14px" />}
                onClick={() => {
                  const csv = generateCsv(mkConfig({ useKeysAsHeaders: true }))(
                    filteredCustomers.map((c) => {
                      return {
                        first_name: c.firstName,
                        last_name: c.lastName,
                        sign_up_date: dayjs(c.createdAt).format('YYYY-MM-DD HH:mm:ss'),
                        email: c.email
                      }
                    })
                  )
                  const dateSuffix = dayjs().format('YYYY-MM-DD_HH-mm-ss')
                  download(mkConfig({ useKeysAsHeaders: true, filename: `contacts_${dateSuffix}.csv` }))(csv)
                }}
              />
            </Flex>
          </Flex>
          <Container p="0px" pt="16px">
            <Flex justifyContent="space-between" px="24px" mb="16px">
              <SmallTitle>Users</SmallTitle>
            </Flex>
            <Table>
              <HTr>
                <Th width="220px !important">
                  <HeaderText left>NAME</HeaderText>
                </Th>
                <Th>
                  <HeaderText left>SIGN UP DATE</HeaderText>
                </Th>
                <Th>
                  <HeaderText width="30%" left>
                    EMAIL
                  </HeaderText>
                </Th>
                <Th>
                  <HeaderText left>PLAN</HeaderText>
                </Th>
                <Th>
                  <HeaderText left>STATUS</HeaderText>
                </Th>
                <Th width="45px"></Th>
              </HTr>
              {paginatedCustomers &&
                paginatedCustomers.map((customer) => {
                  const customerHasActiveSubscription = customer.subscriptionPrice
                  return (
                    <Tr key={customer.id} justifyContent="space-between">
                      <Td left>
                        <LineClamp>
                          {customer.firstName} {customer.lastName}
                        </LineClamp>
                      </Td>
                      <Td left lightLight>
                        {dayjs(customer.createdAt).format('MMM DD, YYYY')}
                      </Td>
                      <Td left lightLight>
                        {customer.email}
                      </Td>
                      {customerHasActiveSubscription ? (
                        <Td left lightLight>
                          {currencySymbol}
                          {(customer.subscriptionPrice / 100).toFixed(2)}
                          {customer.planType === 'MONTHLY' ? '/m' : '/yr'}
                        </Td>
                      ) : (
                        <Td left lightLight></Td>
                      )}
                      <Td left lightLight>
                        {customerHasActiveSubscription ? (
                          <Flex alignItems="center">
                            <Box
                              mr="12px"
                              width="10px"
                              borderRadius="10px"
                              height="10px"
                              bg={customer.isOnTrial ? 'rgba(247, 147, 111, 1)' : 'rgba(102, 203, 159, 1)'}
                            />
                            {customer.isOnTrial ? 'Trial' : 'Active'}
                          </Flex>
                        ) : null}
                      </Td>
                      <Td width="45px">
                        <CustomerActionsMenu subscriber={customer} onDeleteClick={handleDeleteUser} />
                      </Td>
                    </Tr>
                  )
                })}
            </Table>
            {!filteredCustomers?.length ? (
              <>
                <Text light fontSize="16px" pt="120px" mb="8px" fontWeight="500" textAlign="center">
                  {searchTerm ? 'No matching contacts found' : 'No contacts yet'}
                </Text>
                <Text light fontSize="14px" pb="120px" textAlign="center">
                  {searchTerm
                    ? 'Try adjusting your search'
                    : "You'll find all of your contacts here including free signups, and previous subscribers"}
                </Text>
              </>
            ) : null}
            <Flex>
              <Flex mt="16px" mb="16px" justifyContent="space-between">
                <Text fontWeight="300" width="400px" lightLight px="16px" italic>
                  Showing {!filteredCustomers?.length ? 0 : (currentPage - 1) * PAGESIZE + 1} -{' '}
                  {Math.min(currentPage * PAGESIZE, filteredCustomers.length)} of {filteredCustomers.length}
                </Text>
                <Flex cursor="pointer" justifyContent="center" alignItems="center">
                  <Text
                    onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)}
                    light={currentPage > 1}
                    lightLight={currentPage === 1}
                    fontWeight="500"
                    px="8px"
                    cursor={currentPage > 1 ? 'pointer' : 'default'}
                  >
                    {'<'}
                  </Text>
                  {renderPageNumbers()}
                  <Text
                    onClick={() => currentPage < totalPages && setCurrentPage(currentPage + 1)}
                    light={currentPage < totalPages}
                    lightLight={currentPage === totalPages}
                    fontWeight="500"
                    px="8px"
                    cursor={currentPage < totalPages ? 'pointer' : 'default'}
                  >
                    {'>'}
                  </Text>
                </Flex>
                <Flex justifyContent="flex-end" pr="32px" width="400px"></Flex>
              </Flex>
            </Flex>
            <CreateSubscriberModal
              onClose={() => fetchCustomers()}
              isOpen={showCreateSubscriberModal}
              setIsOpen={setShowCreateSubscriberModal}
            />
            <DeleteConfirmationModal
              isOpen={deleteModalOpen}
              setIsOpen={setDeleteModalOpen}
              onConfirm={confirmDelete}
              subscriberName={`${selectedSubscriber?.firstName} ${selectedSubscriber?.lastName}`}
            />
          </Container>
        </Flex>
      )}
    </Flex>
  )
}

export default ContactsTab
