import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle, LineClamp } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import { Input } from '../ui/Input.js'
import Icon from '../ui/Icon.js'
import Button from '../ui/Button.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import { useModal } from '../Modal.js'
const edjsHTML = require('editorjs-html')
const edjsParser = edjsHTML()
import EditorJS from '@editorjs/editorjs'
import DragDrop from 'editorjs-drag-drop'
import AnyButton from 'editorjs-button'
import { Formik, Field } from 'formik'
import { useTheme } from '../ThemeProvider.js'
import ImageUploadModal, { getHeightAndWidthFromDataUrl } from '../ui/ImageUploadModal.js'
import formikFormWrapper from '../formikFormWrapper.js'
import SlideUpModal from '../Components/SlideUpModal.js'
import initEditor from './editorConfig.js'
import { debounce, set } from 'lodash' // Add this import
import { colors } from '../ui/helpers.js'
import Image from '../ui/Image.js'
import Popover from '../Components/ui/Popover.js'
import { CustomButtonStyles } from '../Posts/CustomButton.js'
import PostDetailsPopover from '../Posts/PostDetailsPopover.js'
import PreviewModal from './PreviewModal.js'

const TitleInput = styled.textarea`
  margin-bottom: 10px;
  font-size: 36px;
  font-weight: 700;
  line-height: 136.023%;
  border: none;
  outline: none;
  background: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin-left: 24px;
  font-family: 'Inter', sans-serif;
  ::-webkit-calendar-picker-indicator {
    background: none;
  }
  ::-webkit-input-placeholder {
    color: #b5b5b5;
    font-family: 'Inter', sans-serif;
  }
  ::-moz-placeholder {
    color: #b5b5b5;
    font-family: 'Inter', sans-serif;
  }
  :-ms-input-placeholder {
    color: #b5b5b5;
    font-family: 'Inter', sans-serif;
  }
  :-moz-placeholder {
    color: #b5b5b5;
    font-family: 'Inter', sans-serif;
  }
  resize: none;
  overflow: hidden;
  min-height: 48px;
  height: auto; // Add this line
  width: 100%;
`

const AuthorInput = styled.input`
  border: none;
  outline: none;
  background: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: 'Inter', sans-serif;
  ::-webkit-calendar-picker-indicator {
    background: none;
  }
  ::-webkit-input-placeholder {
    color: #b5b5b5;
    font-family: 'Inter', sans-serif;
  }
  ::-moz-placeholder {
    color: #b5b5b5;
    font-family: 'Inter', sans-serif;
  }
  :-ms-input-placeholder {
    color: #b5b5b5;
    font-family: 'Inter', sans-serif;
  }
  :-moz-placeholder {
    color: #b5b5b5;
    font-family: 'Inter', sans-serif;
  }
  font-weight: 400;
  font-size: 14px;
  padding: 0px;
  color: ${colors.textLight};
  min-height: auto;
  margin-left: 0px;
`

const FieldTitleInput = formikFormWrapper(TitleInput)
const FieldAuthorInput = formikFormWrapper(AuthorInput)

export const EditorJSStyles = styled(Box)`
  ${CustomButtonStyles}
  .button {
    border-style: solid;
    border-color: ${({ primaryButtonColor }) => primaryButtonColor};
    background: ${({ primaryButtonColor }) => primaryButtonColor};
    color: ${({ primaryButtonTextColor }) => primaryButtonTextColor};
    border-width: 0px;
    display: inline-block;
    border-radius: 30px;
    width: auto;
    text-decoration: none;
    -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none;
    mso-line-height-rule: exactly;
    color: #ffffff;
    font-size: 16px;
    padding: 15px 25px;
    display: inline-block;
    background: ${({ primaryButtonColor }) => primaryButtonColor};
    border-radius: 30px;
  }
  div {
    color: ${colors.textLight} !important;
    word-break: break-word;
  }
  .ce-header {
    color: ${colors.textDark} !important;
  }
  .ce-paragraph {
    a {
      text-decoration: underline;
    }
  }

  .codex-editor [data-placeholder-active]:empty:before,
  .codex-editor [data-placeholder-active][data-empty='true']:before {
    color: #b5b5b5 !important;
  }
`

const DEFAULT_INITIAL_DATA = {
  time: new Date().getTime(),
  blocks: []
}

const CustomContent = (props) => {
  let { user } = useAuth()
  const navigate = useNavigate()

  let { type } = useParams()
  const ejInstance = useRef()
  const { theme, refresh: refreshTheme } = useTheme()
  const [inputPost, setInputPost] = useState()

  const [isLoading, setIsLoading] = useState(true)
  const [thumbnailImageUploadOpen, setThumbnailImageUploadOpen] = useState(false)

  const [html, setHtml] = useState([])

  const [previewModalOpen, setPreviewModalOpen] = useState(false)

  const [saveStatus, setSaveStatus] = useState('') // Add this state

  const [isPostDetailsOpen, setIsPostDetailsOpen] = useState(false)

  const submit = async (values) => {
    const res = await api.post('/custom-content/update', { ...values, organisationId: user.organisationId, type })
    refresh()
    return res
  }

  const refresh = async () => {
    const res = await api.get(`/custom-content/get?organisationId=${user.organisationId}&type=${type}`)
    if (res.data.payload) {
      setInputPost(res.data.payload)
      setHtml(res.data.payload.html)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (type) {
      refresh()
    } else {
      setIsLoading(false)
    }
  }, [])

  const autoResizeTextArea = (element) => {
    if (element) {
      element.style.height = 'auto'
      element.style.height = `${element.scrollHeight}px`
    }
  }

  const shouldShowAuthor = type === 'welcome-email' || type === 'subscriber-welcome-email'
  const typeNeedsPublish = type === 'about'
  const isPublished = type === 'about' ? theme.useAboutMe : null
  const functionToPublish = async (publishBoolean) => {
    if (type === 'about') {
      await api.post('/theme/update-partial', { useAboutMe: publishBoolean })
      await refreshTheme()
    }
  }

  return (
    <Flex minHeight="100vh" alignItems="center" justifyContent="center" flexDirection="column" backgroundColor="white">
      {isLoading ? (
        <Flex height="500px" justifyContent="center" alignItems="center">
          <BigDarkSpinner />
        </Flex>
      ) : (
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={
            inputPost
              ? inputPost
              : {
                  title: 'New Post',
                  author: user.organisationName
                }
          }
          validate={(values) => {
            let errors = {}
            if (!values.title || values.title.trim() === '') {
              errors.title = 'Title is required'
            }
            return errors
          }}
          onSubmit={async (values, { setSubmitting, resetForm, setFieldValue }) => {
            setSubmitting(true)
            await submit(values)
            setSubmitting(false)
          }}
        >
          {({
            values,
            dirty,
            touched,
            resetForm,
            setSubmitting,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            validateForm,
            errors
          }) => {
            // Add this debounced save function

            const openPreviewModal = async () => {
              await handleSubmit()
              setPreviewModalOpen(true)
            }

            useEffect(() => {
              if (!ejInstance.current) {
                ejInstance.current = initEditor({
                  holder: 'editorjs',
                  content: values.content,
                  theme,
                  user,
                  onReady: () => {
                    new DragDrop(ejInstance.current)
                  },
                  onChange: async (api) => {
                    const data = await api.saver.save()
                    setFieldValue('content', data)
                  }
                })
              }

              return () => {
                ejInstance?.current?.destroy()
                ejInstance.current = null
              }
            }, [])

            useEffect(() => {
              const titleElement = document.querySelector('textarea[name="title"]')
              autoResizeTextArea(titleElement)
              const subtitleElement = document.querySelector('textarea[name="subtitle"]')

              autoResizeTextArea(subtitleElement)
            }, [values.title, values.subtitle])

            return (
              <>
                <Flex
                  minHeight="65px"
                  height="65px"
                  alignItems="center"
                  justifyContent="space-between"
                  backgroundColor="white"
                  borderBottom="2px solid #EEF2F7"
                  px="24px"
                  position="fixed"
                  top="0px"
                  zIndex={100}
                >
                  <Link to={`/settings`}>
                    <Flex cursor="pointer" width="auto">
                      <Icon mt="4px" mr="13px" width="16px" height="16px" icon="chevron-left-grey" />
                      <Text lightLight fontSize="14px">
                        Back
                      </Text>
                    </Flex>
                  </Link>
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    position="absolute"
                    top="20px"
                    left="calc(50% - 100px)"
                    width="200px"
                  >
                    {typeNeedsPublish ? (
                      <>
                        <Box
                          width="8px"
                          height="8px"
                          mr="8px"
                          borderRadius="8px"
                          backgroundColor={isPublished ? 'rgba(102, 203, 159, 1)' : 'rgba(247, 147, 111, 1)'}
                        />
                        <Text light fontWeight="500">
                          {isPublished ? 'Published' : 'Draft'}
                        </Text>
                      </>
                    ) : null}
                  </Flex>
                  <Flex width="auto" alignItems="center">
                    {errors && Object.keys(errors).length > 0 ? <Error mr="16px">Please check form errors</Error> : null}
                    <Button
                      isDisabled={!dirty}
                      isLoading={isSubmitting}
                      variant={!isPublished && typeNeedsPublish ? 'secondary' : 'green'}
                      label={'Save'}
                      onClick={async () => {
                        handleSubmit()
                      }}
                    />
                    {typeNeedsPublish ? (
                      <Button
                        ml="16px"
                        isLoading={isSubmitting}
                        variant={isPublished ? 'secondary' : 'green'}
                        label={isPublished ? 'Unpublish' : 'Publish'}
                        onClick={async () => {
                          await handleSubmit()
                          await functionToPublish(isPublished ? false : true)
                        }}
                      />
                    ) : null}
                  </Flex>
                </Flex>

                <Flex flexDirection="column" alignItems="center" position="relative" height="100%">
                  <Flex position="fixed" top="77px" right="12px" width="auto">
                    <Button
                      borderRadius="8px"
                      color="#4A5568"
                      shadow
                      renderLeftIcon={() => <Icon icon="preview-grey" width="16px" height="16px" />}
                      variant="white"
                      label="Preview"
                      onClick={() => openPreviewModal()}
                    />
                    <Popover
                      isOpen={isPostDetailsOpen}
                      onClose={() => setIsPostDetailsOpen(false)}
                      position="left"
                      content={
                        <PostDetailsPopover
                          content={values.content} // Pass the entire content object
                        />
                      }
                    >
                      <Button
                        borderRadius="8px"
                        color="#4A5568"
                        shadow
                        renderCenterIcon={() => <Icon icon="info-circle-grey" width="16px" height="16px" />}
                        variant="white"
                        width="40px"
                        minWidth="40px"
                        padding="0px"
                        ml="16px"
                        onClick={() => setIsPostDetailsOpen(!isPostDetailsOpen)}
                      />
                    </Popover>
                  </Flex>
                  <Flex width="700px" height="100%" flexDirection="column" pt="137px" position="relative">
                    <Flex flexDirection="column">
                      <Field
                        maxLength={200}
                        mb="24px"
                        large
                        name="title"
                        placeholder="New Post"
                        component={FieldTitleInput}
                        dark
                        rows={1}
                        onInput={(e) => {
                          autoResizeTextArea(e.target)
                        }}
                      />
                      {errors.title && touched.title && (
                        <Error pl="24px" mb="8px">
                          {errors.title}
                        </Error>
                      )}
                    </Flex>

                    {shouldShowAuthor && (
                      <Flex alignItems="center" borderBottom="1px solid #EDF2F7" mb="40px" pb="16px">
                        <Text ml="24px" mr="4px" lineHeight="21px" fontSize="14px" light>
                          By{' '}
                        </Text>
                        <Field
                          large
                          name="author"
                          component={FieldAuthorInput}
                          dark
                          onInput={(e) => autoResizeTextArea(e.target)}
                        />
                      </Flex>
                    )}

                    <EditorJSStyles
                      position="relative"
                      primaryButtonColor={theme.primaryButtonColor}
                      primaryButtonTextColor={theme.primaryButtonTextColor}
                      width="700px"
                      height="100%"
                      id="editorjs"
                    ></EditorJSStyles>
                  </Flex>

                  <PreviewModal
                    values={values}
                    type={type}
                    isOpen={previewModalOpen}
                    html={html}
                    onClose={() => setPreviewModalOpen(false)}
                  />
                </Flex>
              </>
            )
          }}
        </Formik>
      )}
    </Flex>
  )
}

export default CustomContent
