'use client'

import { useEffect, useState } from 'react'
import AccordionItem from './AccordionItem'
import { H3 } from '../ui/Typography'
import { Flex } from '../ui/Layout'
import api from '../api'
import { useAuth } from '../Auth'
import { CONSISTENT_BOTTOM_MARGIN } from './HomepageSectionsContainer'
import { useTheme } from '../ThemeProvider'

const HomepageFAQs = () => {
  const { user } = useAuth()
  const { theme } = useTheme()
  const [openIndex, setOpenIndex] = useState(null)
  const [faqs, setFaqs] = useState([])
  useEffect(() => {
    const fetchFaqs = async () => {
      const res = await api.get(`/site/homepage?organisationId=${user?.organisationId}`)
      console.log(res.data.payload)

      setFaqs(res.data.payload.faqs)
    }
    fetchFaqs()
  }, [])

  if (!faqs || faqs.length === 0) return null

  const homepageFaqs = faqs?.filter((faq) => faq.showOnHomepage)

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index)
  }

  if (!homepageFaqs || homepageFaqs.length === 0) return null
  return (
    <Flex mb={CONSISTENT_BOTTOM_MARGIN} alignItems="center" justifyContent="center" flexDirection="column">
      <H3 fontSize="24px" fontWeight="700" lineHeight="136%" mb="60px" px="16px">
        Frequently Asked Questions
      </H3>
      <Flex flexDirection="column" maxWidth="800px" px="16px" width="100%">
        {homepageFaqs?.map((faq, index) => (
          <AccordionItem
            key={index}
            small
            question={faq.question}
            answer={faq.answer}
            isOpen={openIndex === index}
            onClick={() => toggleAccordion(index)}
            primaryFontColor={theme?.primaryFontColor}
          />
        ))}
      </Flex>
    </Flex>
  )
}

export default HomepageFAQs
