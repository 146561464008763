'use client'

import { useEffect, useState, useCallback, useRef } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import hexToRgba from 'hex-to-rgba'
import ChatFooter from './ChatFooter'
import Comment from './Comment'
import { Box, Flex, MobileContainer } from '../ui/Layout'
import api from '../api'
import { Text } from '../ui/Typography'
import Thread from './Thread'
import NewThread from './NewThread'
import { colors } from '../ui/helpers'
import Button from '../ui/Button'
import Icon from '../ui/Icon'
import { useAuth } from '../Auth'
import { useTheme } from '../ThemeProvider'
import { Link } from 'react-router-dom'

export const GlobalStyle = createGlobalStyle`
html { scrollbar-width: none; } /* Firefox */
body { -ms-overflow-style: none; } /* IE and Edge */
body::-webkit-scrollbar, body::-webkit-scrollbar-button { display: none;-webkit-appearance: none; width: 0px; scrollbar-color: transparent transparent; } /* Chrome */
html {
  height: 100%;
  overflow: hidden;
}
body {
  min-height: 100%;
  overflow: hidden;
    -webkit-overflow-scrolling: touch; /* Enables smooth scrolling on iOS */
  position: fixed; /* Prevents bounce effect and nav bar appearance */
  width: 100%;
  max-height: 100%;
  height: 100%;
}
`

export const CommentContainer = styled(Flex)`
  height: 100%;
  flex: 1;
  overflow-y: scroll;
`

export const PageContainer = styled(Flex)`
  flex-direction: column;
  position: relative;
  min-height: 100%;
  height: 100%;
  max-height: 100%;
  @media (max-width: 768px) {
    padding-top: 62px;
  }
`
export const Header = styled(Flex)`
  height: 62px;
  border-bottom: 1px solid ${(props) => hexToRgba(props.primaryFontColor, 0.1)};
  align-items: center;
  background-color: ${(props) => props.backgroundColor};
  justify-content: space-between;
  @media (max-width: 768px) {
    margin-top: env(safe-area-inset-top);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
  }
`
export const PagePageContainer = styled(Flex)`
  min-height: 100%;
  justify-content: center;
  max-height: 100%;
`

export const BackArrow = ({ primaryFontColor }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3536 1.64645C11.5488 1.84171 11.5488 2.15829 11.3536 2.35355L5.70711 8L11.3536 13.6464C11.5488 13.8417 11.5488 14.1583 11.3536 14.3536C11.1583 14.5488 10.8417 14.5488 10.6464 14.3536L4.64645 8.35355C4.45118 8.15829 4.45118 7.84171 4.64645 7.64645L10.6464 1.64645C10.8417 1.45118 11.1583 1.45118 11.3536 1.64645Z"
      fill={colors.textLight}
    />
  </svg>
)

export const Title = styled(Text)`
  font-size: 14px;
  font-weight: 500;
`

const EmptyState = () => {
  const { user } = useAuth()
  const { theme } = useTheme()

  if (!theme.chatEnabled) {
    return (
      <Flex flexDirection="column" alignItems="center" justifyContent="center" height="100%" pt="80px">
        <svg width="178" height="89" viewBox="0 0 178 89" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M44.5 83.4375C69.0767 83.4375 89 66.0046 89 44.5C89 22.9954 69.0767 5.5625 44.5 5.5625C19.9233 5.5625 0 22.9954 0 44.5C0 54.2948 4.13328 63.245 10.9569 70.0879C10.3346 76.5882 8.07782 83.8071 5.77288 88.5722C5.68507 88.7537 5.83892 88.9604 6.03846 88.9311C19.89 86.9014 28.015 83.4723 31.4439 81.7347C35.5726 82.8418 39.9568 83.4375 44.5 83.4375Z"
            fill="#FDF4E8"
          />
          <path
            d="M89 83.4375C113.853 83.4375 134 66.0046 134 44.5C134 22.9954 113.853 5.5625 89 5.5625C64.1472 5.5625 44 22.9954 44 44.5C44 54.2948 48.1797 63.245 55.08 70.0879C54.4507 76.5879 52.1688 83.8065 49.838 88.5716C49.7493 88.753 49.9029 88.9606 50.1028 88.9316C64.1121 86.9019 72.3295 83.4724 75.7972 81.7347C79.9723 82.8418 84.4058 83.4375 89 83.4375Z"
            fill="#5BB3F9"
          />
          <path
            d="M133.5 83.4375C158.077 83.4375 178 66.0046 178 44.5C178 22.9954 158.077 5.5625 133.5 5.5625C108.923 5.5625 89 22.9954 89 44.5C89 54.2948 93.1333 63.245 99.9569 70.0879C99.3346 76.5882 97.0778 83.8071 94.7729 88.5722C94.6851 88.7537 94.8389 88.9604 95.0385 88.9311C108.89 86.9014 117.015 83.4723 120.444 81.7347C124.573 82.8418 128.957 83.4375 133.5 83.4375Z"
            fill="#F4ACC4"
          />
        </svg>

        <Text light fontSize="16px" mt="24px" mb="8px" fontWeight="500" textAlign="center">
          Welcome to Chat
        </Text>
        <Text light fontSize="14px" textAlign="center" maxWidth="400px" mb="24px">
          Connect with your subscribers and let them chat to each other.
        </Text>
        <Link to={`/settings`}>
          <Button
            mb="60px"
            renderLeftIcon={() => <Icon icon="speech-white" width="12px" height="12px" />}
            variant="green"
            label="Enable chat"
          />
        </Link>
      </Flex>
    )
  }
  return (
    <Flex flexDirection="column" alignItems="center" justifyContent="center" height="100%" pt="80px">
      <svg width="178" height="89" viewBox="0 0 178 89" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M44.5 83.4375C69.0767 83.4375 89 66.0046 89 44.5C89 22.9954 69.0767 5.5625 44.5 5.5625C19.9233 5.5625 0 22.9954 0 44.5C0 54.2948 4.13328 63.245 10.9569 70.0879C10.3346 76.5882 8.07782 83.8071 5.77288 88.5722C5.68507 88.7537 5.83892 88.9604 6.03846 88.9311C19.89 86.9014 28.015 83.4723 31.4439 81.7347C35.5726 82.8418 39.9568 83.4375 44.5 83.4375Z"
          fill="#FDF4E8"
        />
        <path
          d="M89 83.4375C113.853 83.4375 134 66.0046 134 44.5C134 22.9954 113.853 5.5625 89 5.5625C64.1472 5.5625 44 22.9954 44 44.5C44 54.2948 48.1797 63.245 55.08 70.0879C54.4507 76.5879 52.1688 83.8065 49.838 88.5716C49.7493 88.753 49.9029 88.9606 50.1028 88.9316C64.1121 86.9019 72.3295 83.4724 75.7972 81.7347C79.9723 82.8418 84.4058 83.4375 89 83.4375Z"
          fill="#5BB3F9"
        />
        <path
          d="M133.5 83.4375C158.077 83.4375 178 66.0046 178 44.5C178 22.9954 158.077 5.5625 133.5 5.5625C108.923 5.5625 89 22.9954 89 44.5C89 54.2948 93.1333 63.245 99.9569 70.0879C99.3346 76.5882 97.0778 83.8071 94.7729 88.5722C94.6851 88.7537 94.8389 88.9604 95.0385 88.9311C108.89 86.9014 117.015 83.4723 120.444 81.7347C124.573 82.8418 128.957 83.4375 133.5 83.4375Z"
          fill="#F4ACC4"
        />
      </svg>

      <Text light fontSize="16px" mt="24px" mb="8px" fontWeight="500" textAlign="center">
        You’re ready to chat.
      </Text>
      <Text light fontSize="14px" textAlign="center" maxWidth="400px" mb="24px">
        Send an email via Posts to invite your subscribers to join and kick things off with your first thread.
      </Text>
    </Flex>
  )
}

export default function ChatIndex({ config }) {
  const COMMENTS_PER_PAGE = 100

  const { theme } = useTheme()
  const scrollPositionRef = useRef(null)
  const [comments, setComments] = useState([])
  const [reactions, setReactions] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [imageUrl, setImageUrl] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [displayedComments, setDisplayedComments] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [threadId, setThreadId] = useState(null)
  const [threadComment, setThreadComment] = useState(null)
  const [newThread, setNewThread] = useState(false)

  // Initialize displayed comments
  useEffect(() => {
    setDisplayedComments(comments.slice(-COMMENTS_PER_PAGE))
    setCurrentPage(1)
  }, [comments])

  useEffect(() => {
    refreshChat()
  }, [])

  const loadMoreComments = useCallback(() => {
    setIsLoading(true)

    // Calculate the next set of comments to display
    const nextPage = currentPage + 1
    const endIndex = comments.length
    // Add more comments to the top of displayedComments
    const newComments = comments.slice(-(nextPage * COMMENTS_PER_PAGE))

    setDisplayedComments(newComments)
    setCurrentPage(nextPage)
    setIsLoading(false)
  }, [comments, currentPage])

  const handleScroll = useCallback(
    (e) => {
      const container = e.target
      const hasMoreToLoad = displayedComments.length < comments.length

      // Add console.log statements to debug
      // Modify the scroll threshold and add error handling
      if (container.scrollTop <= 100 && !isLoading && hasMoreToLoad) {
        console.log('Triggering loadMoreComments')
        loadMoreComments()
      }
    },
    [loadMoreComments, isLoading, displayedComments.length, comments.length]
  )

  // Modified effect to restore scroll position after comments load
  useEffect(() => {
    const commentContainer = document.querySelector('[data-comment-container]')
    if (commentContainer && displayedComments.length > 0) {
      if (currentPage === 1) {
        commentContainer.scrollTop = commentContainer.scrollHeight
        window.scrollTo(0, document.body.scrollHeight)
      }
      commentContainer.addEventListener('scroll', handleScroll)
      return () => commentContainer.removeEventListener('scroll', handleScroll)
    }
  }, [displayedComments, currentPage, handleScroll])

  const refreshChat = async () => {
    const response = await api.get(`/chat/get-chat?organisationId=${config.organisationId}`)
    setComments(response.data.payload.comments || [])
    setReactions(response.data.payload.reactions || [])
    setCurrentPage(1)
    setDisplayedComments((response.data.payload.comments || []).slice(-COMMENTS_PER_PAGE))
  }

  const refreshReactions = async () => {
    const response = await api.get(`/chat/get-chat?organisationId=${config.organisationId}`)
    setReactions(response.data.payload.reactions || [])
  }

  const handleTextAreaChange = (e) => {
    setInputValue(e)
  }

  // Modify handleCommentClick to save scroll position to localStorage
  const handleCommentClick = (comment) => {
    const container = document.querySelector('[data-comment-container]')
    if (container) {
      localStorage.setItem('chatScrollPosition', container.scrollTop.toString())
    }
    setThreadId(comment.id)
    setThreadComment(comment)
  }

  const deleteComment = async (commentId) => {
    await api.post(`/chat/admin-delete-comment`, {
      commentId: commentId
    })
    refreshChat()
  }

  if (theme?.id && !theme?.chatEnabled && !isLoading) {
    return <EmptyState />
  }

  return (
    <>
      {/* Thread is displayed when threadId is set */}
      <Flex flexDirection="column" height="100%" alignItems="center" display={threadId ? 'flex' : 'none'} position="relative">
        {threadId && !newThread && (
          <Thread
            config={config}
            threadId={threadId}
            threadComment={threadComment}
            refresh={refreshChat}
            setThreadId={setThreadId}
            setThreadComment={setThreadComment}
          />
        )}
      </Flex>

      {/* New thread page */}
      <Flex flexDirection="column" height="100%" alignItems="center" display={newThread ? 'flex' : 'none'} position="relative">
        <Flex
          position="absolute"
          top="24px"
          left="24px"
          width="auto"
          alignItems="center"
          cursor="pointer"
          onClick={() => {
            setNewThread(false)
          }}
        >
          <BackArrow primaryFontColor={config.primaryFontColor} />
          <Text light ml="16px" fontSize="14px" fontWeight="500">
            Back
          </Text>
        </Flex>

        <NewThread config={config} setNewThread={setNewThread} refresh={refreshChat} />
      </Flex>

      {/* PageContainer is hidden when threadId is set */}
      <PagePageContainer display={threadId || newThread ? 'none' : 'flex'}>
        <PageContainer primaryFontColor={config.primaryFontColor}>
          {!isLoading && theme?.id && !comments?.length && <EmptyState />}
          <CommentContainer data-comment-container noScrollBar isActiveSubcriber>
            <Flex maxWidth="600px" flexDirection="column" px="24px" pxm="16px" pt="24px" pb="60px">
              {displayedComments.map((comment, index) => (
                <Comment
                  key={comment.id}
                  comment={comment}
                  config={config}
                  reactions={reactions}
                  refresh={refreshReactions}
                  refreshChat={refreshChat}
                  commentOnClick={() => handleCommentClick(comment)}
                  replyOnClick={() => handleCommentClick(comment)}
                  previousCommentDate={index > 0 ? displayedComments[index - 1].createdAt : null}
                  addReactionsBelow={index < displayedComments.length - 2}
                  onDelete={deleteComment}
                />
              ))}

              <Box height="40px" minHeight="40px" />
            </Flex>
          </CommentContainer>
          <ChatFooter
            config={config}
            inputValue={inputValue}
            handleTextAreaChange={handleTextAreaChange}
            showNewThread={true}
            setNewThread={setNewThread}
          />
        </PageContainer>
      </PagePageContainer>
    </>
  )
}
