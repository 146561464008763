'use client'

import styled from 'styled-components'
import { useState, useRef, useEffect, forwardRef } from 'react'
import hexToRgba from 'hex-to-rgba'
import { Flex } from '../ui/Layout'
import WriteComment from './WriteComment'
import { Text } from '../ui/Typography'
import Button from '../ui/Button'

const StyledTextArea = styled.textarea`
  border: none;
  width: 100%;
  max-height: 120px;
  min-height: 40px;
  height: 40px;
  padding: 8px 16px;
  border-radius: 16px;
  font-size: 16px;
  outline: none;
  resize: none;
  background-color: transparent;
  font-family: ${(props) => props.primaryFontFamily};
  color: ${(props) => props.primaryFontColor};
  overflow-y: hidden;
  line-height: 24px;
  display: block;

  &::placeholder {
    color: ${(props) => props.secondaryFontColor};
  }
`

const StyledInput = styled.input`
  display: none;
`

const ImageContainer = styled(Flex)`
  width: 100px;
  min-width: 100px;
  height: 100px;
  min-height: 100px;
  border: ${(props) => (props.hasImage ? 'none' : '1px dashed #718096')};
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  margin-right: 16px;
`

const ImageButton = styled(Flex)`
  width: 24px;
  height: 24px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(68, 68, 68, 0.5);
`

const ChatFooterContainer = styled(Flex)`
  padding-top: 8px;
  padding-bottom: 8px;
  @media (max-width: 768px) {
    padding-top: 8px;
    padding-bottom: calc(32px + env(safe-area-inset-bottom) + env(safe-area-inset-bottom) * 1 / 2);
  }
`

const ChatFooter = forwardRef(
  (
    {
      config,
      user,
      router,
      threadId,
      inputValue,
      handleTextAreaChange,
      isNewThreadPage,
      placeholder,
      submit,
      imageUrls,
      setImageUrls,
      replyingTo,
      setReplyingTo,
      isProfilePage,
      isSubmitting,
      showNewThread,
      setNewThread
    },
    ref
  ) => {
    return (
      <ChatFooterContainer
        ref={ref}
        justifyContent="center"
        backgroundColor={config.backgroundColor}
        px="12px"
        width="100%"
        borderTop={`1px solid ${hexToRgba(config.primaryFontColor, 0.1)}`}
      >
        <Flex width="100%">
          {(() => {
            if (isNewThreadPage) {
              return (
                <WriteComment
                  config={config}
                  inputValue={inputValue}
                  handleTextAreaChange={handleTextAreaChange}
                  placeholder={placeholder}
                  submit={submit}
                  imageUrls={imageUrls}
                  setImageUrls={setImageUrls}
                  replyingTo={replyingTo}
                  setReplyingTo={setReplyingTo}
                />
              )
            }

            if (threadId) {
              return (
                <WriteComment
                  config={config}
                  inputValue={inputValue}
                  handleTextAreaChange={handleTextAreaChange}
                  placeholder={placeholder}
                  submit={submit}
                  imageUrls={imageUrls}
                  setImageUrls={setImageUrls}
                  replyingTo={replyingTo}
                  setReplyingTo={setReplyingTo}
                  threadId={threadId}
                />
              )
            }

            if (showNewThread) {
              return (
                <Flex alignItems="center" justifyContent="center">
                  <Button
                    onClick={() => setNewThread(true)}
                    fontSize="14px"
                    height="40px"
                    width="auto"
                    variant="green"
                    px="32px"
                    renderLeftIcon={() => (
                      <Flex width="auto" height="100%" alignItems="center" mr="8px">
                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M3.17789 11.894C3.38828 12.1049 3.49363 12.3988 3.46523 12.6954C3.40159 13.3601 3.25502 14.0519 3.06677 14.6945C4.46166 14.3721 5.31358 13.9981 5.70082 13.8019C5.92062 13.6905 6.17381 13.6642 6.41182 13.728C7.06966 13.9044 7.77076 14 8.5 14C12.4956 14 15.5 11.1925 15.5 8C15.5 4.80754 12.4956 2 8.5 2C4.5044 2 1.5 4.80754 1.5 8C1.5 9.46809 2.1173 10.8304 3.17789 11.894ZM2.68489 15.7989C2.67816 15.8003 2.67142 15.8016 2.66466 15.8029C2.57283 15.821 2.47922 15.8388 2.38382 15.8563C2.25 15.8808 2.11264 15.9047 1.97172 15.9277C1.77271 15.9603 1.62037 15.7521 1.69898 15.5664C1.75034 15.4451 1.80097 15.3179 1.8503 15.1861C1.88164 15.1023 1.91245 15.0167 1.94259 14.9294C1.94389 14.9256 1.94518 14.9219 1.94648 14.9181C2.19422 14.1984 2.39596 13.3711 2.46979 12.6001C1.24306 11.3699 0.5 9.76087 0.5 8C0.5 4.13401 4.08172 1 8.5 1C12.9183 1 16.5 4.13401 16.5 8C16.5 11.866 12.9183 15 8.5 15C7.68324 15 6.89508 14.8929 6.15284 14.6939C5.63337 14.9571 4.51434 15.4363 2.68489 15.7989Z"
                            fill={'white'}
                          />
                        </svg>
                      </Flex>
                    )}
                    label="Start a thread"
                  />
                </Flex>
              )
            }
          })()}
        </Flex>
      </ChatFooterContainer>
    )
  }
)

ChatFooter.displayName = 'ChatFooter'

export default ChatFooter
