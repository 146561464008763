import { useState } from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import hexToRgba from 'hex-to-rgba'
import { Text } from '../ui/Typography'
import { Box, Flex } from '../ui/Layout'
import { FontText, PrimaryText } from './Preview'
import { useTheme } from '../ThemeProvider'

const AccordionItem = ({ question, answer, isOpen, onClick, primaryFontColor, small }) => {
  const { theme } = useTheme()
  if (!question || !answer) return null
  return (
    <StyledAccordionItem primaryFontColor={primaryFontColor} width="100%" mb="20px" pb="20px">
      <AccordionHeader onClick={onClick} isOpen={isOpen}>
        <PrimaryText config={theme} fontSize={small ? '16px' : '20px'} fontWeight="700" lineHeight="150%">
          {question}
        </PrimaryText>
        <ChevronIcon isOpen={isOpen} animate={{ rotate: isOpen ? 0 : 180 }} transition={{ duration: 0.3 }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.3536 11.3536C14.1583 11.5488 13.8417 11.5488 13.6464 11.3536L8 5.70711L2.35355 11.3536C2.15829 11.5488 1.84171 11.5488 1.64645 11.3536C1.45118 11.1583 1.45118 10.8417 1.64645 10.6464L7.64645 4.64645C7.84171 4.45118 8.15829 4.45118 8.35355 4.64645L14.3536 10.6464C14.5488 10.8417 14.5488 11.1583 14.3536 11.3536Z"
              fill={primaryFontColor}
            />
          </svg>
        </ChevronIcon>
      </AccordionHeader>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
            transition={{ duration: 0.3 }}
            style={{ overflow: 'hidden' }}
          >
            <AccordionContent>
              <FontText config={theme} light fontSize={small ? '14px' : '16px'} lineHeight="150%">
                {answer}
              </FontText>
            </AccordionContent>
          </motion.div>
        )}
      </AnimatePresence>
    </StyledAccordionItem>
  )
}

const StyledAccordionItem = styled(Box)`
  width: 100%;
  border-bottom: 1px solid ${(props) => hexToRgba(props.primaryFontColor, 0.1)};
`

const AccordionHeader = styled(Flex)`
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const ChevronIcon = styled(motion.div)`
  margin-left: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const AccordionContent = styled(Box)`
  margin-top: 8px;
  overflow: hidden;
  padding-right: 40px;
`

export default AccordionItem
