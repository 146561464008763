import React, { useState, useEffect, useRef, useCallback } from 'react'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
import { colors, formatSeconds } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle, LineClamp } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import Icon from '../ui/Icon.js'
import Button from '../ui/Button.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import ModalContainer from '../ui/ModalContainer.js'
import { uniq, difference, intersection, indexBy, prop } from 'ramda'
import Tooltip from '../ui/Tooltip.js'
import ReactTooltip from 'react-tooltip'
import { useModal } from '../Modal.js'
import PopoverDropdown from '../ui/PopoverDropdown.js'
import { useContent } from '../ContentProvider.js'
import { useSearchParams } from 'react-router-dom'
import { useTheme } from '../ThemeProvider.js'
import { currencySymbols } from '../ui/formatters.js'
import Thread from './Thread.js'
import ChatIndex from './ChatIndex.js'
import { useChat } from '../ChatProvider.js'

const TABS = {
  ALL: 'all',
  THREADS: 'threads'
}

const TabText = styled(Text)`
  ${(props) => !props.isActive && `color: ${colors.textLight};`}
  padding-bottom: 20px;
  ${(props) => props.isActive && `border-bottom: 1px solid ${colors.green};`}
  font-weight: 600;
  margin-right: 30px;
  cursor: pointer;
`

const Tab = ({ onClick, title, isActive }) => {
  return (
    <TabText onClick={onClick} isActive={isActive}>
      {title}
    </TabText>
  )
}

const EmptyState = () => {
  const { theme } = useTheme()
  return (
    <>
      <Text textAlign="center" fontWeight="500" fontSize="16px" lineHeight="25px" pt="150px">
        No activity yet
      </Text>
      <Text textAlign="center" lineHeight="25px" px="40px">
        You'll be notified here about new threads and replies
      </Text>
    </>
  )
}

const formatTimeDifference = (date) => {
  const now = new Date()
  const diff = now - new Date(date)
  const minutes = Math.floor(diff / 60000)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const months = Math.floor(days / 30)
  const years = Math.floor(months / 12)

  if (years > 0) return `${years}y`
  if (months > 0) return `${months}m`
  if (days > 0) return `${days}d`
  if (hours > 0) return `${hours}h`
  if (minutes > 0) return `${minutes}m`
  return 'now'
}

const ITEMS_PER_PAGE = 25 // Number of items to load per scroll

const Chat = (props) => {
  let { user, refresh } = useAuth()
  let navigate = useNavigate()
  let { threads, isLoaded } = useChat()

  let { theme } = useTheme()
  const config = {
    ...theme,
    organisationId: user.organisationId,
    primaryFontColor: '#27272E',
    primaryButtonFontColor: 'white',
    primaryButtonColor: colors.green,
    secondaryFontColor: '#425466',
    secondaryButtonColor: colors.green,
    secondaryButtonFontColor: 'white',
    primaryFontFamily: 'Inter',
    secondaryFontFamily: 'Inter',
    backgroundColor: 'white'
  }

  const [searchParams, setSearchParams] = useSearchParams()
  const [activeTab, setActiveTabState] = useState(searchParams.get('tab') || TABS.ALL)

  const contentToShow = threads

  const [activeContent, setActiveContent] = useState(contentToShow?.[0])

  const currencySymbol = currencySymbols[theme.plans?.[0]?.currency]

  const setActiveTab = (tab) => {
    setActiveTabState(tab)
    setDisplayedItems([])
    setPage(1)
    setSearchParams({ tab })
  }

  const [displayedItems, setDisplayedItems] = useState([])
  const [page, setPage] = useState(1)
  const containerRef = useRef(null)

  const updateLastRead = async () => {
    await api.post('/organisations/update-chat-last-read')
    await refresh()
  }
  useEffect(() => {
    setTimeout(updateLastRead, 1000)
  }, [])

  const loadMore = useCallback(() => {
    const startIndex = (page - 1) * ITEMS_PER_PAGE
    const newItems = contentToShow.slice(0, startIndex + ITEMS_PER_PAGE)
    setDisplayedItems(newItems)
    setPage((prevPage) => prevPage + 1)
  }, [contentToShow, page])

  useEffect(() => {
    if (isLoaded) {
      setPage(1)
      loadMore()
    }
  }, [isLoaded, activeTab])

  const handleScroll = useCallback(() => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current
      if (scrollTop + clientHeight >= scrollHeight - 20 && displayedItems.length < contentToShow.length) {
        loadMore()
      }
    }
  }, [displayedItems.length, contentToShow.length, loadMore])

  useEffect(() => {
    const currentContainer = containerRef.current
    if (currentContainer) {
      currentContainer.addEventListener('scroll', handleScroll)
    }
    return () => {
      if (currentContainer) {
        currentContainer.removeEventListener('scroll', handleScroll)
      }
    }
  }, [handleScroll])

  return (
    <Flex flexDirection="column" px="12px">
      <H1 width="100%" pt="24px" mb="16px">
        Chat
      </H1>
      <Container height="calc(100vh - 80px)" p="0px" overflow="hidden">
        <Flex p="24px" pb="0px" borderBottom="1px solid #EDF2F7">
          <Tab onClick={() => setActiveTab(TABS.ALL)} title="All" isActive={activeTab === TABS.ALL} />
          <Tab onClick={() => setActiveTab(TABS.THREADS)} title="Threads" isActive={activeTab === TABS.THREADS} />
        </Flex>
        <Flex height="calc(100% - 70px)">
          {activeTab === TABS.ALL ? <ChatIndex config={config} /> : null}

          {activeTab === TABS.THREADS ? (
            <>
              <Flex
                ref={containerRef}
                flexDirection="column"
                width="450px"
                minWidth="450px"
                borderRight="1px solid #EDF2F7"
                overflowY="scroll"
                noScrollBar
              >
                {displayedItems && !displayedItems.length ? <EmptyState /> : null}

                {displayedItems.map((thread) => (
                  <Flex
                    key={thread.id}
                    p="16px"
                    borderBottom="1px solid #EDF2F7"
                    backgroundColor={activeContent?.id === thread.id ? '#F7FAFC' : 'white'}
                    cursor="pointer"
                    minHeight="96px"
                    onClick={() => {
                      setActiveContent(thread)
                    }}
                  >
                    <Flex
                      width="32px"
                      minWidth="32px"
                      height="32px"
                      borderRadius="50%"
                      bg={'#007D5B'}
                      justifyContent="center"
                      alignItems="center"
                      overflow="hidden"
                      mr="16px"
                    >
                      {thread.isCreator ? (
                        <Image
                          objectFit="cover"
                          src={theme.commentsImageUrl + '?width=200&height=200'}
                          width="100%"
                          height="100%"
                        />
                      ) : (
                        <Text fontWeight="600" color="white">
                          {thread?.firstName?.charAt(0)}
                        </Text>
                      )}
                    </Flex>
                    <Flex flexDirection="column">
                      <Text fontSize="14px" lineHeight="20px" light mb="4px">
                        {thread.isCreator ? 'New replies to:' : 'New thread created by:'}
                      </Text>
                      <LineClamp light lines={2} fontSize="14px" lineHeight="20px" fontWeight="600" mb="4px">
                        {thread.isCreator ? thread.comment : thread.chatName || thread.firstName}
                      </LineClamp>
                    </Flex>
                    <Text light whiteSpace="nowrap" fontSize="12px" lineHeight="20px">
                      {formatTimeDifference(thread.subscribedAt || thread.createdAt)}
                    </Text>
                  </Flex>
                ))}
              </Flex>
              <Flex flexDirection="column" overflowY="scroll" noScrollBar maxWidth="100%">
                {activeContent && (
                  <Thread config={config} threadId={activeContent.id} threadComment={activeContent} refresh={() => null} />
                )}
              </Flex>
            </>
          ) : null}
        </Flex>
      </Container>
    </Flex>
  )
}

export default Chat
