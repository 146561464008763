import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { colors, formatSeconds } from '../ui/helpers.js'
import { H6, Text } from '../ui/Typography.js'
import { Flex, Box } from '../ui/Layout.js'
import Icon from '../ui/Icon.js'
import { EmptyState } from './index.js'
import MealPlanCard from './MealPlanCard.js'
import PostCard from './PostCard.js'
import SelectableCard from './SelectableCard.js'
import { useContent } from '../ContentProvider.js'

const Grid = styled(Flex)`
  display: grid;
  grid-gap: 22px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  @media (min-width: 1800px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  @media (min-width: 2200px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`

const ImageContainer = styled(Flex)`
  border-radius: 8px 8px 0px 0px;
`

const VideoImage = styled.img`
  width: 100%;
  object-fit: cover;
  height: 100%;
`

const Gradient = styled(Flex)`
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 80%, rgba(0, 0, 0, 0.3) 100%);
`

const GradientReverse = styled(Flex)`
  border-radius: 8px 8px 0px 0px;
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 80%, rgba(0, 0, 0, 0.3) 100%);
`

const ContainerWithHover = styled(Flex)`
  box-shadow: none;
  background-color: transparent;
  border: ${(props) => (props.isSelected ? `4px solid rgba(120, 149, 255, 1)` : '4px solid #F5F8FA')};
  a {
    height: 100%;
  }
  .hover {
    visibility: hidden;
  }
`

const GridView = ({ content, page, PAGESIZE, selectedIds, handleSelect, user }) => {
  const { recipeStats } = useContent()
  if (!content.length) return <EmptyState user={user} />

  return (
    <Grid>
      {content.slice((page - 1) * PAGESIZE, page * PAGESIZE - 1).map((recipe) => {
        if (recipe.type === 'MEAL_PLAN') {
          return (
            <SelectableCard
              key={recipe.id}
              isSelected={selectedIds.includes(recipe.id)}
              onSelect={() => handleSelect(recipe.id)}
              anySelected={selectedIds.length > 0}
              path={`/meal-plan/${recipe.id}`}
            >
              <MealPlanCard {...recipe} />
            </SelectableCard>
          )
        }
        if (recipe.type === 'POST') {
          return (
            <SelectableCard
              key={recipe.id}
              isSelected={selectedIds.includes(recipe.id)}
              onSelect={() => handleSelect(recipe.id)}
              anySelected={selectedIds.length > 0}
              path={`/post/${recipe.id}`}
            >
              <PostCard {...recipe} />
            </SelectableCard>
          )
        }

        // Recipe card rendering
        const image = recipe.coverImageUrl
          ? `${recipe.coverImageUrl}?width=500`
          : recipe.playbackId
          ? `https://image.mux.com/${recipe.playbackId}/thumbnail.jpg?time=0&width=500`
          : undefined

        let dateToShow = recipe.createdAt
        const isScheduled = recipe.published && !recipe.publishedAt
        if (isScheduled) {
          dateToShow = recipe.sendAt
        }
        if (recipe.publishedAt) {
          dateToShow = recipe.publishedAt
        }

        return (
          <Box key={recipe.id} minWidth="0px">
            <SelectableCard
              isSelected={selectedIds.includes(recipe.id)}
              onSelect={() => handleSelect(recipe.id)}
              anySelected={selectedIds.length > 0}
              path={`/recipe/${recipe.id}`}
            >
              <ContainerWithHover
                mr="30px"
                p="0px"
                height="400px"
                justifyContent="space-between"
                position="relative"
                overflowX="hidden"
                borderRadius="16px"
              >
                <Flex
                  flexDirection="column"
                  height="100%"
                  boxShadow={'0px 0px 1px rgba(12, 26, 75, 0.3), 0px 4px 20px -2px rgba(50, 50, 71, 0.24)'}
                >
                  <Flex cursor="pointer" noverflow="hidden" height="calc(100% - 100px)" position="relative">
                    <ImageContainer>
                      {image ? <VideoImage loading="lazy" src={image} /> : <Flex height="100%" backgroundColor={'#FDF4E8'} />}
                    </ImageContainer>

                    <Box position="absolute" top="12px" right="12px">
                      <Icon icon="recipe-white" width={24} height={24} />
                    </Box>

                    <Gradient position="absolute" top="0px" left="0px" height="100%" />
                    <Box position="absolute" bottom="8px" right={'8px'}>
                      <Text color="white" fontSize="12px" fontWeight="600">
                        {recipe.duration && formatSeconds(recipe.duration)}
                      </Text>
                    </Box>
                    <Box position="absolute" bottom="8px" left={'8px'}>
                      <Text color="white" fontSize="12px" fontWeight="600">
                        {isScheduled ? 'Scheduled ' : ''} {dayjs(dateToShow).format('DD MMM')}
                      </Text>
                    </Box>
                    <Flex width="auto" alignItems="center" position="absolute" bottom="8px" right={'8px'}>
                      <Icon icon="favourite-white" width={14} height={14} />
                      <Text color="white" fontSize="12px" ml="8px" fontWeight="600">
                        {recipeStats[recipe.id]?.favouriteCount || 0}
                      </Text>
                      <Icon icon="add-to-meal-plan-white" width={14} height={14} ml="16px" />
                      <Text color="white" fontSize="12px" ml="8px" fontWeight="600">
                        {recipeStats[recipe.id]?.mealPlanCount || 0}
                      </Text>
                    </Flex>
                    <GradientReverse className="hover" position="absolute" top="0px" left="0px" height="100%" />
                  </Flex>
                  <Flex
                    backgroundColor="white"
                    flexDirection="column"
                    py="8px"
                    pb={'16px'}
                    minHeight={'100px'}
                    height={'100px'}
                    justifyContent="space-between"
                  >
                    <H6 wordBreak="break-word" mb="0px" px={'8px'}>
                      {recipe.name && recipe.name.length > 50 ? recipe.name.slice(0, 50) + '...' : recipe.name}
                    </H6>
                    <Flex overflowX="scroll" pl={'8px'} noScrollBar>
                      {(recipe.tags || []).map((tag) => (
                        <Flex
                          key={tag}
                          alignItems="center"
                          backgroundColor={'rgba(225, 232, 255, 1)'}
                          width="auto"
                          height="28px"
                          mr="8px"
                          borderRadius="14px"
                          px="16px"
                          cursor="pointer"
                        >
                          <Text whiteSpace="nowrap" mb="2px" light fontWeight="500">
                            {tag}
                          </Text>
                        </Flex>
                      ))}
                    </Flex>
                  </Flex>
                </Flex>
              </ContainerWithHover>
            </SelectableCard>
          </Box>
        )
      })}
    </Grid>
  )
}

export default GridView
