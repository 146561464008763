import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
import { colors, formatSeconds } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import Icon from '../ui/Icon.js'
import Button from '../ui/Button.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import { useContent, useVideos } from '../ContentProvider.js'
import ModalContainer from '../ui/ModalContainer.js'
import { uniq, difference, intersection } from 'ramda'
import Popover from '../Components/ui/Popover.js'
import { CONTENT_TYPES } from './index.js'
import PopoverDropdown from '../ui/PopoverDropdown.js'

const InputContainer = styled(Flex)`
  border: 1px solid #cbd5e0;
  height: 36px;
  border-radius: 36px;
  background-color: white;
  overflow: hidden;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  width: 250px;
`

const TagContainer = styled(Flex)`
  cursor: pointer;
  width: 118px;
  min-width: 118px;
  justify-content: space-between;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  height: 36px;
  border-radius: 36px;
  background-color: white;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  margin-right: 16px;
`

const VerticalDivider = styled(Box)`
  border-right: 1px solid rgba(166, 183, 212, 1);
  width: 0px;
  margin-right: 16px;
  margin-left: 8px;
  height: 40px;
`

const InputComponent = styled.input`
  ::-webkit-input-placeholder {
    font-family: 'Inter', sans-serif;
  }
  ::-moz-placeholder {
    font-family: 'Inter', sans-serif;
  }
  :-ms-input-placeholder {
    font-family: 'Inter', sans-serif;
  }
  :-moz-placeholder {
    font-family: 'Inter', sans-serif;
  }
  background: white;
  height: 36px;
  margin: 0px;
  margin-bottom: 0px;
  border-radius: 6px;
  color: white;
  display: inline-block;
  border: none;
  font-size: 14px;
  text-decoration: none;
  color: ${colors.textDark};
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
`

const FullWidthForm = styled.form`
  margin-left: 16px;
  width: 100%;
`
export const FilterButton = styled(Flex)`
  cursor: pointer;
  width: 92px;
  min-width: 84px;
  justify-content: space-between;
  box-shadow: 0px 0px 1px 0px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  height: 36px;
  border-radius: 36px;
  background-color: white;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
`

const TabText = styled(Text)`
  ${(props) => !props.isActive && `color: ${colors.textLight};`}
  padding-bottom: 20px;
  ${(props) => props.isActive && `border-bottom: 1px solid ${colors.green};`}
  font-weight: 600;
  margin-right: 30px;
  cursor: pointer;
`

const Tab = ({ onClick, title, isActive, count }) => {
  return (
    <TabText onClick={onClick} isActive={isActive}>
      {title}
      {count ? (
        <Span ml="8px" light>
          ({count})
        </Span>
      ) : null}
    </TabText>
  )
}

const ViewToggle = styled(Flex)`
  width: auto;
  cursor: pointer;
  margin-left: ${({ ml }) => ml || '4px'};
  margin-right: ${({ mr }) => mr || '4px'};
`

const sortOptions = [
  { label: 'Newest first', value: 'newest' },
  { label: 'Oldest first', value: 'oldest' },
  { label: 'A-Z', value: 'az' },
  { label: 'Z-A', value: 'za' },
  { label: 'Most saved', value: 'saved' },
  { label: 'Most added to plan', value: 'planned' }
]

const Search = ({
  searchTerm,
  setSearchTerm,
  selectedTags,
  setPage,
  setSelectedTags,
  setShowPublished,
  setContentType,
  contentType,
  showPublished,
  selectAll,
  countPublished,
  countDraft,
  view,
  setView,
  setSortOption,
  sortOption
}) => {
  const [tempSearch, setTempSearch] = useState('')
  const [filterPopoverOpen, setFilterPopoverOpen] = useState(false)
  const [sortPopoverOpen, setSortPopoverOpen] = useState(false)
  const [tagModalOpen, setTagModalOpen] = useState(false)
  const { recipes, mealPlans, refresh } = useContent()
  const inputAllTags = uniq(
    [...recipes, ...mealPlans]
      .map((el) => el.tags)
      .flat()
      .filter((el) => el)
      .sort()
  )

  useEffect(() => {
    if (searchTerm !== tempSearch) {
      setTempSearch(searchTerm)
    }
  }, [searchTerm])

  const submit = async (e) => {
    e?.preventDefault()
    setPage(1)
    setSearchTerm(tempSearch)
  }

  return (
    <>
      <Flex mb="24px">
        <Flex borderBottom="1px solid #E2E8F0" justifyContent="space-between">
          <Flex width="auto">
            <Tab title="Published" count={countPublished} isActive={showPublished} onClick={() => setShowPublished(true)} />
            <Tab title="Draft" count={countDraft} isActive={!showPublished} onClick={() => setShowPublished(false)} />
          </Flex>
          <Flex width="auto" alignItems="center">
            <Flex
              alignItems="center"
              justifyContent="center"
              borderRadius="16px"
              height="28px"
              width="auto"
              mr="20px"
              cursor="pointer"
              onClick={() =>
                contentType === CONTENT_TYPES.RECIPE ? setContentType(CONTENT_TYPES.BOTH) : setContentType(CONTENT_TYPES.RECIPE)
              }
            >
              <Text
                fontWeight={contentType === CONTENT_TYPES.RECIPE ? '600' : '400'}
                color={contentType === CONTENT_TYPES.RECIPE ? colors.green : 'undefined'}
                light
              >
                Recipes
              </Text>
            </Flex>
            <Flex
              alignItems="center"
              justifyContent="center"
              borderRadius="16px"
              height="28px"
              width="auto"
              mr="20px"
              cursor="pointer"
              onClick={() =>
                contentType === CONTENT_TYPES.MEAL_PLAN
                  ? setContentType(CONTENT_TYPES.BOTH)
                  : setContentType(CONTENT_TYPES.MEAL_PLAN)
              }
            >
              <Text
                fontWeight={contentType === CONTENT_TYPES.MEAL_PLAN ? '600' : '400'}
                color={contentType === CONTENT_TYPES.MEAL_PLAN ? colors.green : 'undefined'}
                light
              >
                Plans
              </Text>
            </Flex>
            <Flex
              alignItems="center"
              justifyContent="center"
              borderRadius="16px"
              height="28px"
              width="auto"
              mr="20px"
              cursor="pointer"
              onClick={() =>
                contentType === CONTENT_TYPES.POST ? setContentType(CONTENT_TYPES.BOTH) : setContentType(CONTENT_TYPES.POST)
              }
            >
              <Text
                fontWeight={contentType === CONTENT_TYPES.POST ? '600' : '400'}
                color={contentType === CONTENT_TYPES.POST ? colors.green : 'undefined'}
                light
              >
                Posts
              </Text>
            </Flex>
            <InputContainer>
              <Icon icon="search-grey" width={14} height={14} />
              <FullWidthForm onSubmit={submit}>
                <InputComponent placeholder="Search ..." onChange={(e) => setTempSearch(e.target.value)} value={tempSearch} />
              </FullWidthForm>
              {tempSearch || searchTerm ? (
                <Icon
                  cursor="pointer"
                  icon="circle-cross-grey"
                  width={14}
                  height={14}
                  onClick={() => {
                    setSearchTerm('')
                    setTempSearch('')
                  }}
                />
              ) : null}
            </InputContainer>
            {inputAllTags && inputAllTags.length ? (
              <>
                <Popover
                  isOpen={filterPopoverOpen}
                  onClose={() => setFilterPopoverOpen(false)}
                  position="left"
                  content={
                    <Box maxHeight="300px" overflowY="auto" width="200px" p="16px" noScrollBar>
                      {inputAllTags
                        .sort((a, b) => a.localeCompare(b))
                        .map((tag) => (
                          <Flex
                            key={tag}
                            alignItems="center"
                            p="8px"
                            cursor="pointer"
                            justifyContent="space-between"
                            onClick={() => {
                              const isSelected = selectedTags.includes(tag)
                              if (isSelected) {
                                setSelectedTags(selectedTags.filter((t) => t !== tag))
                              } else {
                                setSelectedTags([...selectedTags, tag])
                              }
                            }}
                          >
                            <Text fontWeight="400" fontSize="14px">
                              {tag}
                            </Text>
                            {selectedTags?.includes(tag) && <Icon icon="tick-green-2" width={16} height={16} />}
                          </Flex>
                        ))}
                    </Box>
                  }
                >
                  <Box>
                    <Button
                      ml="8px"
                      onClick={() => setFilterPopoverOpen(!filterPopoverOpen)}
                      variant="search"
                      border="1px solid #E2E8F0"
                      label={'Filter' + (selectedTags && selectedTags.length ? ` (${selectedTags.length})` : '')}
                      height="36px"
                      backgroundColor={'white'}
                      renderRightIcon={() => <Icon icon="filter-black" ml="8px" width={16} height={16} />}
                    />
                  </Box>
                </Popover>
              </>
            ) : null}
            <Popover
              isOpen={sortPopoverOpen}
              onClose={() => setSortPopoverOpen(false)}
              position="left"
              content={
                <Box width="200px" p="16px">
                  {sortOptions.map((option) => (
                    <Flex
                      key={option.value}
                      alignItems="center"
                      p="8px"
                      cursor="pointer"
                      justifyContent="space-between"
                      onClick={() => {
                        setSortOption(option.value)
                        setSortPopoverOpen(false)
                      }}
                    >
                      <Text fontWeight="400" fontSize="14px">
                        {option.label}
                      </Text>
                      {sortOption === option.value && <Icon icon="tick-green-2" width={16} height={16} />}
                    </Flex>
                  ))}
                </Box>
              }
            >
              <Box>
                <Button
                  ml="8px"
                  onClick={() => setSortPopoverOpen(!sortPopoverOpen)}
                  variant="search"
                  border="1px solid #E2E8F0"
                  label={sortOptions.find((option) => option.value === sortOption)?.label || 'Sort'}
                  height="36px"
                  backgroundColor={'white'}
                  renderRightIcon={() => <Icon icon="chevron-down-black" ml="8px" width={10} height={10} />}
                />
              </Box>
            </Popover>

            {/* View toggle buttons */}
            <ViewToggle onClick={() => setView('list')} ml="12px">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.75 17.25C3.75 16.8358 4.08579 16.5 4.5 16.5H19.5C19.9142 16.5 20.25 16.8358 20.25 17.25C20.25 17.6642 19.9142 18 19.5 18H4.5C4.08579 18 3.75 17.6642 3.75 17.25Z"
                  fill={view === 'list' ? '#007D5B' : '#718096'}
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.75 11.25C3.75 10.8358 4.08579 10.5 4.5 10.5H19.5C19.9142 10.5 20.25 10.8358 20.25 11.25C20.25 11.6642 19.9142 12 19.5 12H4.5C4.08579 12 3.75 11.6642 3.75 11.25Z"
                  fill={view === 'list' ? '#007D5B' : '#718096'}
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.75 5.25C3.75 4.83579 4.08579 4.5 4.5 4.5H19.5C19.9142 4.5 20.25 4.83579 20.25 5.25C20.25 5.66421 19.9142 6 19.5 6H4.5C4.08579 6 3.75 5.66421 3.75 5.25Z"
                  fill={view === 'list' ? '#007D5B' : '#718096'}
                />
              </svg>
            </ViewToggle>
            <ViewToggle onClick={() => setView('grid')}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.5 3.75C1.5 2.50736 2.50736 1.5 3.75 1.5L8.25 1.5C9.49264 1.5 10.5 2.50736 10.5 3.75V8.25C10.5 9.49264 9.49264 10.5 8.25 10.5H3.75C2.50736 10.5 1.5 9.49264 1.5 8.25L1.5 3.75ZM3.75 3C3.33579 3 3 3.33579 3 3.75V8.25C3 8.66421 3.33579 9 3.75 9H8.25C8.66421 9 9 8.66421 9 8.25V3.75C9 3.33579 8.66421 3 8.25 3H3.75ZM13.5 3.75C13.5 2.50736 14.5074 1.5 15.75 1.5L20.25 1.5C21.4926 1.5 22.5 2.50736 22.5 3.75V8.25C22.5 9.49264 21.4926 10.5 20.25 10.5H15.75C14.5074 10.5 13.5 9.49264 13.5 8.25V3.75ZM15.75 3C15.3358 3 15 3.33579 15 3.75V8.25C15 8.66421 15.3358 9 15.75 9H20.25C20.6642 9 21 8.66421 21 8.25V3.75C21 3.33579 20.6642 3 20.25 3H15.75ZM1.5 15.75C1.5 14.5074 2.50736 13.5 3.75 13.5H8.25C9.49264 13.5 10.5 14.5074 10.5 15.75V20.25C10.5 21.4926 9.49264 22.5 8.25 22.5H3.75C2.50736 22.5 1.5 21.4926 1.5 20.25L1.5 15.75ZM3.75 15C3.33579 15 3 15.3358 3 15.75V20.25C3 20.6642 3.33579 21 3.75 21H8.25C8.66421 21 9 20.6642 9 20.25V15.75C9 15.3358 8.66421 15 8.25 15H3.75ZM13.5 15.75C13.5 14.5074 14.5074 13.5 15.75 13.5H20.25C21.4926 13.5 22.5 14.5074 22.5 15.75V20.25C22.5 21.4926 21.4926 22.5 20.25 22.5H15.75C14.5074 22.5 13.5 21.4926 13.5 20.25V15.75ZM15.75 15C15.3358 15 15 15.3358 15 15.75V20.25C15 20.6642 15.3358 21 15.75 21H20.25C20.6642 21 21 20.6642 21 20.25V15.75C21 15.3358 20.6642 15 20.25 15H15.75Z"
                  fill={view === 'grid' ? '#007D5B' : '#718096'}
                />
              </svg>
            </ViewToggle>
            {inputAllTags && inputAllTags.length ? (
              <>
                <PopoverDropdown
                  right
                  noHover
                  top="24px"
                  px="0px"
                  py="0px"
                  borderRadius="8px"
                  renderCustomLauncher={() => <Icon ml="8px" icon="three-dots-grey" width={16} height={16} cursor="pointer" />}
                  options={[
                    {
                      label: 'Edit Tags',
                      onClick: () => setTagModalOpen(true)
                    }
                  ]}
                  width="120px"
                />
                <TagModal
                  tagModalOpen={tagModalOpen}
                  setTagModalOpen={setTagModalOpen}
                  setSelectedTags={setSelectedTags}
                  setPage={setPage}
                  setSearchTerm={setSearchTerm}
                  inputAllTags={inputAllTags}
                  refresh={refresh}
                />
              </>
            ) : null}
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}

const TagModal = ({ tagModalOpen, setTagModalOpen, setSelectedTags, setPage, setSearchTerm, inputAllTags, refresh }) => {
  const [activeTag, setActiveTag] = useState(null)
  const [editTagModalOpen, setEditTagModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [editTagText, setEditTagText] = useState('')

  const deleteTag = async () => {
    const res = await api.post('/content/delete-tag', {
      tag: activeTag
    })
    refresh()
    setDeleteModalOpen(false)
    setSelectedTags([])
  }

  const editTag = async () => {
    const res = await api.post('/content/edit-tag', {
      oldTag: activeTag,
      newTag: editTagText.trim()
    })
    refresh()
    setEditTagModalOpen(false)
    setSelectedTags([])
  }

  return (
    <>
      <ModalContainer interiorClose p="0px" width="400px" autoHeight isOpen={tagModalOpen} setIsOpen={setTagModalOpen}>
        <Flex p="16px" borderBottom="1px solid #EDF2F7;">
          <Text fontWeight="600" fontSize="18px" width="100%">
            Manage Tags
          </Text>
        </Flex>
        <Flex p="16px" flexDirection="column" maxHeight="400px" overflowY="scroll" noScrollBar>
          {inputAllTags.map((tag) => {
            return (
              <Flex mb="16px" key={tag} alignItems="center" justifyContent="space-between">
                <Flex
                  alignItems="center"
                  bg="rgba(225, 232, 255, 1)"
                  width="auto"
                  height="36px"
                  mr="8px"
                  borderRadius="20px"
                  px="16px"
                  key={tag}
                  position="relative"
                  onClick={() => {
                    setPage(1)
                    setSelectedTags([tag])
                    setSearchTerm('')
                    setTagModalOpen(false)
                  }}
                  cursor="pointer"
                >
                  <Text mb="2px" whiteSpace="nowrap" light fontWeight="500">
                    {tag}
                  </Text>
                </Flex>
                <Flex width="auto" alignItems="center">
                  <Box borderRight="1px solid #EDF2F7;" pr="8px" mr="8px">
                    <Text
                      fontWeight="400"
                      cursor="pointer"
                      light
                      onClick={() => {
                        setActiveTag(tag)
                        setTagModalOpen(false)
                        setEditTagModalOpen(true)
                        setEditTagText(tag)
                      }}
                    >
                      Edit
                    </Text>
                  </Box>
                  <Text
                    fontWeight="400"
                    cursor="pointer"
                    light
                    onClick={() => {
                      setActiveTag(tag)
                      setTagModalOpen(false)
                      setDeleteModalOpen(true)
                    }}
                  >
                    Delete
                  </Text>
                </Flex>
              </Flex>
            )
          })}
        </Flex>
      </ModalContainer>
      <ModalContainer interiorClose p="24px" width="400px" autoHeight isOpen={deleteModalOpen} setIsOpen={setDeleteModalOpen}>
        <Text fontWeight="600" fontSize="18px" width="100%" mb="8px">
          Delete Tag
        </Text>
        <Text width="100%" fontWeight="400" fontSize="14px" lightLight mb="24px">
          Deleting the "{activeTag}" tag will remove it from all content.
        </Text>
        <Flex justifyContent="flex-end">
          <Button
            zIndex={1}
            variant="secondary"
            label="Cancel"
            width="80px"
            mr="16px"
            onClick={() => {
              setTagModalOpen(true)
              setDeleteModalOpen(false)
            }}
          />

          <Button zIndex={1} variant="red" label="Delete" width="80px" onClick={deleteTag} />
        </Flex>
      </ModalContainer>
      <ModalContainer interiorClose p="24px" width="400px" autoHeight isOpen={editTagModalOpen} setIsOpen={setEditTagModalOpen}>
        <Flex flexDirection="column">
          <Text fontWeight="600" fontSize="18px" width="100%" mb="8px">
            Edit Tag
          </Text>
          <Text width="100%" fontWeight="400" fontSize="14px" lightLight mb="16px">
            Edit the "{activeTag}" tag.
          </Text>
          <Input autofocus dark width="100%" mb="24px" value={editTagText} onChange={(e) => setEditTagText(e.target.value)} />
        </Flex>
        <Flex justifyContent="flex-end">
          <Button
            zIndex={1}
            variant="secondary"
            label="Cancel"
            width="80px"
            mr="16px"
            onClick={() => {
              setTagModalOpen(true)
              setEditTagModalOpen(false)
            }}
          />

          <Button
            isDisabled={!editTagText || !editTagText.length}
            zIndex={1}
            variant="green"
            label="Save"
            width="80px"
            onClick={editTag}
          />
        </Flex>
      </ModalContainer>
    </>
  )
}

export default Search
