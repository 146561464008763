import React from 'react'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { Container, Flex } from '../ui/Layout.js'
import { SmallTitle, Text } from '../ui/Typography.js'
import { Td, Tr, HTr, HeaderText, Table, Th } from '../ui/Table.js'
import { formatSeconds } from '../ui/helpers.js'
import Icon from '../ui/Icon.js'
import { useContent } from '../ContentProvider.js'
import { Link } from 'react-router-dom'
import Image from '../ui/Image.js'
import { toTitleCase } from '../ui/formatters.js'

const LineClamp = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const HoverTr = styled(Tr)`
  .checkbox {
    opacity: ${(props) => (props.anySelected ? 1 : 0)};
  }

  &:hover {
    background-color: #f7fafc !important;
    .checkbox {
      opacity: 1;
    }
  }

  // Keep checkbox visible when row is selected
  &[data-selected='true'] .checkbox {
    opacity: 1;
  }
`

const ListView = ({ content, selectedIds, handleSelect, page, PAGESIZE }) => {
  const { recipes, mealPlans, posts, recipeStats } = useContent()

  const getContentLink = (item) => {
    switch (item.type) {
      case 'RECIPE':
        return `/recipe/${item.id}`
      case 'MEAL_PLAN':
        return `/meal-plan/${item.id}`
      case 'POST':
        return `/post/${item.id}`
      default:
        return '#'
    }
  }

  return (
    <Container p="0px" pt="16px">
      <Flex justifyContent="space-between" px="24px" mb="16px">
        <SmallTitle>Content</SmallTitle>
      </Flex>
      <Table>
        <HTr>
          <Th width="40px" paddingRight="0px">
            <HeaderText left>
              <Flex
                cursor="pointer"
                height="20px"
                justifyContent="center"
                onClick={() => {
                  if (selectedIds.length === content.length) {
                    handleSelect([])
                  } else {
                    handleSelect(content.map((item) => item.id))
                  }
                }}
              >
                <Icon
                  icon={
                    selectedIds.length === content.length && content.length > 0
                      ? 'checkbox-ticked-green'
                      : 'checkbox-unticked-grey'
                  }
                  width={20}
                  height={20}
                />
              </Flex>
            </HeaderText>
          </Th>
          <Th width="40px">
            <HeaderText left></HeaderText>
          </Th>
          <Th width="300px">
            <HeaderText left>NAME</HeaderText>
          </Th>
          <Th width="150px">
            <HeaderText left>TYPE</HeaderText>
          </Th>
          <Th width="150px">
            <HeaderText left>PUBLISHED</HeaderText>
          </Th>
          <Th width="100px">
            <HeaderText left>SAVES</HeaderText>
          </Th>
          <Th width="150px">
            <HeaderText left>ADDED TO PLAN</HeaderText>
          </Th>
          <Th width="300px">
            <HeaderText left>TAGS</HeaderText>
          </Th>
        </HTr>
        {content.slice((page - 1) * PAGESIZE, page * PAGESIZE - 1).map((item) => (
          <HoverTr
            key={item.id}
            height="80px"
            anySelected={selectedIds.length > 0}
            onClick={(e) => {
              if (selectedIds.length > 0) {
                handleSelect(item.id)
              } else {
                window.location.href = getContentLink(item)
              }
            }}
            style={{
              cursor: 'pointer',
              backgroundColor: selectedIds.includes(item.id) ? '#f7fafc' : 'transparent'
            }}
            data-selected={selectedIds.includes(item.id)}
          >
            <Td width="40px" left paddingRight="0px">
              <Flex
                className="checkbox"
                cursor="pointer"
                justifyContent="center"
                height="20px"
                onClick={(e) => {
                  e.stopPropagation()
                  handleSelect(item.id)
                }}
              >
                <Icon
                  icon={selectedIds.includes(item.id) ? 'checkbox-ticked-green' : 'checkbox-unticked-grey'}
                  width={20}
                  height={20}
                />
              </Flex>
            </Td>
            <Td width="45px" left>
              {(() => {
                let imageUrl
                if (item.type === 'RECIPE') {
                  imageUrl = item.coverImageUrl
                    ? `${item.coverImageUrl}?width=100&height=100`
                    : item.playbackId
                    ? `https://image.mux.com/${item.playbackId}/thumbnail.jpg?time=0&width=100`
                    : undefined
                } else {
                  imageUrl = item.coverImageUrl ? `${item.coverImageUrl}?width=100&height=100` : undefined
                }
                if (item.type === 'POST') {
                  imageUrl = item.thumbnailImageUrl
                }

                return imageUrl ? (
                  <Image src={imageUrl} width="45px" height="45px" minWidth="45px" objectFit="cover" borderRadius="8px" />
                ) : (
                  <Flex
                    width="45px"
                    height="45px"
                    backgroundColor="#F7FAFC"
                    borderRadius="8px"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {item.type === 'POST' && <Icon icon="post-grey" width="16px" height="16px" />}
                    {item.type === 'RECIPE' && <Icon icon="recipe-grey" width="16px" height="16px" />}
                    {item.type === 'MEAL_PLAN' && <Icon icon="meal-plan-grey" width="16px" height="16px" />}
                  </Flex>
                )
              })()}
            </Td>
            <Td left>
              <LineClamp fontWeight="600">{item.name || item.title}</LineClamp>
            </Td>
            <Td left>{toTitleCase(item.type.toLowerCase().replace('_', ' '))}</Td>
            <Td left lightLight>
              {item.publishedAt ? (
                dayjs(item.publishedAt).format('MMM DD, YYYY')
              ) : item.published ? (
                'Scheduled'
              ) : (
                <Text color="#CBD5E0">N/A</Text>
              )}
            </Td>
            <Td left lightLight width="60px">
              {item.type === 'RECIPE' && recipeStats[item.id] ? (
                recipeStats[item.id].favouriteCount
              ) : (
                <Text color="#CBD5E0">N/A</Text>
              )}
            </Td>
            <Td left lightLight width="150px">
              {item.type === 'RECIPE' && recipeStats[item.id] ? (
                recipeStats[item.id].mealPlanCount
              ) : (
                <Text color="#CBD5E0">N/A</Text>
              )}
            </Td>
            <Td left lightLight width="300px" paddingRight="0px">
              <Flex maxWidth="300px" flexWrap="wrap" noScrollBar>
                {(item.tags || []).slice(0, 3).map((tag) => (
                  <Flex
                    key={tag}
                    alignItems="center"
                    backgroundColor={'rgba(225, 232, 255, 1)'}
                    width="auto"
                    height="28px"
                    mr="4px"
                    borderRadius="14px"
                    px="12px"
                    cursor="pointer"
                    my="2px"
                  >
                    <Text fontSize="12px" whiteSpace="nowrap" light fontWeight="500">
                      {tag}
                    </Text>
                  </Flex>
                ))}
                {(item.tags || []).length > 3 && (
                  <Flex
                    alignItems="center"
                    backgroundColor={'rgba(225, 232, 255, 1)'}
                    width="auto"
                    height="28px"
                    mr="4px"
                    borderRadius="14px"
                    px="12px"
                    cursor="pointer"
                    my="2px"
                  >
                    <Text fontSize="12px" whiteSpace="nowrap" light fontWeight="500">
                      +{(item.tags || []).length - 3}
                    </Text>
                  </Flex>
                )}
              </Flex>
            </Td>
          </HoverTr>
        ))}
      </Table>
    </Container>
  )
}

export default ListView
