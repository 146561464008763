import { useEffect, useState } from 'react'
import { Flex } from '../ui/Layout.js'
import { LineClamp, Text } from '../ui/Typography.js'
import formikFormWrapper from '../formikFormWrapper.js'
import { Field } from 'formik'
import ProductPicker from '../Products/ProductPicker.js'
import dayjs from 'dayjs'
import api from '../api.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import Image from '../ui/Image.js'
import { useAuth } from '../Auth.js'
import styled from 'styled-components'
import { ReactSortable } from 'react-sortablejs'
import Icon from '../ui/Icon.js'
import { Input } from '../ui/Input.js'
const FormInput = formikFormWrapper(Input)

const ProductDisplay = styled(Flex)`
  margin-bottom: 8px;
  cursor: pointer;
  border-radius: 6px;
  box-shadow: 0px 0px 1px 0px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  &:hover {
    background-color: rgba(235, 242, 250, 1);
  }
`

const GrabIcon = styled(Icon)`
  cursor: grab;
`

const Products = ({ values, setFieldValue }) => {
  const { user } = useAuth()
  const [isOpen, setIsOpen] = useState(false)
  const [products, setProducts] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    fetchProducts()
  }, [])

  const fetchProducts = async () => {
    setIsLoading(true)
    try {
      const res = await api.get(`/products/get?organisationId=${user.organisationId}`)
      setProducts(res.data.payload?.sort((a, b) => dayjs(b.created_at).diff(dayjs(a.created_at))))
    } catch (e) {
      console.log(e)
    }
    setIsLoading(false)
  }

  const selectedProducts =
    values?.productIds && products?.length > 0
      ? values?.productIds?.map((id) => products.find((p) => Number(p.id) === Number(id))).filter((p) => p)
      : []
  const save = (products) => {
    setFieldValue(
      'productIds',
      products.map((p) => p.id)
    )
  }

  return (
    <>
      <Text mb="16px" color="black" fontSize="18px" fontWeight="600" width="100%">
        Products
      </Text>
      <Field large mb="24px" type="text" name="productTitle" label="Title" component={FormInput} dark />
      {isLoading ? (
        <Flex alignItems="center" justifyContent="center" height="100%">
          <BigDarkSpinner />
        </Flex>
      ) : (
        <>
          {selectedProducts.length > 0 && (
            <Flex flexDirection="column" mb="16px">
              <ReactSortable
                list={selectedProducts || []}
                setList={(newProducts) => {
                  setFieldValue(
                    'productIds',
                    newProducts.map((p) => p.id)
                  )
                }}
              >
                {selectedProducts.map((product) => (
                  <ProductDisplay onClick={() => setIsOpen(true)} key={product?.id} p="8px" alignItems="center">
                    <GrabIcon mr="8px" icon="drag-circles-grey" width="24px" height="24px" alt="drag icon" />
                    <Image
                      src={product?.imageUrl}
                      width="48px"
                      height="48px"
                      minWidth="48px"
                      minHeight="48px"
                      objectFit="cover"
                      borderRadius="4px"
                      mr="12px"
                    />
                    <Flex flexDirection="column">
                      <LineClamp lines={1} light fontWeight="600" fontSize="13px" lineHeight="20px">
                        {product?.name}
                      </LineClamp>
                    </Flex>
                  </ProductDisplay>
                ))}
              </ReactSortable>
            </Flex>
          )}
          <Text cursor="pointer" light onClick={() => setIsOpen(true)}>
            + Select Products
          </Text>
          <ProductPicker
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            selectedProducts={selectedProducts}
            saveFunction={save}
            products={products}
          />
        </>
      )}
    </>
  )
}

export default Products
