import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Formik, Field } from 'formik'
import { Error, H1, Text } from '../ui/Typography.js'
import { Flex, Box } from '../ui/Layout.js'
import { Input } from '../ui/Input.js'
import Button from '../ui/Button.js'
import ModalContainer from '../ui/ModalContainer.js'
import formikFormWrapper from '../formikFormWrapper.js'
import ImageUploadModal from '../ui/ImageUploadModal.js'
import Icon from '../ui/Icon.js'
import api from '../api.js'
import { motion, AnimatePresence } from 'framer-motion'
import SelectComponent from '../ui/Select.js'
import { currencyOptions } from '../Plans/types.js'

const FormInput = formikFormWrapper(Input)

const PulsingPlaceholder = styled.svg`
  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }

  rect {
    animation: pulse 1.5s ease-in-out infinite;
  }
`

const ProductModal = ({ isOpen, setIsOpen, refresh, inputProduct, setStep, step }) => {
  const [imageUploadOpen, setImageUploadOpen] = useState(false)

  useEffect(() => {
    if (inputProduct && inputProduct.id) {
      setStep(2)
    } else {
      setStep(1)
    }
  }, [inputProduct])

  const validate = (values) => {
    const errors = {}
    if (!values.name) {
      errors.name = 'Product name is required'
    } else if (values.name.length < 3) {
      errors.name = 'Product name must be at least 3 characters long'
    }
    if (!values.imageUrl) {
      errors.imageUrl = 'Product image is required'
    }
    if (values.price && !values.currency) {
      errors.currency = 'Product currency is required'
    }
    return errors
  }

  return (
    <ModalContainer
      width="640px"
      autoHeight
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      p="0px"
      interiorClose
      onClose={() => setStep(1)}
    >
      <Formik
        initialValues={{
          link: inputProduct?.link || '',
          name: inputProduct?.name || '',
          currency: inputProduct?.currency || '',
          imageUrl: inputProduct?.imageUrl || '',
          price: inputProduct?.price
        }}
        validate={validate}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            // Handle submission
            console.log(values)
            const response = await api.post('/products/create', { ...values, id: inputProduct?.id || undefined })
            console.log(response)
            setIsOpen(false)
          } catch (error) {
            console.error(error)
          }
          setSubmitting(false)
          refresh()
        }}
      >
        {({ values, handleSubmit, setFieldValue, isSubmitting, setSubmitting, errors, touched }) => {
          const getMetaData = async () => {
            setSubmitting(true)
            const response = await api.get(`/products/fetch-metadata?url=${values.link}`)
            if (response?.data?.success) {
              setFieldValue('name', response.data.payload.name)
              setFieldValue('price', response.data.payload.price)
              setFieldValue('currency', response.data.payload.currency)
              setFieldValue('imageUrl', response.data.payload.imageUrl)
              setSubmitting(false)
              setStep(2)
            } else {
              setSubmitting(false)
              setStep(2)
            }
          }
          return (
            <Flex flexDirection="column" width="100%">
              <Text fontSize="18px" fontWeight="600" py="32px" px="24px" lineHeight="normal">
                Product Details
              </Text>

              {step === 1 ? (
                <Flex flexDirection="column">
                  <Flex flexDirection="column" px="24px">
                    <Field label="Link" name="link" placeholder="https://product-link.com" component={FormInput} dark large />
                  </Flex>
                  <AnimatePresence>
                    {isSubmitting && (
                      <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: 'auto', opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.3 }}
                      >
                        <Flex flexDirection="column" alignItems="center" justifyContent="center" mt="32px">
                          <PulsingPlaceholder
                            width="584"
                            height="120"
                            viewBox="0 0 584 120"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect width="120" height="120" rx="16" fill="#EDF2F7" />
                            <rect x="141" width="443" height="24" rx="8" fill="#EDF2F7" />
                            <rect x="141" y="36" width="229" height="24" rx="8" fill="#EDF2F7" />
                          </PulsingPlaceholder>
                        </Flex>
                      </motion.div>
                    )}
                  </AnimatePresence>
                  <Flex
                    justifyContent="flex-end"
                    mt="32px"
                    height="60px"
                    borderTop="1px solid #EDF2F7"
                    alignItems="center"
                    pr="16px"
                  >
                    <Button
                      variant="green"
                      label="Create"
                      onClick={getMetaData}
                      disabled={!values.link || isSubmitting}
                      isLoading={isSubmitting}
                    />
                  </Flex>
                </Flex>
              ) : (
                <>
                  <Flex flexDirection="column" px="24px">
                    <Field
                      label="Link"
                      name="link"
                      placeholder="https://product-link.com"
                      component={FormInput}
                      dark
                      large
                      mb="16px"
                    />
                    <Field label="Name" name="name" placeholder="My product..." component={FormInput} dark large mb="16px" />

                    <Flex>
                      <Field
                        label="Price"
                        name="price"
                        placeholder="Optional"
                        component={FormInput}
                        dark
                        large
                        type="number"
                        mr="16px"
                        width="100%"
                      />
                      <Box width="200px" minWidth="200px">
                        <SelectComponent
                          label="Currency"
                          value={values.currency}
                          onChange={(option) => {
                            setFieldValue('currency', option.value)
                          }}
                          options={currencyOptions}
                          placeholder="Select"
                        />
                        {errors.currency && touched.currency && <Error mt="2px">{errors.currency}</Error>}
                      </Box>
                    </Flex>
                    <Text fontSize="12px" color="#A0AEC0" mt="4px" mb="16px">
                      Not recommended if you don't have control of the link price.
                    </Text>
                    <Text mb="8px">Product Image</Text>
                    <Flex
                      width="128px"
                      height="128px"
                      border="1px dashed #CBD5E0"
                      borderRadius="8px"
                      alignItems="center"
                      justifyContent="center"
                      cursor="pointer"
                      onClick={() => setImageUploadOpen(true)}
                      overflow="hidden"
                    >
                      {values.imageUrl ? (
                        <img
                          src={values.imageUrl}
                          alt="Product"
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            borderRadius: '4px'
                          }}
                        />
                      ) : (
                        <Icon icon="plus-grey" width="24px" height="24px" />
                      )}
                    </Flex>
                    {errors.imageUrl && touched.imageUrl && <Error mt="2px">{errors.imageUrl}</Error>}
                  </Flex>
                  <Flex
                    justifyContent="flex-end"
                    mt="32px"
                    height="60px"
                    borderTop="1px solid #EDF2F7"
                    alignItems="center"
                    pr="16px"
                  >
                    <Button variant="green" label={inputProduct ? 'Edit Product' : 'Add Product'} onClick={handleSubmit} />
                  </Flex>
                </>
              )}

              <ImageUploadModal
                isOpen={imageUploadOpen}
                setIsOpen={setImageUploadOpen}
                saveFunction={(urls) => {
                  setFieldValue('imageUrl', urls[0])
                }}
              />
            </Flex>
          )
        }}
      </Formik>
    </ModalContainer>
  )
}

export default ProductModal
