import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

// Third-party utilities
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'
import { motion, AnimatePresence } from 'framer-motion'
import { Formik, Field } from 'formik'

// Core utilities and API
import api from '../api.js'
import { useAuth } from '../Auth.js'
import formikFormWrapper from '../formikFormWrapper.js'
import { colors, formatSeconds, formatStepTime } from '../ui/helpers.js'
import { BUTTON_FILL, BUTTON_ROUNDING, CARD_OPTIONS, buttonOptions } from './types.js'

// UI Components
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle, Label } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import { Input, TextArea } from '../ui/Input.js'
import Button from '../ui/Button.js'
import Icon from '../ui/Icon.js'
import Image from '../ui/Image.js'
import SelectComponent from '../ui/Select.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import ColorPicker, { ColorPickerElement, ColorPickerRow } from '../ui/ColorPicker.js'
import ToggleRedGreen from '../ui/ToggleTwoOptions.js'
import CircleToggle from '../ui/CircleToggle.js'
import TickBox from '../ui/TickBox.js'
import Checkbox from '../ui/Checkbox.js'
import DateTimePicker from '../ui/DateTimePicker.js'
import CodeCopy from '../ui/CodeCopy.js'
import usePrompt from '../ui/BlockerPrompt.js'

// Modals
import ImageUploadModal from '../ui/ImageUploadModal.js'
import VideoUploadModal from '../ui/VideoUploadModal.js'
import TagsModal from '../ui/TagsModalV2.js'
import ModalContainer from '../ui/ModalContainer.js'
import PublishModal from './PublishModal.js'
import PreviewModal from './PreviewModal.js'

// Recipe-specific components
import VideoPlayer from '../Components/VideoPlayer.js'
import ProcessingPreview from '../Components/ProcessingPreview.js'
import RecipeSteps from './Steps.js'
import Ingredients from './Ingredients.js'
import Nutrition from './Nutrition.js'
import Products from './Products.js'

// Add import
import Notes from './Notes.js'

// Initialize dayjs plugin
dayjs.extend(relativeTime)

// Form wrappers
const FormInput = formikFormWrapper(Input)
const FormTextArea = formikFormWrapper(TextArea)

const HoverFlexButton = styled(Flex)`
  button {
    display: none !important;
  }
  &:hover {
    button {
      display: block !important;
    }
  }
`

const Recipe = (props) => {
  const navigate = useNavigate()
  const { user } = useAuth()
  let { recipeId } = useParams()
  const [inputRecipe, setInputRecipe] = useState()
  const [videoUploadOpen, setVideoUploadOpen] = useState(false)
  const [showTagsModal, setShowTagsModal] = useState(false)
  const [coverImageUploadOpen, setCoverImageUploadOpen] = useState(false)
  const [publishModalOpen, setPublishModalOpen] = useState(false)
  const [previewModalOpen, setPreviewModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState()

  console.log(data)

  const [stepErrors, setStepErrors] = useState({})

  const refresh = async () => {
    const res = await api.get(`/content/get-recipe?id=${recipeId}`)

    // Add a random id to each step and ingredient only if they don't already have one
    const recipe = {
      ...res.data.payload,
      recipeSteps:
        res.data.payload?.recipeSteps?.map((step) => ({
          ...step,
          id: step.id || uuidv4()
        })) || [],
      ingredients:
        res.data.payload?.ingredients?.map((ingredient) => ({
          ...ingredient,
          id: ingredient.id || uuidv4()
        })) || []
    }

    setInputRecipe(recipe)
    setIsLoading(false)
  }

  const fetchData = async () => {
    const res = await api.get(`/content/get-admin-recipe-data?id=${recipeId}`)
    setData(res.data.payload)
  }

  useEffect(() => {
    if (recipeId) {
      refresh()
      fetchData()
    } else {
      setIsLoading(false)
    }
  }, [])

  return (
    <>
      <Flex flexDirection="column" alignItems="center">
        {!isLoading ? (
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={
              inputRecipe
                ? inputRecipe
                : {
                    sendTo: 'NONE',
                    published: false,
                    hasStepPlayer: false,
                    isFree: false,
                    portions: 1,
                    productTitle: 'Shop'
                  }
            }
            validate={(values) => {
              let errors = {}

              if (!values.name) {
                errors['name'] = 'Please add a name'
              }

              if (!values.recipeSteps || values.recipeSteps.length === 0) {
                errors['steps'] = 'Please add a step'
              }

              if (!values.coverImageUrl && !values.videoId) {
                errors['coverImageUrl'] = 'Please add a cover image or video'
                errors['videoId'] = 'Please add a cover image or video'
              }

              if (values.videoId && values.hasStepPlayer) {
                if (values.recipeSteps) {
                  const nonSectionSteps = values.recipeSteps.filter((step) => !step.isSection)
                  nonSectionSteps.forEach((step) => {
                    // Find the original index in the full array (including sections)
                    const originalIndex = values.recipeSteps.findIndex((s) => s === step)
                    // Find the previous non-section step
                    const prevStep = values.recipeSteps
                      .slice(0, originalIndex)
                      .filter((s) => !s.isSection)
                      .pop()

                    if (prevStep && step.time <= prevStep.time) {
                      errors[`step-${originalIndex}`] = 'Step time must be greater than the previous step'
                    }
                    if (typeof step.time === 'undefined') {
                      errors[`step-${originalIndex}`] = 'Please add a step time'
                    }
                  })
                }
              }
              return errors
            }}
            onSubmit={async (values, { setSubmitting, resetForm, setFieldValue }) => {
              setSubmitting(true)

              try {
                // Remove the id from each step
                const recipeSteps = values.recipeSteps.map((step) => ({
                  ...step,
                  chosen: undefined,
                  selected: undefined
                }))
                const ingredients = values.ingredients.map((ingredient) => ({
                  ...ingredient,
                  chosen: undefined,
                  selected: undefined
                }))
                const res = await api.post('/content/recipe-update', { ...values, recipeSteps, ingredients })
                console.log(res)
                if (!values.id || !recipeId) {
                  navigate(`/recipe/${res.data.payload.id}`)
                  resetForm({ values: { ...values, id: res.data.payload.id } })
                } else {
                  resetForm({ values: values })
                }
                console.log(res)
              } catch (e) {
                console.log(e)
              }
              setSubmitting(false)
            }}
          >
            {({
              values,
              dirty,
              touched,
              resetForm,
              setSubmitting,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              setFieldTouched,
              validateForm,
              errors
            }) => {
              console.log(touched)
              const [video, setVideo] = useState()
              useEffect(() => {
                if (values.videoId) {
                  const func = async () => {
                    const res = await api.get(`/video/get?id=${values.videoId}`)
                    setVideo(res.data.payload)
                    if (!res.data.payload.playbackId) {
                      setTimeout(() => {
                        func()
                      }, 3000)
                    }
                  }
                  func()
                }
              }, [values && values.videoId])

              const deleteRecipe = async () => {
                setSubmitting(true)
                const res = await api.post(`/content/recipe-delete`, { id: recipeId })
                navigate('/content')
                setSubmitting(false)
              }

              const publishRecipe = async () => {
                setPublishModalOpen(false)
                const res = await api.post(`/content/publish-recipe`, values)
                resetForm({ values: res.data.payload })
                handleSubmit()
              }

              const unpublishRecipe = async () => {
                const res = await api.post(`/content/unpublish-recipe`, { id: recipeId })
                setFieldValue('published', false)
                values.published = false
                setFieldValue('publishedAt', null)
                values.publishedAt = null
                handleSubmit()
              }

              const addStepAtTimeStamp = () => {
                const time = document.getElementById('recipe-player').currentTime
                console.log({ time })
                setFieldValue('recipeSteps', (values.recipeSteps || []).concat({ time, formattedTime: formatStepTime(time) }))
              }
              process.env.NODE_ENV === 'production' &&
                usePrompt("Are you sure you want to leave this page? You'll lose your changes", dirty && !isSubmitting)

              const errorForCoverImageOrVideo =
                (touched.coverImageUrl && errors.coverImageUrl) || (touched.videoId && errors.videoId)
              return (
                <>
                  <Flex
                    height="65px"
                    alignItems="center"
                    justifyContent="space-between"
                    backgroundColor="white"
                    borderBottom="2px solid #EEF2F7"
                    px="24px"
                    position="sticky"
                    top="0px"
                    zIndex={100}
                  >
                    <Link to={`/content?showPublished=${values.published}`}>
                      <Flex cursor="pointer" width="auto">
                        <Icon mt="4px" mr="13px" width="16px" height="16px" icon="chevron-left-grey" />
                        <Text lightLight fontSize="14px">
                          Close
                        </Text>
                      </Flex>
                    </Link>
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      position="absolute"
                      top="20px"
                      left="calc(50% - 100px)"
                      width="200px"
                    >
                      <Box
                        width="8px"
                        height="8px"
                        mr="8px"
                        borderRadius="8px"
                        backgroundColor={values.published ? 'rgba(102, 203, 159, 1)' : 'rgba(247, 147, 111, 1)'}
                      />
                      <Text light fontWeight="500">
                        {values.published ? (values.publishedAt ? 'Live' : 'Scheduled') : 'Draft'}
                      </Text>
                    </Flex>
                    <Flex width="auto" alignItems="center">
                      {errors && Object.keys(errors).length > 0 ? <Error mr="16px">Please check form errors</Error> : null}

                      {values.id ? (
                        <Flex
                          width="auto"
                          alignItems="center"
                          cursor="pointer"
                          onClick={() => setPreviewModalOpen(true)}
                          mr="24px"
                        >
                          <Icon icon="preview-grey" width="16px" height="16px" />
                          <Text light ml="8px" fontWeight="500">
                            Preview
                          </Text>
                        </Flex>
                      ) : null}
                      <Button
                        isDisabled={!dirty}
                        isLoading={isSubmitting}
                        variant={!values.published ? 'secondary' : 'green'}
                        label="Save"
                        onClick={async () => {
                          if (isSubmitting) {
                            return
                          }
                          const formErrors = await validateForm()
                          if (Object.keys(formErrors).length === 0) {
                            resetForm({ values: values })
                            handleSubmit()
                          } else {
                            // Force all fields to be touched to show errors
                            Object.keys(formErrors).forEach((field) => setFieldTouched(field, true, false))
                          }
                        }}
                      />
                      {values.published && values.publishedAt ? (
                        <a href={`https://${user.subdomain}.theclubb.co/recipe/${values.id}`} target="_blank" rel="noreferrer">
                          <Button
                            ml="16px"
                            isLoading={isSubmitting}
                            variant="secondary"
                            renderLeftIcon={() => <Icon icon="open-green" width={12} height={12} />}
                            label="Open"
                          />
                        </a>
                      ) : null}

                      {!values.published ? (
                        <Button
                          ml="16px"
                          isLoading={isSubmitting}
                          variant="green"
                          label="Publish"
                          onClick={async () => {
                            const errors = await validateForm()
                            if (errors && Object.keys(errors).length > 0) {
                              return
                            }
                            setPublishModalOpen(true)
                          }}
                        />
                      ) : null}
                    </Flex>
                  </Flex>
                  <Flex height="100%" justifyContent="center">
                    <Flex flexDirection="column" width="auto" height="100%">
                      <Flex px="32px" flexDirection="column" width="auto" pt="40px">
                        {!values.coverImageUrl ? (
                          <Flex
                            cursor="pointer"
                            onClick={() => setCoverImageUploadOpen(true)}
                            border="1px dashed #CBD5E0"
                            height="429px"
                            width="322px"
                            borderRadius="16px"
                            backgroundColor="white"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Text light fontSize="16px" fontWeight="500" mb="16px" objectFit="cover">
                              Cover Image
                            </Text>
                            <Button variant="secondary" label="Upload" />
                            {errorForCoverImageOrVideo ? <Error mt="16px">{errors.coverImageUrl}</Error> : null}
                          </Flex>
                        ) : null}
                        {values.coverImageUrl ? (
                          <HoverFlexButton
                            position="relative"
                            cursor="pointer"
                            backgroundColor="white"
                            onClick={() => setCoverImageUploadOpen(true)}
                            height="429px"
                            width="322px"
                            borderRadius="16px"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Image
                              src={values.coverImageUrl + '?width=600&height=600'}
                              width="100%"
                              height="100%"
                              objectFit="cover"
                              borderRadius="16px"
                            />
                            <Button
                              zIndex="1"
                              position="absolute"
                              pill
                              bottom="16px"
                              right="16px"
                              variant="grey"
                              label="Change"
                              onClick={() => setCoverImageUploadOpen(true)}
                            />
                          </HoverFlexButton>
                        ) : null}
                        <ImageUploadModal
                          isOpen={coverImageUploadOpen}
                          setIsOpen={setCoverImageUploadOpen}
                          saveFunction={(urls) => {
                            setFieldValue('coverImageUrl', urls[0])
                            setCoverImageUploadOpen(false)
                          }}
                        />
                      </Flex>
                      <Flex px="32px" flexDirection="column" width="auto" pt="24px" height="100%">
                        {!video ? (
                          <Flex
                            cursor="pointer"
                            onClick={() => setVideoUploadOpen(true)}
                            border="1px dashed #CBD5E0"
                            height="544px"
                            width="322px"
                            borderRadius="16px"
                            backgroundColor="white"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Text light fontSize="16px" fontWeight="500" mb="16px">
                              Add a video
                            </Text>
                            <Button variant="secondary" label="Upload" />
                            {errorForCoverImageOrVideo ? <Error mt="16px">{errors.videoId}</Error> : null}
                          </Flex>
                        ) : null}
                        {video && video.playbackId ? (
                          <Flex flexDirection="column" bg="white" p="16px" borderRadius="16px" width="322px">
                            <HoverFlexButton position="relative" width="auto" mb="16px">
                              <VideoPlayer
                                id="recipe-player"
                                videoStyle={{
                                  width: `${290}px`,
                                  height: `${290 / video.aspectRatioCalc}px`,
                                  borderRadius: '16px'
                                }}
                                src={`https://stream.mux.com/${video.playbackId}.m3u8`}
                              />
                            </HoverFlexButton>
                            <Flex justifyContent="center" mb="48px">
                              <Button
                                variant="secondary"
                                fontSize="12px"
                                label="Edit"
                                mr="8px"
                                width="70px"
                                minWidth="70px"
                                onClick={() => setVideoUploadOpen(true)}
                              />

                              <Button
                                zIndex="1"
                                variant="green"
                                width="100%"
                                fontSize="12px"
                                px="12px"
                                label="Add step at current timestamp"
                                onClick={() => {
                                  addStepAtTimeStamp()
                                }}
                              />
                            </Flex>
                            <Text fontWeight="600" mb="16px">
                              Video Player Format
                            </Text>
                            <Flex cursor="pointer" mb="16px" onClick={() => setFieldValue('hasStepPlayer', true)}>
                              <Box mr="16px" mt="2px">
                                <CircleToggle color="rgba(0, 125, 91, 1)" toggled={values.hasStepPlayer} width={20} />
                              </Box>
                              <Flex flexDirection="column">
                                <Text fontWeight="500">Step by Step</Text>
                                <Text lineHeight="130%" light>
                                  Story style navigation between steps with a toggle for standard player
                                </Text>
                              </Flex>
                            </Flex>
                            <Flex cursor="pointer" mb="16px" onClick={() => setFieldValue('hasStepPlayer', false)}>
                              <Box mr="16px" mt="2px">
                                <CircleToggle color="rgba(0, 125, 91, 1)" toggled={!values.hasStepPlayer} width={20} />
                              </Box>
                              <Flex flexDirection="column">
                                <Text fontWeight="500">Standard player only</Text>
                                <Text lineHeight="130%" light>
                                  Regular player with play, pause and progress bar
                                </Text>
                              </Flex>
                            </Flex>
                          </Flex>
                        ) : null}
                        {video && !video?.playbackId ? (
                          <HoverFlexButton
                            cursor="pointer"
                            height="544px"
                            width="322px"
                            borderRadius="16px"
                            backgroundColor="white"
                            overflow="hidden"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            position="relative"
                          >
                            <ProcessingPreview />
                            <Button
                              zIndex="1"
                              position="absolute"
                              pill
                              bottom="16px"
                              right="16px"
                              variant="grey"
                              label="Change"
                              onClick={() => setVideoUploadOpen(true)}
                            />
                          </HoverFlexButton>
                        ) : null}
                      </Flex>
                    </Flex>

                    <Flex pt="40px" flexDirection="column" maxWidth="800px" mr="24px">
                      {values.published ? (
                        <Container p="24px" justifyContent="space-between" mb="32px">
                          <H3 mb="16px">Overview</H3>
                          <Text light fontSize="14px" fontWeight="500" mb="8px">
                            Activity
                          </Text>
                          <Flex>
                            <Flex flexDirection="column" border="1px solid #EDF2F7" borderRadius="8px" p="20px" mr="8px">
                              <Text light lineHeight="24px">
                                Bookmarks
                              </Text>
                              <Text fontSize="18px" fontWeight="600" lineHeight="normal">
                                {(data && data.favouritesCount) || 0}
                              </Text>
                            </Flex>
                            <Flex flexDirection="column" border="1px solid #EDF2F7" borderRadius="8px" p="20px" ml="8px">
                              <Text light lineHeight="24px">
                                Added to Plans
                              </Text>
                              <Text fontSize="18px" fontWeight="600" lineHeight="normal">
                                {(data && data.mealPlanCount) || 0}
                              </Text>
                            </Flex>
                          </Flex>
                          {values.sendTo && values.sendTo !== 'NONE' ? (
                            <>
                              <Text light fontSize="14px" fontWeight="500" mb="8px" mt="24px">
                                Email
                              </Text>
                              <Flex>
                                <Flex flexDirection="column" border="1px solid #EDF2F7" borderRadius="8px" p="20px" mr="8px">
                                  <Text light lineHeight="24px">
                                    Recipients
                                  </Text>
                                  <Text fontSize="18px" fontWeight="600" lineHeight="normal">
                                    {(data && data.sends) || 0}
                                  </Text>
                                </Flex>
                                <Flex flexDirection="column" border="1px solid #EDF2F7" borderRadius="8px" p="20px" ml="8px">
                                  <Text light lineHeight="24px">
                                    Open %
                                  </Text>
                                  <Text fontSize="18px" fontWeight="600" lineHeight="normal">
                                    {data && data.opens && data.opens > 0 && data.sends > 0
                                      ? Math.round((data.opens / data.sends) * 100)
                                      : 0}
                                    %
                                  </Text>
                                </Flex>
                                <Flex flexDirection="column" border="1px solid #EDF2F7" borderRadius="8px" p="20px" ml="8px">
                                  <Text light lineHeight="24px">
                                    Clicks %
                                  </Text>
                                  <Text fontSize="18px" fontWeight="600" lineHeight="normal">
                                    {data && data.clicks && data.clicks > 0 && data.sends > 0
                                      ? Math.round((data.clicks / data.sends) * 100 * 10) / 10
                                      : 0}
                                    %
                                  </Text>
                                </Flex>
                              </Flex>
                            </>
                          ) : null}
                        </Container>
                      ) : null}

                      <Container p="24px" justifyContent="space-between" mb="32px">
                        <H3 mb="16px">Details</H3>
                        {values.published ? (
                          <>
                            {values.updatedAt ? (
                              <>
                                <Text light mb={values.publishedAt ? '16px' : '0px'}>
                                  Last edited: {dayjs(values.updatedAt).format('MMMM D, YYYY h:mm A')}
                                </Text>
                              </>
                            ) : null}
                            {!values.publishedAt ? (
                              <>
                                <Text mb="16px" color="#D17757">
                                  Scheduled for {dayjs(values.sendAt).format('MMMM D, YYYY h:mm A')}
                                </Text>
                                <Box>
                                  <Button mb="24px" variant="secondary" label="Cancel" onClick={unpublishRecipe} />
                                </Box>
                              </>
                            ) : null}
                            <Flex borderBottom="1px solid rgba(237, 242, 247, 1)" mb="24px" />
                          </>
                        ) : null}
                        <Field mb="24px" large name="name" label="Recipe Name" component={FormInput} dark />
                        <Label>Introduction</Label>
                        <Field
                          mb="24px"
                          large
                          name="intro"
                          label="Recipe Intro"
                          rows={4}
                          resize="vertical"
                          component={FormTextArea}
                          dark
                        />
                        <Flex>
                          <Field
                            mb="16px"
                            mr="24px"
                            width="190px"
                            large
                            type="number"
                            name="cookingTime"
                            label="Prep Time"
                            subLabel="(Minutes)"
                            component={FormInput}
                            dark
                          />
                          <Field
                            mb="16px"
                            mr="24px"
                            width="190px"
                            large
                            type="number"
                            name="totalTime"
                            label="Cooking Time"
                            subLabel="(Minutes)"
                            component={FormInput}
                            dark
                          />
                          <Flex flexDirection="column">
                            <Label mb="16px">Total</Label>
                            <Text fontSize="14px" fontWeight="400">
                              {(values.totalTime ? values.totalTime : 0) + (values.cookingTime ? values.cookingTime : 0)} minutes
                            </Text>
                          </Flex>
                        </Flex>
                        <Text light mb="8px" fontWeight="500">
                          Tags
                        </Text>
                        <Flex flexWrap="wrap" noScrollBar>
                          {(values.tags || []).map((tag) => {
                            return (
                              <Flex
                                alignItems="center"
                                backgroundColor="rgba(225, 232, 255, 1)"
                                width="auto"
                                height="28px"
                                mr="8px"
                                mb="8px"
                                borderRadius="14px"
                                px="16px"
                                key={tag}
                                cursor="pointer"
                                onClick={() => setShowTagsModal(true)}
                              >
                                <Text whiteSpace="nowrap" mb="2px" light fontWeight="500">
                                  {tag}
                                </Text>
                              </Flex>
                            )
                          })}
                        </Flex>
                        <Text cursor="pointer" light onClick={() => setShowTagsModal(true)}>
                          + Add a tag
                        </Text>
                        <TagsModal
                          inputTags={values.tags}
                          saveFunction={(tags) => setFieldValue('tags', tags)}
                          isOpen={showTagsModal}
                          setIsOpen={setShowTagsModal}
                          isRecipes
                        />
                        <Label mb="16px" mt="24px">
                          Available to non subscribers
                        </Label>
                        <ToggleRedGreen
                          selected={values.isFree}
                          customActiveColor={colors.green}
                          value1={false}
                          value2={true}
                          setSelected={(x) => setFieldValue('isFree', x)}
                        />
                        <Flex borderBottom="1px solid rgba(237, 242, 247, 1)" my="24px" />
                        <Text mb="16px" fontWeight="600">
                          Optional
                        </Text>
                        <Field mb="24px" large name="pairing" label="Pairs well with" component={FormInput} dark />
                        <Notes values={values} setFieldValue={setFieldValue} />
                      </Container>

                      {/* STEPS */}
                      <Container p="24px" justifyContent="space-between" mb="32px">
                        <RecipeSteps
                          values={values}
                          errors={errors}
                          recipeSteps={values.recipeSteps || []}
                          setRecipeSteps={(steps) => {
                            setFieldValue('recipeSteps', steps)
                            setFieldTouched('recipeSteps', true)
                          }}
                        />
                      </Container>

                      {/* Ingredients */}
                      <Container p="24px" justifyContent="space-between" mb="32px">
                        <Ingredients
                          ingredients={values.ingredients || []}
                          setIngredients={(ingredients) => setFieldValue('ingredients', ingredients)}
                          setPortions={(x) => setFieldValue('portions', x)}
                          portions={values.portions}
                        />
                      </Container>

                      {/* Nutrition */}
                      <Container p="24px" justifyContent="space-between" mb="32px">
                        <Nutrition
                          values={values}
                          errors={errors}
                          nutrition={values.nutrition || []}
                          setNutrition={(steps) => {
                            setFieldValue('nutrition', steps)
                            setFieldTouched('nutrition', true)
                          }}
                        />
                      </Container>

                      {/* Products */}
                      <Container p="24px" justifyContent="space-between" mb="32px">
                        <Products values={values} setFieldValue={setFieldValue} />
                      </Container>

                      <Container p="24px" justifyContent="space-between" mb="32px">
                        <Text mb="8px" color="black" fontSize="18px" fontWeight="600" width="100%">
                          Embeds
                        </Text>
                        <Text mb="24px" light fontSize="14px" fontWeight="400" width="100%">
                          Add a bit of character
                        </Text>
                        <Flex>
                          <Flex
                            alignItems="center"
                            justifyContent="center"
                            width="60px"
                            minWidth="60px"
                            height="60px"
                            borderRadius="16px"
                            backgroundColor={colors.softBlue}
                            mr="24px"
                          >
                            <Icon icon="spotify-black" width={37} height={37} />
                          </Flex>
                          <Flex flexDirection="column">
                            <Field
                              mb="24px"
                              dark
                              placeholder={`Get's me in the mood`}
                              large
                              name="spotifyTitle"
                              label="Section Title"
                              component={FormInput}
                            />
                            <Field
                              mb="24px"
                              dark
                              placeholder={`https://open.spotify.com/track/...`}
                              large
                              name="spotifyEmbed"
                              label="Spotify song or playlist link"
                              component={FormInput}
                            />
                          </Flex>
                        </Flex>
                      </Container>

                      <Flex justifyContent="flex-end" alignItems="center" mb="32px">
                        {errors && Object.keys(errors).length > 0 ? <Error mr="16px">Please check form errors</Error> : null}

                        {values.publishedAt ? <Button variant="secondary" label="Unpublish" onClick={unpublishRecipe} /> : null}
                        {values.id ? (
                          <Button isLoading={isSubmitting} variant="red" label="Delete" ml="16px" onClick={deleteRecipe} />
                        ) : null}
                      </Flex>
                    </Flex>

                    <VideoUploadModal
                      isOpen={videoUploadOpen}
                      setIsOpen={setVideoUploadOpen}
                      saveFunction={(ids) => {
                        setFieldValue('videoId', ids[0])
                        setVideoUploadOpen(false)
                      }}
                    />
                    <PublishModal
                      isOpen={publishModalOpen}
                      setIsOpen={setPublishModalOpen}
                      setFieldValue={setFieldValue}
                      saveFunction={publishRecipe}
                      values={values}
                    />
                    <PreviewModal
                      values={values}
                      isOpen={previewModalOpen}
                      onClose={() => setPreviewModalOpen(false)}
                      urlPostFix="recipe"
                    />
                  </Flex>
                </>
              )
            }}
          </Formik>
        ) : (
          <Flex height="500px" justifyContent="center" alignItems="center">
            <BigDarkSpinner />
          </Flex>
        )}
      </Flex>
    </>
  )
}

export default Recipe
