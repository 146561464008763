import React, { useState, useEffect, useRef } from 'react'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, formatSeconds } from './helpers'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from './Typography'
import { Flex, Box, Container } from './Layout'
import Image from './Image'
import { Input } from './Input'
import Icon from './Icon'
import Button from './Button'
import { useContent, useVideos } from '../ContentProvider'
import ModalContainer from './ModalContainer'
import { uniq, difference } from 'ramda'

const BigInput = styled.input`
  border-radius: 8px;
  width: 100%;
  background: ${(props) => {
    if (props.disabled) {
      return '#EDF2F7'
    }
    return props.dark ? '#EDF2F7' : '#ffffff'
  }};
  height: ${(props) => (props.large ? '40px' : '36px')};
  margin: 0px;
  margin-bottom: 8px;
  border-radius: 6px;
  color: white;
  display: inline-block;
  border: none;
  font-size: ${(props) => (props.large ? '15px' : '14px')};
  padding-left: 24px;
  padding-right: 24px;
  border: 1px solid rgba(166, 183, 212, 1);
  text-decoration: none;
  color: ${colors.textDark};
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  ::-webkit-input-placeholder {
    font-family: 'Inter', sans-serif;
  }
  ::-moz-placeholder {
    font-family: 'Inter', sans-serif;
  }
  :-ms-input-placeholder {
    font-family: 'Inter', sans-serif;
  }
  :-moz-placeholder {
    font-family: 'Inter', sans-serif;
  }
  &&:focus {
    padding-left: 22.5px;
    border: 1.5px solid #7895ff;
    box-shadow: 0px 0px 0px 2px rgba(76, 111, 255, 0.3);
  }
`

const FullWidthForm = styled.form`
  width: 100%;
  position: relative;
`

const TagsModal = ({ saveFunction, inputTags, isOpen, setIsOpen, clearTagsOnSave }) => {
  const { recipes, mealPlans, posts, refresh } = useContent()

  const allTags = [...recipes, ...mealPlans, ...posts]
    .map((r) => r.tags)
    .flat()
    .filter((t) => t && t.trim())

  const [tags, setTags] = useState(inputTags || [])
  const [isAddingNewTag, setIsAddingNewTag] = useState(false)
  const [newTagValue, setNewTagValue] = useState('')
  const inputRef = useRef(null)
  const [selectedIndex, setSelectedIndex] = useState(-1)

  // Get filtered suggestions for reuse
  const filteredSuggestions = difference(allTags, tags)
    .filter((t) => t?.toLowerCase().includes(newTagValue.toLowerCase()))
    .filter((t) => t)
    .sort()

  // Get filtered selected tags
  const filteredSelectedTags = tags.filter((t) => t?.toLowerCase().includes(newTagValue.toLowerCase())).filter((t) => t)

  // Combined array for keyboard navigation - now includes potential new tag
  const allFilteredTags = [...filteredSelectedTags, ...filteredSuggestions]
  const showCreateNewTag = newTagValue && !allTags.some((el) => el.toLowerCase() === newTagValue.toLowerCase())
  if (showCreateNewTag) {
    allFilteredTags.push(newTagValue.trim())
  }

  // Handle keyboard navigation
  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault()
      setSelectedIndex((prev) => (prev < allFilteredTags.length - 1 ? prev + 1 : prev))
    } else if (e.key === 'ArrowUp') {
      e.preventDefault()
      setSelectedIndex((prev) => (prev > -1 ? prev - 1 : -1))
    } else if (e.key === 'Enter' && selectedIndex >= 0) {
      e.preventDefault()
      const selectedTag = allFilteredTags[selectedIndex]
      if (tags.includes(selectedTag)) {
        setTags(tags.filter((el) => el !== selectedTag))
      } else {
        setTags(uniq(tags.concat(selectedTag)))
      }
      setNewTagValue('')
      // setSelectedIndex(-1)
    }
  }

  // Reset selected index when input changes
  useEffect(() => {
    setSelectedIndex(0)
  }, [newTagValue])

  // Add new ref for the scrollable container
  const scrollContainerRef = useRef(null)
  const selectedItemRef = useRef(null)

  // Add scroll into view effect when selectedIndex changes
  useEffect(() => {
    if (selectedIndex >= 0 && selectedItemRef.current && scrollContainerRef.current) {
      const container = scrollContainerRef.current
      const element = selectedItemRef.current

      const containerRect = container.getBoundingClientRect()
      const elementRect = element.getBoundingClientRect()

      if (elementRect.bottom > containerRect.bottom) {
        container.scrollTop += elementRect.bottom - containerRect.bottom
      } else if (elementRect.top < containerRect.top) {
        container.scrollTop -= containerRect.top - elementRect.top
      }
    }
  }, [selectedIndex])

  const save = async () => {
    saveFunction(tags)
    if (clearTagsOnSave) {
      setTags([])
    }
    refresh()
  }

  return (
    <ModalContainer noClose p="0px" width="500px" height="350px" isOpen={isOpen} setIsOpen={setIsOpen} onClose={save}>
      <Flex flexDirection="column" overflow="hidden" height="350px">
        <Flex flexDirection="column" borderBottom={'1px solid #EDF2F7'} px="16px" pt="24px" pb="8px">
          <FullWidthForm
            onSubmit={(e) => {
              e.preventDefault()
              if (selectedIndex >= 0) {
                const selectedTag = filteredSuggestions[selectedIndex]
                setTags(uniq(tags.concat(selectedTag)))
              } else {
                const matchingTags = difference(allTags, tags)
                  .filter((t) => t?.toLowerCase().includes(newTagValue.toLowerCase()))
                  .filter((t) => t)
                  .sort()
                if (matchingTags?.length) {
                  setTags(uniq(tags.concat(matchingTags[0])))
                  setNewTagValue('')
                } else {
                  setTags(uniq(tags.concat(newTagValue.trim())))
                  setNewTagValue('')
                }
                setIsAddingNewTag(false)
              }
              setNewTagValue('')
              setSelectedIndex(-1)
            }}
          >
            <BigInput
              placeholder="Add tags..."
              value={newTagValue}
              ref={inputRef}
              autoFocus
              onChange={(e) => setNewTagValue(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </FullWidthForm>
        </Flex>
        <Flex flexDirection="column" overflowY="scroll" ref={scrollContainerRef}>
          {tags?.length ? (
            <Flex flexDirection="column" px="4px" pt="8px" pb="8px" borderBottom={'1px solid #EDF2F7'}>
              {filteredSelectedTags.map((l, index) => (
                <Flex
                  key={l}
                  ref={selectedIndex === index ? selectedItemRef : null}
                  backgroundColor={selectedIndex === index ? '#F7FAFC' : 'transparent'}
                  border={selectedIndex === index ? '1px solid #E2E8F0' : '1px solid white'}
                  py="4px"
                  pl="12px"
                  borderRadius="4px"
                >
                  <Flex
                    alignItems="center"
                    backgroundColor={'#425466'}
                    height="28px"
                    mr="8px"
                    borderRadius="14px"
                    px="16px"
                    width="fit-content"
                    onClick={(e) => {
                      e.stopPropagation()
                      setTags(tags.filter((el) => el !== l))
                    }}
                    cursor="pointer"
                  >
                    <Text color="white" mb="2px" mr="8px" light fontWeight="500">
                      {l}
                    </Text>
                    <Icon width="14px" height="14px" icon="circle-cross-white" />
                  </Flex>
                </Flex>
              ))}
            </Flex>
          ) : null}

          <Flex flexDirection="column" px="4px" pt="16px" pb="8px">
            {filteredSuggestions.map((l, index) => (
              <Flex
                key={l}
                ref={selectedIndex === index + filteredSelectedTags.length ? selectedItemRef : null}
                backgroundColor={selectedIndex === index + filteredSelectedTags.length ? '#F7FAFC' : 'transparent'}
                border={selectedIndex === index + filteredSelectedTags.length ? '1px solid #E2E8F0' : '1px solid white'}
                py="4px"
                pl="12px"
                borderRadius="4px"
              >
                <Flex
                  alignItems="center"
                  backgroundColor={'rgba(225, 232, 255, 1)'}
                  width="fit-content"
                  height="28px"
                  mr="8px"
                  borderRadius="14px"
                  px="16px"
                  onClick={() => {
                    if (tags.includes(l)) {
                      setTags(uniq(tags).filter((el) => el !== l))
                    } else {
                      setTags(uniq(tags.concat(l)))
                    }
                  }}
                  cursor="pointer"
                >
                  <Text color={tags.includes(l) ? 'white' : colors.textLight} mb="2px" light fontWeight="500">
                    {l}
                  </Text>
                  {tags.includes(l) && <Icon ml="8px" width="14px" height="14px" icon="circle-cross-white" />}
                </Flex>
              </Flex>
            ))}
            {newTagValue && !allTags.some((el) => el.toLowerCase() === newTagValue.toLowerCase()) ? (
              <Text
                light
                fontWeight="500"
                cursor="pointer"
                backgroundColor={selectedIndex === allFilteredTags.length - 1 ? '#F7FAFC' : 'transparent'}
                border={selectedIndex === allFilteredTags.length - 1 ? '1px solid #E2E8F0' : '1px solid white'}
                px="16px"
                py="4px"
                borderRadius="4px"
                onClick={() => {
                  setTags(uniq(tags.concat(newTagValue)))
                  setNewTagValue('')
                }}
              >
                + Create new tag "{newTagValue.trim()}"
              </Text>
            ) : null}
          </Flex>
        </Flex>
      </Flex>
    </ModalContainer>
  )
}

export default TagsModal
