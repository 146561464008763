import styled, { css } from 'styled-components'
import * as styledSystem from 'styled-system'
import { colors, fontFamily } from './helpers'
import { props } from 'bluebird'
const {
  space,
  width,
  height,
  minHeight,
  fontSize,
  lineHeight,
  maxWidth,
  textAlign,
  letterSpacing,
  display,
  color,
  fontWeight,
  border,
  position
} = styledSystem

const capitalizeFirstLetter = css`
  ::first-letter {
    text-transform: capitalize;
  }
`

const link = css`
  color: ${colors.green};
  cursor: pointer;
  text-decoration: none;
`

export const baseTextStyle = css`
  ${(props) => props.cursor && `cursor: ${props.cursor};`}
  ${(props) => props.wordBreak && `word-break: ${props.wordBreak};`}
  margin-top: 0px;
  color: ${colors.textDark};
  ${(props) => props.light && `color: ${colors.textLight};`}
  ${(props) => props.lightLight && `color: ${colors.textLightLight};`}
  font-size: 18px;
  font-family: ${fontFamily};
  ${space};
  ${display};
  ${width};
  ${maxWidth};
  ${textAlign};
  ${(props) => props.capitalize && capitalizeFirstLetter};
  ${fontSize};
  ${fontWeight};
  ${lineHeight};
  ${letterSpacing};
  ${color}
  ${(props) => props.link && link};
  ${(props) => props.underline && 'text-decoration: underline;'};
  ${(props) => props.whiteSpace && `white-space: ${props.whiteSpace};`}
  ${(props) => props.textTransform && `text-transform: ${props.textTransform};`}

  @media (max-width: 700px) {
    padding-left: 14px;
    padding-right: 14px;
  }
`

export const H1 = styled.h1`
  ${baseTextStyle};
  font-weight: 600;
  font-size: 20px;
  line-height: 136.02%;
  ${fontSize};
  ${fontWeight};
  ${lineHeight};
`

export const H2 = styled.h2`
  ${baseTextStyle};
  font-weight: 400;
  font-size: 16px;
  line-height: 162.02%;
  ${fontSize};
  ${fontWeight};
  ${lineHeight};
`

export const H3 = styled.h3`
  ${baseTextStyle};
  font-weight: 600;
  font-size: 18px;
  line-height: 21.78px;
  ${fontSize};
  ${fontWeight};
  ${lineHeight};
`

export const H6 = styled.h6`
  ${baseTextStyle};
  font-weight: 600;
  font-size: 14px;
  line-height: 21.78px;
`

export const Text = styled.p`
  font-weight: 400;
  ${baseTextStyle};
  line-height: 24px;
  font-size: 14px;
  ${fontSize};
  ${fontFamily};
  ${fontWeight};
  ${lineHeight};
  ${border};
  ${(props) => props.wordBreak && `word-break: ${props.wordBreak};`}
`

export const SmallTitle = styled(Text)`
  font-weight: 500;
  font-size: 14px;
  ${position};
`

export const Label = styled.p`
  font-weight: 500;
  line-height: 17px;
  margin-bottom: 8px;
  ${baseTextStyle};
  font-size: 14px;
  color: ${colors.textLight};
`

export const Error = styled.p`
  ${baseTextStyle};
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.error};
`

export const Span = styled.span`
  ${(props) => props.underline && 'text-decoration: underline;'};
  ${space};
  ${display};
  ${width};
  ${maxWidth};
  ${textAlign};
  ${(props) => props.capitalize && capitalizeFirstLetter};
  ${fontSize};
  ${fontWeight};
  ${lineHeight};
  ${letterSpacing};
  ${color}
  ${(props) => props.link && link};
`

export const Li = styled.li`
  p {
    display: inline-block;
  }
  ${space};
  ${display};
  ${width};
  ${maxWidth};
  ${textAlign};
  ${fontSize};
  ${fontWeight};
  ${lineHeight};
  ${letterSpacing};
`

export const GradientFont = styled(Text)`
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  color: white;
`

export const LineClamp = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props.lines || 1};
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: ${(props) => props.wordBreak || 'break-all'};
  ${height};
  ${minHeight};
`
