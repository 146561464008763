import React, { useState } from 'react'
import { Field } from 'formik'
import { Label } from '../ui/Typography.js'
import { TextArea } from '../ui/Input.js'
import formikFormWrapper from '../formikFormWrapper.js'
import { Flex } from '../ui/Layout.js'
import Icon from '../ui/Icon.js'
import ImageUploadModal from '../ui/ImageUploadModal.js'
import Image from '../ui/Image.js'
import styled from 'styled-components'
import { colors } from '../ui/helpers.js'

const FormTextArea = formikFormWrapper(TextArea)

const DeleteIconWrapper = styled(Flex)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 4px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  opacity: 0;
  &:hover {
    opacity: 1;
  }
`

const Notes = ({ values, setFieldValue }) => {
  const [openImageModal, setOpenImageModal] = useState(false)
  return (
    <>
      <Label>Notes:</Label>
      <Flex
        backgroundColor="#fbfbfb"
        boxShadow="0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2);"
        borderRadius="8px"
        flexDirection="column"
      >
        <TextArea
          noBorder
          onChange={(e) => {
            const value = e.target.value
            setFieldValue('notes', value)
          }}
          value={values.notes}
          placeholder="Enter details"
          name="notes"
          width="100%"
          mr="16px"
          mb="8px"
          height="100px"
        />
        <Flex px="24px" pb="16px" justifyContent="space-between" alignItems="center">
          {values.notesImages &&
            values.notesImages.length > 0 &&
            values.notesImages.map((image, i) => (
              <Flex
                key={image}
                position="relative"
                width="45px"
                height="45px"
                mr="8px"
                backgroundColor={'white'}
                borderRadius="8px"
                overflow="hidden"
                border="1px solid #E0E0E0"
              >
                <Image
                  src={image + '?width=100&height=100'}
                  width="45px"
                  height="45px"
                  minWidth="45px"
                  objectFit="contain"
                  borderRadius="8px"
                />
                <DeleteIconWrapper
                  onClick={() => {
                    const newImages = values.notesImages.filter((_, index) => index !== i)
                    setFieldValue('notesImages', newImages)
                  }}
                >
                  <Icon icon="bin-white" width={16} height={16} />
                </DeleteIconWrapper>
              </Flex>
            ))}
          {(values?.notesImages?.length < 1 || !values?.notesImages) && (
            <Flex ml={values?.notesImages?.length > 0 ? '8px' : '0px'} cursor="pointer" onClick={() => setOpenImageModal(true)}>
              <Icon cursor icon="image-grey2" width="16px" height="16px" />
            </Flex>
          )}
        </Flex>
        <ImageUploadModal
          isOpen={openImageModal}
          setIsOpen={(isOpen) => setOpenImageModal(isOpen)}
          saveFunction={(url) => {
            setFieldValue('notesImages', (values.notesImages || []).concat(url[0]))
          }}
        />
      </Flex>
    </>
  )
}

export default Notes
