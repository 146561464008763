import React, { useState, useEffect, useRef } from 'react'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import 'react-datepicker/dist/react-datepicker.css'
import { Link, useNavigate } from 'react-router-dom'
import { colors, shorten } from './helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from './Typography.js'
import { Flex, Box, Container } from './Layout.js'
import Image from './Image.js'
import { Input } from './Input.js'
import Icon from './Icon.js'
import Button from './Button.js'
import { BigDarkSpinner } from './Spinner.js'
import SideNav, { TABS } from '../Components/SideNav.js'
import VideoPlayer from '../Components/VideoPlayer'
import StatusPill from '../Components/StatusPill'
import ProcessingPreview from '../Components/ProcessingPreview'

import formikFormWrapper from '../formikFormWrapper.js'

const Popover = styled(Flex)`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: ${(props) => props.top || '50px'};
  width: ${(props) => props.width || '500px'};
  ${(props) => (props.right ? 'right: 0;' : 'left:0;')};
  z-index: 100;
  background-color: white;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: ${(props) => props.borderRadius || '16px'};
  max-height: 400px;
  overflow-y: scroll;
`

const Option = styled(Text)`
  width: 100%;
  font-size: 14px;
  padding: 8px;
  padding-left: 12px;
  padding-right: 12px;
  cursor: pointer;
  border-radius: 8px;
  font-weight: 500;
  text-align: left;
  color: ${(props) => props.color || 'inherit'};
  :hover {
    color: ${(props) => (props.noHover ? props.color || 'inherit' : 'white')};
    background-color: ${(props) => (props.noHover ? 'transparent' : colors.green)};
  }
`

const PopoverDropdown = ({ options, buttonLabel, right, renderCustomLauncher, width, px, py, top, noHover, borderRadius }) => {
  const [showPopover, setShowPopover] = useState(false)
  const popover = useRef(null)

  const closeMenu = (event) => {
    if (popover && popover.current && !popover.current.contains(event.target)) {
      document.removeEventListener('click', closeMenu)
      setShowPopover(false)
    }
  }

  const showPopoverFunc = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setShowPopover(true)
    document.addEventListener('click', closeMenu)
  }

  return (
    <Box position="relative">
      <Box cursor="pointer" onClick={showPopover ? closeMenu : showPopoverFunc}>
        {renderCustomLauncher ? (
          renderCustomLauncher()
        ) : (
          <Button
            pill
            label={buttonLabel}
            variant="white"
            shadow
            renderRightIcon={() => <Icon ml="16px" width="16px" height="16px" icon="filter-circle-black" />}
          />
        )}
      </Box>
      {showPopover ? (
        <Popover width={width} right={right} top={top} ref={popover} borderRadius={borderRadius}>
          <Flex>
            <Flex px={px || '12px'} py={py || '14px'} flexDirection="column">
              {options.map((option) => {
                return (
                  <Flex
                    key={option.label}
                    alignItems="center"
                    onClick={(e) => {
                      option.onClick(e)
                      setShowPopover(false)
                    }}
                  >
                    {option.renderLeftIcon ? option.renderLeftIcon() : null}
                    <Option color={option.color} noHover={noHover}>
                      {option.label}
                    </Option>
                  </Flex>
                )
              })}
            </Flex>
          </Flex>
        </Popover>
      ) : null}
    </Box>
  )
}

export default PopoverDropdown
