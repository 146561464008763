import React from 'react'
import styled from 'styled-components'
import hexToRgba from 'hex-to-rgba'
import { Flex } from '../../ui/Layout'
import { H3, Text } from '../../ui/Typography'
import { CONSISTENT_BOTTOM_MARGIN } from '../HomepageSectionsContainer'
import HomepageCarousel from '../../ui/HomepageCarousel'

const TestimonialCardContainer = styled(Flex)`
  flex-direction: column;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 8px;
  padding: 24px;
  width: 320px;
  min-width: 320px;
  height: 220px;
  overflow: hidden;
`

const StarsContainer = styled(Flex)`
  margin-bottom: 16px;
`

const TestimonialText = styled(Text)`
  flex-grow: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  font-size: 16px;
  font-weight: 500;
`

const StarSvg = styled.svg`
  margin-right: 4px;
`

export const StarIcon = ({ primaryButtonColor }) => (
  <StarSvg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.51536 19.3034C4.03231 19.5517 3.48535 19.1178 3.5826 18.5634L4.61976 12.6516L0.216846 8.45578C-0.19463 8.06366 0.0183705 7.34671 0.569607 7.2684L6.6927 6.39856L9.42285 0.990381C9.66879 0.503206 10.335 0.503206 10.5809 0.990381L13.3111 6.39856L19.4342 7.2684C19.9854 7.34671 20.1984 8.06366 19.7869 8.45578L15.384 12.6516L16.4212 18.5634C16.5184 19.1178 15.9715 19.5517 15.4884 19.3034L10.0019 16.4835L4.51536 19.3034Z"
      fill={primaryButtonColor}
    />
  </StarSvg>
)

const TestimonialCard = ({ name, testimonial, config }) => {
  return (
    <TestimonialCardContainer backgroundColor={hexToRgba(config.primaryFontColor, 0.1)}>
      <StarsContainer>
        <StarIcon primaryButtonColor={config.primaryButtonColor} />
        <StarIcon primaryButtonColor={config.primaryButtonColor} />
        <StarIcon primaryButtonColor={config.primaryButtonColor} />
        <StarIcon primaryButtonColor={config.primaryButtonColor} />
        <StarIcon primaryButtonColor={config.primaryButtonColor} />
      </StarsContainer>

      <TestimonialText light>{testimonial}</TestimonialText>

      <Text fontSize="16px" fontWeight="700" color={config.primaryFontColor}>
        {name}
      </Text>
    </TestimonialCardContainer>
  )
}

const TestimonialsCarousel = ({ title, testimonials, config, isActiveSubscriber }) => {
  if (isActiveSubscriber) {
    return null
  }
  if (!testimonials || testimonials.length === 0) {
    return null
  }

  return (
    <Flex flexDirection="column" mb={CONSISTENT_BOTTOM_MARGIN}>
      <H3 className="primary" fontSize="24px" fontWeight="700" lineHeight="136%" mb="24px" px="16px">
        {title}
      </H3>
      <HomepageCarousel
        title={title || 'What our customers say'}
        slideWidth={320}
        spacing={16}
        cards={testimonials.map((testimonial, index) => (
          <TestimonialCard key={index} name={testimonial.name} testimonial={testimonial.testimonial} config={config} />
        ))}
        pl="16px"
        plm="16px"
      />
    </Flex>
  )
}

export default TestimonialsCarousel
