import React, { useState, useEffect, useRef, useCallback } from 'react'
import styled from 'styled-components'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle, LineClamp, Label } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import { Input } from '../ui/Input.js'
import Icon from '../ui/Icon.js'
import Button from '../ui/Button.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import { useModal } from '../Modal.js'
const edjsHTML = require('editorjs-html')
const edjsParser = edjsHTML()
import EditorJS from '@editorjs/editorjs'
import Header from '@editorjs/header'
import List from '@editorjs/list'
import EditorLink from '@editorjs/link'
import EditorImage from '@editorjs/image'
import Paragraph from '@editorjs/paragraph'
import DragDrop from 'editorjs-drag-drop'
import AnyButton from 'editorjs-button'
import { Formik, Field } from 'formik'
import { useTheme } from '../ThemeProvider.js'
import ImageUploadModal, { getHeightAndWidthFromDataUrl } from '../ui/ImageUploadModal.js'
import formikFormWrapper from '../formikFormWrapper.js'
import CustomButton, { CustomButtonStyles } from './CustomButton.js'
import SlideUpModal from '../Components/SlideUpModal.js'
import PreviewModal from './PreviewModal.js'
import initEditor from './editorConfig.js'
import CircleToggle from '../ui/CircleToggle.js'
import DateTimePicker from '../ui/DateTimePicker.js'
import { motion, AnimatePresence } from 'framer-motion'
import { debounce } from 'lodash'
import HorizontalStepper from './HorizontalStepper.js'

const Posts = (props) => {
  let { user } = useAuth()
  const navigate = useNavigate()

  let { postId } = useParams()
  const ejInstance = useRef()
  const { theme } = useTheme()
  const [inputPost, setInputPost] = useState()

  const [isLoading, setIsLoading] = useState(true)
  const [thumbnailImageUploadOpen, setThumbnailImageUploadOpen] = useState(false)

  const [previewModalOpen, setPreviewModalOpen] = useState(false)

  const refresh = async () => {
    const res = await api.get(`/content/get-post?id=${postId}`)
    setInputPost(res.data.payload)
    setIsLoading(false)
  }

  useEffect(() => {
    if (postId) {
      refresh()
    } else {
      setIsLoading(false)
    }
  }, [])

  const autoResizeTextArea = (element) => {
    if (element) {
      element.style.height = 'auto'
      element.style.height = `${element.scrollHeight}px`
    }
  }

  return (
    <Flex minHeight="100vh" alignItems="center" justifyContent="center" flexDirection="column" backgroundColor="white">
      {isLoading ? (
        <Flex height="500px" justifyContent="center" alignItems="center">
          <BigDarkSpinner />
        </Flex>
      ) : (
        <Formik
          validateOnChange={true}
          validateOnBlur={true}
          initialValues={inputPost ? inputPost : {}}
          validate={(values) => {
            let errors = {}
            return errors
          }}
          onSubmit={async (values, { setSubmitting, resetForm, setFieldValue }) => {
            setSubmitting(true)
            const res = await api.post('/content/post-update', values)
            resetForm({ values })
            setSubmitting(false)
          }}
        >
          {({
            values,
            dirty,
            touched,
            resetForm,
            setSubmitting,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            validateForm,
            errors
          }) => {
            const debouncedSubmit = useCallback(
              debounce(() => {
                handleSubmit()
              }, 1000),
              [handleSubmit]
            )

            // Modify the useEffect to use the debounced submit
            useEffect(() => {
              if (dirty) {
                debouncedSubmit()
              }
            }, [values, dirty, debouncedSubmit])

            return (
              <>
                <Flex
                  minHeight="65px"
                  height="65px"
                  alignItems="center"
                  justifyContent="space-between"
                  backgroundColor="white"
                  borderBottom="2px solid #EEF2F7"
                  px="24px"
                  position="fixed"
                  top="0px"
                  zIndex={100}
                >
                  <Link to={values.published ? `/post/${postId}` : `/post/editor/${postId}`}>
                    <Flex cursor="pointer" width="auto">
                      <Icon mt="4px" mr="13px" width="16px" height="16px" icon="chevron-left-grey" />
                      <Text lightLight fontSize="14px">
                        Back
                      </Text>
                    </Flex>
                  </Link>
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    position="absolute"
                    top="20px"
                    left="calc(50% - 100px)"
                    width="200px"
                  >
                    <Box
                      width="8px"
                      height="8px"
                      mr="8px"
                      borderRadius="8px"
                      backgroundColor={values.published ? 'rgba(102, 203, 159, 1)' : 'rgba(247, 147, 111, 1)'}
                    />
                    <Text light fontWeight="500">
                      {values.published ? 'Published' : 'Draft'}
                    </Text>
                  </Flex>
                  <Flex width="auto">
                    <HorizontalStepper postId={postId} isValid />
                    <Flex width="auto" alignItems="center">
                      {errors && Object.keys(errors).length > 0 ? <Error mr="16px">Please check form errors</Error> : null}
                    </Flex>
                    <Link to={`/post/editor/${postId}`}>
                      <Button variant={'secondary'} label="Back" />
                    </Link>
                    <Box width="16px" />
                    <Link to={`/post/settings/${postId}`}>
                      <Button variant={'green'} label="Next" />
                    </Link>
                  </Flex>
                </Flex>
                <Flex
                  mt="65px"
                  height="calc(100vh - 65px)"
                  width="100%"
                  alignItems="flex-start"
                  justifyContent="center"
                  bg="#f7fafc"
                  pt="24px"
                >
                  <Flex
                    width="640px"
                    flexDirection="column"
                    bg="white"
                    borderRadius="16px"
                    p="24px"
                    boxShadow="0px 0px 1px 0px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)"
                  >
                    <Text fontSize="18px" fontWeight="600" lineHeight="normal" mb="40px">
                      Publishing
                    </Text>

                    <Label mb="16px">Publish to</Label>
                    <Flex mb="40px">
                      <Flex cursor="pointer" onClick={() => setFieldValue('publishTo', 'EMAIL_WEB')} width="auto" mr="24px">
                        <CircleToggle toggled={values.publishTo === 'EMAIL_WEB'} width={24} />
                        <Text ml="10px" fontWeight="500">
                          Email and Web
                        </Text>
                      </Flex>
                      <Flex cursor="pointer" onClick={() => setFieldValue('publishTo', 'EMAIL')} width="auto" mr="24px">
                        <CircleToggle toggled={values.publishTo === 'EMAIL'} width={24} />
                        <Text ml="10px" fontWeight="500">
                          Email only
                        </Text>
                      </Flex>
                      <Flex cursor="pointer" onClick={() => setFieldValue('publishTo', 'WEB')} width="auto">
                        <CircleToggle toggled={values.publishTo === 'WEB'} width={24} />
                        <Text ml="10px" fontWeight="500">
                          Web Only
                        </Text>
                      </Flex>
                    </Flex>
                    {/* <Label mb="16px">The content is for</Label>
                    <Flex mb="40px">
                      <Flex cursor="pointer" onClick={() => setFieldValue('contentFor', 'PAID')} width="auto" mr="24px">
                        <CircleToggle toggled={values.contentFor === 'PAID'} width={24} />
                        <Text ml="10px" fontWeight="500">
                          Paid subscribers
                        </Text>
                      </Flex>
                      <Flex cursor="pointer" onClick={() => setFieldValue('contentFor', 'EVERYONE')} width="auto">
                        <CircleToggle toggled={values.contentFor === 'EVERYONE'} width={24} />
                        <Text ml="10px" fontWeight="500">
                          Everyone
                        </Text>
                      </Flex>
                    </Flex> */}
                    <AnimatePresence>
                      {values.publishTo !== 'WEB' && (
                        <motion.div
                          initial={{ height: 0, opacity: 0 }}
                          animate={{ height: 'auto', opacity: 1 }}
                          exit={{ height: 0, opacity: 0 }}
                          transition={{ duration: 0.3 }}
                        >
                          <Label mb="16px">Send to</Label>
                          <Flex mb="40px">
                            <Flex cursor="pointer" onClick={() => setFieldValue('sendTo', 'PAID')} width="auto" mr="24px">
                              <CircleToggle toggled={values.sendTo === 'PAID'} width={24} />
                              <Text ml="10px" fontWeight="500">
                                Paid subscribers
                              </Text>
                            </Flex>
                            <Flex cursor="pointer" onClick={() => setFieldValue('sendTo', 'FREE')} width="auto" mr="24px">
                              <CircleToggle toggled={values.sendTo === 'FREE'} width={24} />
                              <Text ml="10px" fontWeight="500">
                                Free subscribers
                              </Text>
                            </Flex>
                            <Flex cursor="pointer" onClick={() => setFieldValue('sendTo', 'EVERYONE')} width="auto" mr="24px">
                              <CircleToggle toggled={values.sendTo === 'EVERYONE'} width={24} />
                              <Text ml="10px" fontWeight="500">
                                Everyone
                              </Text>
                            </Flex>
                          </Flex>
                        </motion.div>
                      )}
                    </AnimatePresence>
                    <AnimatePresence>
                      {values.sendTo !== 'NONE' && (
                        <motion.div
                          initial={{ height: 0, opacity: 0 }}
                          animate={{ height: 'auto', opacity: 1 }}
                          exit={{ height: 0, opacity: 0 }}
                          transition={{ duration: 0.3 }}
                        >
                          <Label mb="16px">Publish & Send</Label>
                          <Flex mb="40px">
                            <Flex cursor="pointer" onClick={() => setFieldValue('sendNow', true)} width="auto" mr="24px">
                              <CircleToggle toggled={values.sendNow} width={24} />
                              <Text ml="10px" fontWeight="500">
                                Now
                              </Text>
                            </Flex>
                            <Flex cursor="pointer" onClick={() => setFieldValue('sendNow', false)} width="auto">
                              <CircleToggle toggled={!values.sendNow} width={24} />
                              <Text ml="10px" fontWeight="500">
                                On schedule
                              </Text>
                            </Flex>
                          </Flex>
                          <AnimatePresence>
                            {!values.sendNow && (
                              <motion.div
                                initial={{ height: 0, opacity: 0 }}
                                animate={{ height: 'auto', opacity: 1 }}
                                exit={{ height: 0, opacity: 0 }}
                                transition={{ duration: 0.3 }}
                              >
                                <DateTimePicker
                                  label="Send time"
                                  minDateTime={new Date()}
                                  value={values.sendAt ? values.sendAt : new Date()}
                                  onChange={(newValue) => setFieldValue('sendAt', newValue)}
                                />
                              </motion.div>
                            )}
                          </AnimatePresence>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </Flex>
                </Flex>
              </>
            )
          }}
        </Formik>
      )}
    </Flex>
  )
}

export default Posts
