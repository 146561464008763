import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { colors } from '../ui/helpers'
import { H3, LineClamp, Text } from '../ui/Typography'
import { Flex, Box } from '../ui/Layout'
import Image from '../ui/Image'
import Button from '../ui/Button'
import ModalContainer from '../ui/ModalContainer'
import api from '../api'
import { BigDarkSpinner } from '../ui/Spinner'
import { currencySymbols } from '../ui/formatters'
import Icon from '../ui/Icon'

const ProductDisplay = styled(Flex)`
  margin-bottom: 8px;
  cursor: pointer;
  border-radius: 6px;
  ${(props) => (props.selected ? `background-color: ${colors.weakGreen};` : '')}
  &:hover {
    background-color: rgba(235, 242, 250, 1);
  }
`

const ProductPicker = ({ saveFunction, isOpen, setIsOpen, selectedProducts = [], products = [] }) => {
  const [localSelectedProducts, setLocalSelectedProducts] = useState(selectedProducts)

  // Reset local state when modal opens with new selectedProducts
  useEffect(() => {
    setLocalSelectedProducts(selectedProducts)
  }, [selectedProducts])

  return (
    <ModalContainer noClose p="0px" width="550px" autoHeight isOpen={isOpen} setIsOpen={setIsOpen}>
      <Flex flexDirection="column">
        <Box px="24px" py="16px" borderBottom="1px solid #E4ECF7">
          <H3 fontWeight="600" mb="0px">
            Select Products
          </H3>
        </Box>
        <Flex flexDirection="column" p="24px" height="400px" overflowY="scroll">
          {products.map((product) => (
            <ProductDisplay
              onClick={() => {
                if (localSelectedProducts.some((p) => p.id === product.id)) {
                  setLocalSelectedProducts(localSelectedProducts.filter((p) => p.id !== product.id))
                } else {
                  setLocalSelectedProducts([...localSelectedProducts, product])
                }
              }}
              key={product.id}
              selected={localSelectedProducts.some((p) => p.id === product.id)}
              p="8px"
              alignItems="center"
            >
              <Image
                src={product.imageUrl}
                width="48px"
                height="48px"
                minWidth="48px"
                minHeight="48px"
                objectFit="cover"
                borderRadius="4px"
                mr="12px"
              />
              <Flex flexDirection="column">
                <LineClamp lines={1} light fontWeight="600" fontSize="13px" lineHeight="20px">
                  {product.name}
                </LineClamp>
              </Flex>
              {localSelectedProducts.some((p) => p.id === product.id) ? (
                <Icon icon="tick-grey" width="16px" height="16px" />
              ) : null}
            </ProductDisplay>
          ))}
        </Flex>
      </Flex>
      <Flex px="24px" py="8px" borderTop="1px solid #E4ECF7">
        <Button
          zIndex={1}
          variant="green"
          label="Add"
          width="100%"
          onClick={() => {
            saveFunction(localSelectedProducts)
            setIsOpen(false)
          }}
        />
      </Flex>
    </ModalContainer>
  )
}

export default ProductPicker
