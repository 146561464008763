'use client'

import styled from 'styled-components'
import hexToRgba from 'hex-to-rgba'
import { useState } from 'react'
import { CommentContainer, Header, PageContainer, PagePageContainer, Title } from './ChatIndex'
import ChatFooter from './ChatFooter'
import { Flex } from '../ui/Layout'
import api from '../api'

const StyledTextArea = styled.textarea`
  border: none;
  width: 100%;
  max-height: 120px;
  min-height: 40px;
  height: 40px;
  padding: 8px 16px;
  border-radius: 16px;
  font-size: 16px;
  outline: none;
  resize: none;
  background-color: transparent;
  font-family: ${(props) => props.primaryFontFamily};
  color: ${(props) => props.primaryFontColor};
  overflow-y: hidden;
  line-height: 24px;
  display: block;

  &::placeholder {
    color: ${(props) => props.secondaryFontColor};
  }
`

export default function NewThread({ config, setNewThread, refresh }) {
  const [inputValue, setInputValue] = useState('')
  const [imageUrls, setImageUrls] = useState([])

  const handleTextAreaChange = (e) => {
    setInputValue(e)
  }

  const createThread = async () => {
    await api.post(`/chat/create`, {
      comment: inputValue,
      organisationId: config.organisationId,
      customerId: config.customerId,
      imageUrls,
      threadId: null,
      isCreator: true
    })
    setNewThread(false)
    refresh()
  }

  return (
    <>
      <PagePageContainer>
        <PageContainer primaryFontColor={config.primaryFontColor} height="100%">
          <Header backgroundColor={config.backgroundColor} primaryFontColor={config.primaryFontColor}>
            <Flex px="24px" alignItems="center" width="auto" cursor="pointer" onClick={() => setNewThread(false)}>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15 8C15 7.72386 14.7761 7.5 14.5 7.5L2.70711 7.5L5.85355 4.35355C6.04882 4.15829 6.04882 3.84171 5.85355 3.64645C5.65829 3.45118 5.34171 3.45118 5.14645 3.64645L1.14645 7.64645C0.951184 7.84171 0.951184 8.15829 1.14645 8.35355L5.14645 12.3536C5.34171 12.5488 5.65829 12.5488 5.85355 12.3536C6.04882 12.1583 6.04882 11.8417 5.85355 11.6464L2.70711 8.5H14.5C14.7761 8.5 15 8.27614 15 8Z"
                  fill="black"
                />
              </svg>

              <Title ml="16px">Start a thread</Title>
            </Flex>
          </Header>
          <CommentContainer />
          <ChatFooter
            config={config}
            inputValue={inputValue}
            handleTextAreaChange={handleTextAreaChange}
            onSend={createThread}
            isNewThreadPage
            placeholder="Start a thread"
            submit={createThread}
            imageUrls={imageUrls}
            setImageUrls={setImageUrls}
          />
        </PageContainer>
      </PagePageContainer>
    </>
  )
}
