'use client'

import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Box, Flex } from '../ui/Layout.js'
import { H3, Text } from '../ui/Typography.js'
import { ShimmerWrapper } from './LatestCarousel.js'
import HomepageCarousel from '../ui/HomepageCarousel.js'
import { useAuth } from '../Auth.js'
import { LineClamp } from '../ui/Typography.js'
import Image from '../ui/Image.js'
import api from '../api.js'
import { currencySymbols } from '../ui/formatters.js'
import { CONSISTENT_BOTTOM_MARGIN } from './HomepageSectionsContainer.js'
import { numberWithCommas } from '../ui/helpers.js'

const ProductCard = styled(Flex)`
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  cursor: pointer;
`

const ProductImage = styled(Image)`
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 8px;
  overflow: hidden;
`

const LoadingCard = () => {
  return <ShimmerWrapper paddingTop="100%" />
}

const ProductCardComponent = ({ product }) => {
  return (
    <a href={product.link} rel="noopener noreferrer nofollow" target="_blank">
      <ProductCard>
        <Box overflow="hidden" borderRadius="8px">
          <ProductImage src={product.imageUrl} alt={product.name} />
        </Box>
        <Flex flexDirection="column">
          <LineClamp lines={2} fontSize="14px" fontWeight="500" mb="8px" lineHeight="136%">
            {product.name}
          </LineClamp>
          {product?.price && product?.price > 0 ? (
            <Text light fontSize="14px" lineHeight="100%">
              {currencySymbols[product.currency]}
              {numberWithCommas(product.price)}
            </Text>
          ) : null}
        </Flex>
      </ProductCard>
    </a>
  )
}

const ProductCarousel = ({ title, buttonLabel, productIds }) => {
  const { user } = useAuth()
  const [products, setProducts] = useState([
    { type: 'LOADING' },
    { type: 'LOADING' },
    { type: 'LOADING' },
    { type: 'LOADING' },
    { type: 'LOADING' }
  ])

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const res = await api.post(`/products/getByIds`, {
          productIds: productIds || []
        })
        setProducts(res.data.payload)
      } catch (error) {
        console.error('Error fetching products:', error)
        setProducts([])
      }
    }
    fetchProducts()
  }, [productIds])

  if (products?.length === 0) return null
  if (productIds?.length === 0) return null

  return (
    <Flex flexDirection="column" mb={CONSISTENT_BOTTOM_MARGIN}>
      <H3 className="primary" fontSize="24px" fontWeight="700" lineHeight="136%" mb="24px" px="16px">
        {title}
      </H3>
      <HomepageCarousel
        slideWidth={280}
        spacing={16}
        cards={products?.map((product) => {
          if (product.type === 'LOADING') {
            return <LoadingCard key={Math.random()} />
          }
          return <ProductCardComponent key={product.id + Math.random()} product={product} />
        })}
        pl="24px"
        plm="16px"
      />
    </Flex>
  )
}

export default ProductCarousel
