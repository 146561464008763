'use client'

import React, { useState, useRef, useEffect, useMemo } from 'react'
import hexToRgba from 'hex-to-rgba'
import styled, { createGlobalStyle } from 'styled-components'
import Comment from './Comment'
import { Box, Flex } from '../ui/Layout'
import api from '../api'
import { useTheme } from '../ThemeProvider'
import ChatFooter from './ChatFooter'
import { BackArrow, CommentContainer, Header, PageContainer, PagePageContainer, Title } from './ChatIndex'
import { Text } from '../ui/Typography'

// Move CommentWithReplies outside of the Thread component and memoize it
const MemoizedCommentWithReplies = React.memo(
  ({
    comment,
    level = 0,
    previousCommentDate,
    user,
    reactions,
    config,
    refreshChat,
    setReplyingTo,
    addReactionsBelow,
    onDelete
  }) => (
    <Flex flexDirection="column" pl={level === 1 ? '48px' : '0'}>
      <Comment
        key={comment.id}
        user={user}
        comment={comment}
        reactions={reactions}
        config={config}
        refresh={refreshChat}
        replyOnClick={() => setReplyingTo(comment)}
        previousCommentDate={previousCommentDate}
        addReactionsBelow={addReactionsBelow}
        onDelete={onDelete}
      />
      {comment.replies?.map((reply, index) => (
        <MemoizedCommentWithReplies
          key={reply.id}
          comment={reply}
          level={level + 1}
          previousCommentDate={index > 0 ? comment.replies[index - 1].createdAt : comment.createdAt}
          user={user}
          reactions={reactions}
          config={config}
          refreshChat={refreshChat}
          setReplyingTo={setReplyingTo}
          addReactionsBelow={addReactionsBelow}
          onDelete={onDelete}
        />
      ))}
    </Flex>
  )
)

export default function Thread({ threadId, threadComment, config, refresh, setThreadId, setThreadComment }) {
  const [comments, setComments] = useState([])
  const [reactions, setReactions] = useState([])
  const [replyingTo, setReplyingTo] = useState(null)
  const [inputValue, setInputValue] = useState('')
  const [imageUrls, setImageUrls] = useState([])
  const [footerHeight, setFooterHeight] = useState(0)
  const footerRef = useRef(null)

  // Add effect to update footer height when it changes
  useEffect(() => {
    const updateFooterHeight = () => {
      if (footerRef.current) {
        setFooterHeight(footerRef.current.offsetHeight)
      }
    }

    // Initial measurement
    updateFooterHeight()

    // Create ResizeObserver to watch for height changes
    const resizeObserver = new ResizeObserver(updateFooterHeight)
    if (footerRef.current) {
      resizeObserver.observe(footerRef.current)
    }

    return () => resizeObserver.disconnect()
  }, [])

  const refreshChat = async () => {
    const response = await api.get(`/chat/get-thread?threadId=${threadId}&organisationId=${config.organisationId}`)
    setComments(response.data.payload.comments || [])
    setReactions(response.data.payload.reactions || [])
  }

  useEffect(() => {
    refreshChat()
    setInputValue('')
    setImageUrls([])
    setReplyingTo(null)
  }, [threadId])

  const handleTextAreaChange = (e) => {
    setInputValue(e)
  }

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth'
    })

    const commentContainer = document.querySelector('[data-thread-comment-container]')
    if (commentContainer) {
      commentContainer.scrollTo({
        top: commentContainer.scrollHeight,
        behavior: 'smooth'
      })
    }
  }

  const sendReply = async () => {
    await api.post(`/chat/create`, {
      comment: inputValue,
      threadId: threadId,
      parentId: replyingTo ? replyingTo.id : threadComment.id,
      organisationId: config.organisationId,
      isCreator: true,
      imageUrls
    })
    setInputValue('')
    setReplyingTo(null)
    await refreshChat()
    // Scroll to bottom after sending reply
    if (!replyingTo) {
      setTimeout(scrollToBottom, 100) // Small delay to ensure content is rendered
    }
  }

  // New function to organize comments into a hierarchical structure
  const organizeComments = (comments) => {
    const commentMap = new Map()
    const topLevelComments = []

    // First pass: create a map of all comments
    comments.forEach((comment) => {
      comment.replies = []
      commentMap.set(comment.id, comment)
    })

    // Second pass: organize into parent-child relationships
    comments.forEach((comment) => {
      if (comment.parentId === threadComment.id) {
        topLevelComments.push(comment)
      } else {
        const parentComment = commentMap.get(comment.parentId)
        if (parentComment) {
          parentComment.replies.push(comment)
        }
      }
    })

    // Sort top-level comments and their replies by creation date
    const sortByDate = (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    topLevelComments.sort(sortByDate)
    topLevelComments.forEach((comment) => {
      comment.replies.sort(sortByDate)
    })

    return topLevelComments
  }

  const deleteComment = async (commentId) => {
    await api.post(`/chat/admin-delete-comment`, {
      commentId: commentId
    })
    refreshChat()
  }

  const organizedComments = useMemo(() => organizeComments(comments), [comments, comments.length])

  return (
    <>
      <PagePageContainer>
        <PageContainer primaryFontColor={config.primaryFontColor}>
          {setThreadId ? (
            <Header backgroundColor={config.backgroundColor} primaryFontColor={config.primaryFontColor}>
              <Flex
                px="24px"
                alignItems="center"
                width="auto"
                cursor="pointer"
                onClick={() => {
                  setThreadId(null)
                  setThreadComment(null)
                }}
              >
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15 8C15 7.72386 14.7761 7.5 14.5 7.5L2.70711 7.5L5.85355 4.35355C6.04882 4.15829 6.04882 3.84171 5.85355 3.64645C5.65829 3.45118 5.34171 3.45118 5.14645 3.64645L1.14645 7.64645C0.951184 7.84171 0.951184 8.15829 1.14645 8.35355L5.14645 12.3536C5.34171 12.5488 5.65829 12.5488 5.85355 12.3536C6.04882 12.1583 6.04882 11.8417 5.85355 11.6464L2.70711 8.5H14.5C14.7761 8.5 15 8.27614 15 8Z"
                    fill="black"
                  />
                </svg>

                <Title ml="16px">Replies</Title>
              </Flex>
            </Header>
          ) : null}
          <CommentContainer data-thread-comment-container noScrollBar isActiveSubcriber>
            <Flex
              maxWidth="600px"
              flexDirection="column"
              px="24px"
              pxm="16px"
              pt="24px"
              // Add dynamic bottom padding based on footer height
              pb={`${footerHeight + 16}px`}
            >
              <Comment
                key={threadComment.id}
                comment={threadComment}
                reactions={reactions}
                config={config}
                refresh={() => {
                  refreshChat()
                  refresh()
                }}
                addReactionsBelow={true}
              />

              <Flex borderBottom={`1px solid ${hexToRgba(config.primaryFontColor, 0.1)}`} mb="8px" />
              <Text fontSize="13px" lineHeight="normal" mb="24px">
                Replies
              </Text>
              {organizedComments.map((comment, index) => (
                <MemoizedCommentWithReplies
                  key={comment.id}
                  comment={comment}
                  previousCommentDate={index > 0 ? comments[index - 1].createdAt : threadComment.createdAt}
                  reactions={reactions}
                  config={config}
                  refreshChat={() => {
                    refreshChat()
                    refresh()
                  }}
                  setReplyingTo={setReplyingTo}
                  addReactionsBelow={index < organizedComments.length - 2 || index < 1}
                  onDelete={deleteComment}
                />
              ))}
            </Flex>
          </CommentContainer>
          <ChatFooter
            config={config}
            threadId={threadId}
            inputValue={inputValue}
            handleTextAreaChange={handleTextAreaChange}
            placeholder="Type your reply"
            submit={sendReply}
            showNewThread={false}
            imageUrls={imageUrls}
            setImageUrls={setImageUrls}
            replyingTo={replyingTo}
            setReplyingTo={setReplyingTo}
          />
        </PageContainer>
      </PagePageContainer>
    </>
  )
}
