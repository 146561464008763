import React from 'react'
import styled from 'styled-components'
import { colors } from './helpers'
import { Text } from './Typography'

import {
  space,
  width,
  color,
  flex,
  order,
  fontSize,
  alignSelf,
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
  borders,
  minWidth,
  minHeight,
  borderRadius,
  size,
  height,
  maxWidth,
  textAlign,
  boxShadow,
  borderColor,
  position,
  display,
  top,
  bottom,
  left,
  right,
  zIndex
} from 'styled-system'

const Spinner = styled.div`
  display: inline-block;
  width: 25px;
  height: 25px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out 0.01s infinite;
  // -webkit-animation: spin 1s ease-in-out 0.01s infinite;
  // -webkit-animation: spin 1s ease-in-out 0.01s infinite;
  -webkit-animation-name: spin;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  ${position};
  ${width};
  ${height};
  ${space};
  ${fontSize};
  ${color};
  ${flex};
  ${order};
  ${alignSelf};
  ${borders};
  ${borderColor};
  ${minWidth};
  ${minHeight};
  ${borderRadius};
  ${size};
  ${height};
  ${textAlign};
  ${maxWidth};
  ${boxShadow};
  ${display};
  ${top};
  ${bottom};
  ${left};
  ${right};
  ${zIndex};
  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-moz-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
`

export const GreenSpinner = styled(Spinner)`
  border: 3px solid rgba(0, 125, 91, 0.3);
  border-top-color: ${colors.green};
`

export const BigSpinner = styled(Spinner)``
export const BigDarkSpinner = styled(Spinner)`
  width: 30px;
  height: 30px;
  border: 3px solid rgba(76, 111, 255, 0.3);
  border-top-color: #4c6fff;
`

const DarkBlockSpinnerComp = styled.svg`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  margin-left: -3px;
  box-sizing: border-box;
  animation: spin 1.5s ease-in-out infinite;
`

export const DarkBlockSpinner = ({ zIndex, color }) => (
  <DarkBlockSpinnerComp
    zIndex={zIndex}
    id="myspinner"
    width="20"
    height="20"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.9067 10.0755H20.033V0.235352H10.5707C10.5707 5.67153 6.18243 10.0755 0.765625 10.0755V19.5716H10.5707V29.4118H20.033C20.033 23.9756 24.4899 19.5716 29.9067 19.5716V10.0755Z"
      fill={color || '#425466'}
    />
  </DarkBlockSpinnerComp>
)

export const SmallSpinner = styled(Spinner)`
  width: 25px;
  height: 25px;
`

export const SmallSpinnerColoredComp = styled(SmallSpinner)`
  border: 3px solid ${(props) => props.lightColor};
  border-radius: 50%;
  border-top-color: ${(props) => props.color};
`

export const SmallSpinnerColored = ({ color, lightColor }) => {
  return <SmallSpinnerColoredComp color={color} lightColor={lightColor} />
}

export default Spinner
