import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { colors, numberWithCommas } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle, Label, LineClamp } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import formikFormWrapper from '../formikFormWrapper.js'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import baseUrl from '../baseUrl.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import { Td, Tr, HTr, HeaderText, Table, Th } from '../ui/Table.js'
import { currencySymbols } from '../ui/formatters.js'
import ModalContainer from '../ui/ModalContainer.js'
import { useTheme } from '../ThemeProvider.js'
import Icon from '../ui/Icon.js'
import { useSearchParams } from 'react-router-dom'
import Button from '../ui/Button.js'
import ProductModal from './ProductModal.js'
import OptionsPopover from './OptionsPopover.js'

const TabText = styled(Text)`
  ${(props) => !props.isActive && `color: ${colors.textLight};`}
  padding-bottom: 20px;
  ${(props) => props.isActive && `border-bottom: 1px solid ${colors.green};`}
  font-weight: 600;
  margin-right: 30px;
  cursor: pointer;
`

const Tab = ({ onClick, title, isActive }) => {
  return (
    <TabText onClick={onClick} isActive={isActive} light={isActive ? undefined : true}>
      {title}
    </TabText>
  )
}

const Products = (props) => {
  let { user, setUser } = useAuth()
  const [search, setSearch] = useSearchParams()
  const [page, setPage] = useState(1)
  const [productModalOpen, setProductModalOpen] = useState(false)

  const [selectedProduct, setSelectedProduct] = useState(null)
  const [productModalStep, setProductModalStep] = useState(1)

  return (
    <Flex flexDirection="column" alignItems="center">
      <Flex position="relative" flexDirection="column" width="100%" pt="40px" pl="40px">
        <Flex>
          <H1 width="100%">Products</H1>
          <Button
            position="absolute"
            renderLeftIcon={() => <Icon icon="plus-white" width="12px" height="12px" />}
            top="16px"
            right="16px"
            variant="green"
            label="Add product"
            onClick={() => {
              setSelectedProduct(null)
              setProductModalStep(1)
              setProductModalOpen(true)
            }}
          />
        </Flex>
      </Flex>
      <Flex bg={colors.backgroundGrey} pt="30px" flexDirection="column" alignItems="center">
        <ProductsTable
          setProductModalOpen={setProductModalOpen}
          setSelectedProduct={setSelectedProduct}
          productModalOpen={productModalOpen}
          selectedProduct={selectedProduct}
          setProductModalStep={setProductModalStep}
          productModalStep={productModalStep}
        />
      </Flex>
    </Flex>
  )
}

const PAGESIZE = 50

const ProductsTable = ({
  setProductModalOpen,
  productModalOpen,
  setSelectedProduct,
  selectedProduct,
  setProductModalStep,
  productModalStep
}) => {
  let { user, setUser } = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [allProducts, setAllProducts] = useState([])
  const [currentPage, setCurrentPage] = useState(1)

  let { theme } = useTheme()

  const fetchProducts = async () => {
    setIsLoading(true)
    try {
      const res = await api.get(`/products/get?organisationId=${user.organisationId}`)
      setAllProducts(res.data.payload?.sort((a, b) => dayjs(b.created_at).diff(dayjs(a.created_at))))
      setIsLoading(false)
    } catch (e) {
      console.log(e)
      setIsLoading(false)
    }
  }

  console.log({ allProducts })

  useEffect(() => {
    fetchProducts()
  }, [])

  const totalPages = Math.ceil(allProducts?.length / PAGESIZE)
  const paginatedProducts = allProducts?.slice((currentPage - 1) * PAGESIZE, currentPage * PAGESIZE)

  const renderPageNumbers = () => {
    const pageNumbers = []
    const maxVisiblePages = 5
    const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2))
    const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1)

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Text
          key={i}
          onClick={() => setCurrentPage(i)}
          light={i !== currentPage}
          fontWeight={i === currentPage ? '600' : '500'}
          px="8px"
          cursor="pointer"
        >
          {i}
        </Text>
      )
    }
    return pageNumbers
  }

  return (
    <Flex px="40px">
      {isLoading ? (
        <Flex height="300px" alignItems="center" justifyContent="center">
          <BigDarkSpinner />
        </Flex>
      ) : (
        <Flex flexDirection="column" pb="100px">
          <Container p="0px" pt="16px">
            <Flex justifyContent="space-between" px="24px" mb="16px">
              <SmallTitle>All Products</SmallTitle>
            </Flex>
            <Table>
              <HTr>
                <Th width="100px !important">
                  <HeaderText left></HeaderText>
                </Th>
                <Th minWidth="300px">
                  <HeaderText left>NAME</HeaderText>
                </Th>
                <Th minWidth="100px">
                  <HeaderText left>PRICE</HeaderText>
                </Th>
                <Th minWidth="300px">
                  <HeaderText left>LINK</HeaderText>
                </Th>
                <Th width="130px" minWidth="130px">
                  <HeaderText left>CREATED AT</HeaderText>
                </Th>
                <Th minWidth="50px"></Th>
              </HTr>
              {paginatedProducts &&
                paginatedProducts.map((product) => (
                  <Tr
                    key={product.id}
                    justifyContent="space-between"
                    onClick={() => {
                      setProductModalStep(2)
                      setSelectedProduct(product)
                      setProductModalOpen(true)
                    }}
                  >
                    <Td left>
                      <Image src={product.imageUrl} width="48px" height="48px" m="8px" objectFit="cover" borderRadius="4px" />
                    </Td>
                    <Td left minWidth="300px">
                      <LineClamp light fontWeight="600" lines={1} wordBreak="break-word">
                        {product.name}
                      </LineClamp>
                    </Td>
                    <Td left minWidth="100px">
                      <Text light>
                        {product.price ? `${currencySymbols[product.currency]}${numberWithCommas(product.price)}` : '-'}
                      </Text>
                    </Td>
                    <Td left>
                      <LineClamp light lines={1} wordBreak="break-word">
                        {product.link}
                      </LineClamp>
                    </Td>
                    <Td left light minWidth="130px" width="130px">
                      <Text light>{dayjs(product.created_at).format('MMM DD, YYYY')}</Text>
                    </Td>
                    <Td left minWidth="50px">
                      <OptionsPopover id={product.id} refresh={fetchProducts} />
                    </Td>
                  </Tr>
                ))}
            </Table>

            {!allProducts?.length ? (
              <Flex flexDirection="column" alignItems="center" justifyContent="center" height="100%" pt="80px">
                <svg width="208" height="115" viewBox="0 0 208 115" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect y="21.1807" width="84" height="83" rx="8" transform="rotate(-7.08688 0 21.1807)" fill="#FDF4E8" />
                  <rect
                    x="60.7319"
                    y="7.90576"
                    width="84"
                    height="83"
                    rx="8"
                    transform="rotate(11.9131 60.7319 7.90576)"
                    fill="#5BB3F9"
                  />
                  <rect x="135.563" width="84" height="83" rx="8" transform="rotate(30.9131 135.563 0)" fill="#F4ACC4" />
                </svg>
                <Text light fontSize="16px" mt="24px" mb="8px" fontWeight="500" textAlign="center">
                  No products yet
                </Text>
                <Text light fontSize="14px" textAlign="center" maxWidth="400px" mb="24px">
                  Create products on Clubb to link to other storefronts, affiliates or recommendations.
                </Text>
                <Button
                  mb="60px"
                  renderLeftIcon={() => <Icon icon="plus-white" width="12px" height="12px" />}
                  variant="green"
                  label="Create product"
                  onClick={() => {
                    setSelectedProduct(null)
                    setProductModalStep(1)
                    setProductModalOpen(true)
                  }}
                />
              </Flex>
            ) : null}
            <Flex>
              <Flex mt="16px" mb="16px" justifyContent="space-between">
                <Text fontWeight="300" width="375px" lightLight px="16px" italic>
                  Showing {!allProducts?.length ? 0 : (currentPage - 1) * PAGESIZE + 1} -{' '}
                  {Math.min(currentPage * PAGESIZE, allProducts?.length || 0)} of {allProducts?.length || 0}
                </Text>
                <Flex cursor="pointer" justifyContent="center" alignItems="center">
                  <Text
                    onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)}
                    light={currentPage > 1}
                    lightLight={currentPage === 1}
                    fontWeight="500"
                    px="8px"
                    cursor={currentPage > 1 ? 'pointer' : 'default'}
                  >
                    {'<'}
                  </Text>
                  {renderPageNumbers()}
                  <Text
                    onClick={() => currentPage < totalPages && setCurrentPage(currentPage + 1)}
                    light={currentPage < totalPages}
                    lightLight={currentPage === totalPages}
                    fontWeight="500"
                    px="8px"
                    cursor={currentPage < totalPages ? 'pointer' : 'default'}
                  >
                    {'>'}
                  </Text>
                </Flex>
                <Flex width="375px" justifyContent="flex-end" pr="32px"></Flex>
              </Flex>
            </Flex>
          </Container>
          <ProductModal
            isOpen={productModalOpen}
            setIsOpen={setProductModalOpen}
            refresh={fetchProducts}
            inputProduct={selectedProduct}
            step={productModalStep}
            setStep={setProductModalStep}
          />
        </Flex>
      )}
    </Flex>
  )
}

export default Products
