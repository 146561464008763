import React from 'react'
import styled from 'styled-components'
import { Flex, Box } from '../ui/Layout.js'
import Icon from '../ui/Icon.js'
import { useNavigate } from 'react-router-dom'

const ContainerWithHover = styled(Box)`
  min-width: 0px;
  position: relative;
  cursor: ${(props) => (props.anySelected ? 'pointer' : 'default')};
  &&:hover {
    .hover {
      visibility: visible;
    }
  }
  .hover {
    visibility: hidden;
  }
`

const SelectableCard = ({ children, isSelected, onSelect, anySelected, path }) => {
  const navigate = useNavigate()

  const handleClick = (e) => {
    if (anySelected) {
      e.preventDefault()
      e.stopPropagation()
      onSelect()
    } else if (path) {
      navigate(path)
    }
  }

  return (
    <ContainerWithHover anySelected={anySelected}>
      <Box
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          onSelect()
        }}
      >
        {isSelected ? (
          <Flex
            cursor="pointer"
            position="absolute"
            left="0px"
            top="0px"
            width="52px"
            height="52px"
            zIndex={2}
            justifyContent="center"
            alignItems="center"
          >
            <Icon icon="checkbox-ticked-green" width={20} height={20} />
          </Flex>
        ) : (
          <Flex
            className="hover checkbox-hover"
            cursor="pointer"
            position="absolute"
            left="0px"
            top="0px"
            width="52px"
            height="52px"
            zIndex={2}
            justifyContent="center"
            alignItems="center"
          >
            <Icon icon="checkbox-unticked-white" width={20} height={20} />
          </Flex>
        )}
      </Box>
      <div onClick={handleClick} style={{ height: '100%' }}>
        {children}
      </div>
    </ContainerWithHover>
  )
}

export default SelectableCard
