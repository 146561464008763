import React from 'react'
import styled from 'styled-components'
import { ShimmerWrapper } from '../LatestCarousel'
import FeatureImage from './FeatureImage'
import HomepageCarousel from '../../ui/HomepageCarousel'
import { CONSISTENT_BOTTOM_MARGIN } from '../HomepageSectionsContainer'
import Button from '../../ui/Button'
import { Box, Flex } from '../../ui/Layout'
import { H3, Text } from '../../ui/Typography'

const LoadingCard = () => {
  return <ShimmerWrapper paddingTop="100%" />
}

const FeatureCard = styled(Flex)`
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  width: 280px;
  height: 292px;
  min-width: 280px;
  min-height: 292px;
`

const FeatureCardComponent = ({ feature, config }) => {
  return (
    <FeatureCard>
      <FeatureImage type={feature.type} title={feature.title} subtitle={feature.subtitle} config={config} />
    </FeatureCard>
  )
}

const FeaturesCarousel = ({ title, subtitle, buttonLabel, features, config, hasUser, isActiveSubscriber }) => {
  if (isActiveSubscriber) {
    return null
  }
  return (
    <Flex flexDirection="column" mb={CONSISTENT_BOTTOM_MARGIN}>
      <Flex flexDirection="column" px="16px" mb="24px">
        <H3 className="primary" fontSize="36px" fontWeight="700" lineHeight="130%" mb="16px">
          {title}
        </H3>
        <Text className="secondary" fontSize="18px" fontWeight="400" lineHeight="130%" mb="16px">
          {subtitle}
        </Text>
        <Box>
          <Button label={config.homepageCta} className="primary" />
        </Box>
      </Flex>
      <HomepageCarousel
        slideWidth={280}
        spacing={16}
        cards={
          features &&
          features.length > 0 &&
          features
            .filter((feature) => feature.isActive)
            .map((feature) => {
              return <FeatureCardComponent key={feature.id} feature={feature} config={config} />
            })
        }
        pl="16px"
        plm="16px"
      />
    </Flex>
  )
}

export default FeaturesCarousel
