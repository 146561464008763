import React, { useEffect, useState } from 'react'
import api from './api.js'
import { useAuth } from './Auth.js'

let ChatContext = React.createContext()

export const ChatProvider = ({ children }) => {
  const [threads, setThreads] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)
  let { user } = useAuth()

  const refresh = async () => {
    try {
      const threadsRes = await api.get('/chat/admin-get-threads')
      if (threadsRes.data.success) {
        setThreads(threadsRes.data.payload)
      }
      setIsLoaded(true)
    } catch (error) {
      console.error('Error refreshing chat data:', error)
    }
  }

  useEffect(() => {
    if (user?.organisationId) {
      refresh()
    }
  }, [user])

  const props = { threads, refresh, isLoaded }
  return <ChatContext.Provider value={props}>{children}</ChatContext.Provider>
}

export const useChat = () => {
  return React.useContext(ChatContext)
}
