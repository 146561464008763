import React from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import hexToRgba from 'hex-to-rgba'
import api from '../api'
import { Box, Flex } from '../ui/Layout'
import { Text } from '../ui/Typography'
import PopoverModal from '../Components/ui/PopoverModal'

const ReactionCount = styled(Flex)`
  width: auto;
  height: 24px;
  background: ${(props) => props.backgroundColor};
  border-radius: 16px;
  border: 1px solid ${(props) => hexToRgba(props.primaryFontColor, 0.1)};
  padding: 2px 6px;
  display: flex;
  align-items: center;
  gap: 4px;
  user-select: none;
  cursor: pointer;
  ${(props) =>
    props.isUserReaction &&
    `
    border: 2px solid ${props.primaryButtonColor};
    background: ${props.primaryButtonColor};
    padding: 0px 4px;
  `}
`

const EmojiGrid = styled(Flex)`
  display: flex;
  flex-direction: column;
  padding: 8px;
  max-height: 300px;
  overflow-y: auto;
`

const CategorySection = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const EmojiContainer = styled(Flex)`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 8px;
`

const EmojiButton = styled.button`
  background: none;
  border: none;
  width: 32px;
  height: 32px;
  font-size: 24px;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
  padding: 0px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`

const reactions = {
  FREQUENTLY_USED: [
    { emoji: '😂', label: 'joy' },
    { emoji: '❤️', label: 'heart' },
    { emoji: '😍', label: 'heart_eyes' },
    { emoji: '🤣', label: 'rofl' },
    { emoji: '🙏', label: 'pray' },
    { emoji: '🥰', label: 'smiling_face_with_3_hearts' },
    { emoji: '🤔', label: 'thinking' },
    { emoji: '😄', label: 'grin' },
    { emoji: '🎉', label: 'tada' },
    { emoji: '👍', label: 'thumbsup' },
    { emoji: '🔥', label: 'fire' },
    { emoji: '🤩', label: 'star_struck' },
    { emoji: '👏', label: 'clap' },
    { emoji: '😋', label: 'yum' },
    { emoji: '🥳', label: 'partying_face' },
    { emoji: '👀', label: 'eyes' },
    { emoji: '🥵', label: 'hot_face' },
    { emoji: '🤤', label: 'drooling_face' },
    { emoji: '🤞', label: 'crossed_fingers' },
    { emoji: '🙌', label: 'raised_hands' },
    { emoji: '👌', label: 'ok_hand' }
  ],
  SMILEYS_AND_PEOPLE: [
    { emoji: '🥺', label: 'pleading' },
    { emoji: '😩', label: 'weary' },
    { emoji: '😭', label: 'crying' },
    { emoji: '😳', label: 'flushed' },
    { emoji: '🥴', label: 'woozy' },
    { emoji: '😫', label: 'tired' },
    { emoji: '😵', label: 'dizzy_face' },
    { emoji: '🙄', label: 'rolling_eyes' },
    { emoji: '😶', label: 'no_mouth' },
    { emoji: '😈', label: 'smiling_imp' },
    { emoji: '🤦', label: 'facepalm' },
    { emoji: '🙈', label: 'see_no_evil' },
    { emoji: '😏', label: 'smirk' },
    { emoji: '😬', label: 'grimacing' },
    { emoji: '🤨', label: 'raised_eyebrow' },
    { emoji: '😕', label: 'confused' },
    { emoji: '🤭', label: 'hand_over_mouth' },
    { emoji: '😰', label: 'anxious' },
    { emoji: '🤫', label: 'shushing' },
    { emoji: '😑', label: 'expressionless' },
    { emoji: '🤐', label: 'zipper_mouth' }
  ],
  FOOD_AND_DRINK: [
    { emoji: '☕', label: 'coffee' },
    { emoji: '🍕', label: 'pizza' },
    { emoji: '🍷', label: 'wine' },
    { emoji: '🍺', label: 'beer' },
    { emoji: '🍣', label: 'sushi' },
    { emoji: '🌮', label: 'taco' },
    { emoji: '🍜', label: 'ramen' },
    { emoji: '🍪', label: 'cookie' },
    { emoji: '🍝', label: 'spaghetti' },
    { emoji: '🥑', label: 'avocado' },
    { emoji: '🍫', label: 'chocolate' },
    { emoji: '🧋', label: 'bubble_tea' },
    { emoji: '🍙', label: 'rice_ball' },
    { emoji: '🥐', label: 'croissant' },
    { emoji: '🍩', label: 'doughnut' },
    { emoji: '🥤', label: 'cup_with_straw' },
    { emoji: '🍿', label: 'popcorn' },
    { emoji: '🥨', label: 'pretzel' },
    { emoji: '🥪', label: 'sandwich' },
    { emoji: '🧇', label: 'waffle' },
    { emoji: '🥞', label: 'pancakes' }
  ],
  ANIMALS_AND_NATURE: [
    { emoji: '🦋', label: 'butterfly' },
    { emoji: '🐶', label: 'dog' },
    { emoji: '🐱', label: 'cat' },
    { emoji: '🦊', label: 'fox' },
    { emoji: '🐼', label: 'panda' },
    { emoji: '🐨', label: 'koala' },
    { emoji: '🦄', label: 'unicorn' },
    { emoji: '🐯', label: 'tiger' },
    { emoji: '🦒', label: 'giraffe' },
    { emoji: '🐷', label: 'pig' },
    { emoji: '🦩', label: 'flamingo' },
    { emoji: '🦈', label: 'shark' },
    { emoji: '🐸', label: 'frog' },
    { emoji: '🦆', label: 'duck' },
    { emoji: '🦅', label: 'eagle' },
    { emoji: '🌸', label: 'cherry_blossom' },
    { emoji: '🌺', label: 'hibiscus' },
    { emoji: '🌙', label: 'crescent_moon' },
    { emoji: '🌊', label: 'ocean_wave' },
    { emoji: '🍀', label: 'four_leaf_clover' },
    { emoji: '🦥', label: 'sloth' }
  ],
  ACTIVITY: [
    { emoji: '💃', label: 'dancer' },
    { emoji: '🎮', label: 'gaming' },
    { emoji: '⚽', label: 'soccer' },
    { emoji: '🏃', label: 'running' },
    { emoji: '🎵', label: 'musical_note' },
    { emoji: '🎨', label: 'art' },
    { emoji: '🎬', label: 'clapper' },
    { emoji: '🎯', label: 'dart' },
    { emoji: '🏋️', label: 'weight_lifting' },
    { emoji: '🎤', label: 'microphone' },
    { emoji: '🎸', label: 'guitar' },
    { emoji: '🧘', label: 'meditation' },
    { emoji: '🏀', label: 'basketball' },
    { emoji: '⚾', label: 'baseball' },
    { emoji: '🎳', label: 'bowling' },
    { emoji: '🎪', label: 'circus' },
    { emoji: '🎭', label: 'performing_arts' },
    { emoji: '🎧', label: 'headphone' },
    { emoji: '🎹', label: 'piano' },
    { emoji: '🎲', label: 'game_die' },
    { emoji: '🎨', label: 'artist_palette' }
  ],
  SYMBOLS: [
    { emoji: '💔', label: 'broken_heart' },
    { emoji: '💖', label: 'sparkling_heart' },
    { emoji: '💝', label: 'gift_heart' },
    { emoji: '💓', label: 'beating_heart' },
    { emoji: '💕', label: 'two_hearts' },
    { emoji: '💘', label: 'cupid' },
    { emoji: '💞', label: 'revolving_hearts' },
    { emoji: '💗', label: 'growing_heart' },
    { emoji: '💌', label: 'love_letter' },
    { emoji: '💟', label: 'heart_decoration' },
    { emoji: '⭐', label: 'star' },
    { emoji: '✨', label: 'sparkles' },
    { emoji: '💫', label: 'dizzy' },
    { emoji: '⚡', label: 'zap' },
    { emoji: '🌟', label: 'glowing_star' },
    { emoji: '✅', label: 'check' },
    { emoji: '❌', label: 'x' },
    { emoji: '⭕', label: 'o' },
    { emoji: '❗', label: 'exclamation' },
    { emoji: '❓', label: 'question' },
    { emoji: '💭', label: 'thought_bubble' }
  ]
}

const containerVariants = {
  hidden: { opacity: 0, x: -20 },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      staggerChildren: 0.05,
      duration: 0.2
    }
  }
}

const itemVariants = {
  hidden: { opacity: 0, x: -20 },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.2
    }
  }
}

const useReactions = (commentId, refresh) => {
  const createReaction = async (reaction) => {
    await api.post(`/chat/create-reaction`, {
      reaction: reaction.label,
      commentId: commentId,
      isCreator: true
    })
    refresh()
  }

  const deleteReaction = async () => {
    await api.post(`/chat/delete-reaction`, {
      commentId: commentId,
      isCreator: true
    })
    refresh()
  }

  return { createReaction, deleteReaction }
}

export const AddReaction = ({ commentId, customerId, config, refresh, addReactionsBelow }) => {
  const [showReactions, setShowReactions] = React.useState(false)
  const { createReaction } = useReactions(commentId, refresh)

  const handleCreateReaction = async (reaction) => {
    await createReaction(reaction)
    setShowReactions(false)
  }

  return (
    <Flex onClick={() => setShowReactions(!showReactions)} cursor="pointer" width="auto" alignItems="center" px="2px">
      <svg width="16" height="16" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.7642 12.6908C15.9925 13.0866 15.9922 13.5741 15.7634 13.9696C14.6602 15.8769 12.5949 17.1647 10.2273 17.1647C7.85976 17.1647 5.79449 15.8769 4.6912 13.9696C4.46241 13.5741 4.4621 13.0866 4.6904 12.6908C4.91869 12.295 5.34088 12.0511 5.7978 12.0511H14.6568C15.1138 12.0511 15.536 12.295 15.7642 12.6908Z"
          fill={config.secondaryFontColor}
        />
        <path
          d="M8.94888 8.85507C8.94888 9.91414 8.37652 8.85507 7.67047 8.85507C6.96442 8.85507 6.39206 9.91414 6.39206 8.85507C6.39206 7.796 6.96442 6.93746 7.67047 6.93746C8.37652 6.93746 8.94888 7.796 8.94888 8.85507Z"
          fill={config.secondaryFontColor}
        />
        <path
          d="M14.0625 8.85507C14.0625 9.91414 13.4902 8.85507 12.7841 8.85507C12.0781 8.85507 11.5057 9.91414 11.5057 8.85507C11.5057 7.796 12.0781 6.93746 12.7841 6.93746C13.4902 6.93746 14.0625 7.796 14.0625 8.85507Z"
          fill={config.secondaryFontColor}
        />
        <path
          d="M19.4592 6.74956C20.4894 8.85764 20.7651 11.2553 20.2404 13.5423C19.7157 15.8292 18.4222 17.8668 16.5761 19.3149C14.7299 20.763 12.4429 21.5339 10.0968 21.4989C7.7507 21.4638 5.48768 20.6249 3.68565 19.1222C1.88362 17.6195 0.65167 15.5441 0.195552 13.2426C-0.260565 10.941 0.0867598 8.55259 1.17952 6.47625C2.27228 4.39992 4.04433 2.76135 6.19971 1.83419C8.35509 0.907029 10.7633 0.747414 13.0222 1.382L12.6672 2.64588C10.6976 2.09257 8.59779 2.23174 6.71847 3.04015C4.83914 3.84856 3.29406 5.27727 2.34126 7.08767C1.38845 8.89807 1.08561 10.9806 1.48331 12.9874C1.88101 14.9941 2.95517 16.8037 4.5264 18.1139C6.09763 19.4241 8.07081 20.1556 10.1164 20.1862C12.162 20.2168 14.1562 19.5446 15.7659 18.282C17.3756 17.0194 18.5033 15.2427 18.9609 13.2487C19.4184 11.2547 19.1779 9.16405 18.2797 7.32597L19.4592 6.74956Z"
          fill={config.secondaryFontColor}
        />
        <path d="M15.7857 1H16.7143V7.5H15.7857V1Z" fill={config.secondaryFontColor} />
        <path d="M19.5 3.78571V4.71429H13V3.78571H19.5Z" fill={config.secondaryFontColor} />
      </svg>
      <PopoverModal
        isOpen={showReactions}
        config={config}
        setIsOpen={setShowReactions}
        width="auto"
        right
        bottom={!addReactionsBelow}
        topOveride={'0px'}
        borderRadius="8px"
        boxShadowOverride="0px 4px 8px 0px rgba(152, 152, 152, 0.25)"
      >
        <EmojiGrid>
          {Object.entries(reactions).map(([category, emojis]) => (
            <CategorySection key={category}>
              <Text light pl="4px" fontWeight="500" fontSize="12px" color={hexToRgba(config.secondaryFontColor, 0.6)}>
                {category.replace(/_/g, ' ')}
              </Text>
              <EmojiContainer mb="12px">
                {emojis.map((reaction) => (
                  <EmojiButton key={reaction.label} onClick={() => handleCreateReaction(reaction)} aria-label={reaction.label}>
                    {reaction.emoji}
                  </EmojiButton>
                ))}
              </EmojiContainer>
            </CategorySection>
          ))}
        </EmojiGrid>
      </PopoverModal>
    </Flex>
  )
}

export default function Reactions({
  commentId,
  customerId,
  config,
  refresh,
  reactions: reactionsList = [],
  addReactionsBelow = false
}) {
  const [showReactions, setShowReactions] = React.useState(false)
  const { createReaction, deleteReaction } = useReactions(commentId, refresh)

  // Calculate reaction counts and user reactions
  const reactionCounts = React.useMemo(() => {
    const counts = {}
    const userReactions = new Set()

    reactionsList
      .filter((r) => r.commentId === commentId)
      .forEach((reaction) => {
        counts[reaction.reaction] = (counts[reaction.reaction] || 0) + 1
        if (reaction.isCreator) {
          userReactions.add(reaction.reaction)
        }
      })

    // Sort reactions by count and take top 3
    return Object.entries(counts)
      .sort(([, a], [, b]) => a - b)
      .slice(0, 3)
      .reduce((acc, [reaction, count]) => {
        acc[reaction] = {
          count,
          isUserReaction: userReactions.has(reaction)
        }
        return acc
      }, {})
  }, [reactionsList, commentId])

  // Helper function to find emoji across all categories
  const findEmojiByLabel = (label) => {
    for (const category of Object.values(reactions)) {
      const found = category.find((r) => r.label === label)
      if (found) return found.emoji
    }
    return null
  }

  const handleCreateReaction = async (reaction) => {
    await createReaction(reaction)
    setShowReactions(false)
  }

  const handleDeleteReaction = async () => {
    await deleteReaction()
    setShowReactions(false)
  }

  return (
    <Box position="absolute" display="flex" alignItems="center" top="-10px" right="0">
      <Flex alignItems="center">
        {Object.entries(reactionCounts).map(([reactionType, { count, isUserReaction }]) => {
          const reactionEmoji = findEmojiByLabel(reactionType)
          if (!reactionEmoji) return null

          const onClick = () => {
            if (isUserReaction) {
              handleDeleteReaction()
            } else {
              handleCreateReaction({ label: reactionType })
            }
          }

          return (
            <ReactionCount
              key={reactionType}
              backgroundColor={config.backgroundColor}
              primaryFontColor={config.primaryFontColor}
              primaryButtonColor={config.primaryButtonColor}
              ml="4px"
              isUserReaction={isUserReaction}
              onClick={onClick}
            >
              <Text fontSize="14px" lineHeight="16px" mt="0px">
                {reactionEmoji}
              </Text>
              <Text
                color={isUserReaction ? config.primaryButtonFontColor : config.primaryFontColor}
                whiteSpace="nowrap"
                fontWeight="600"
                lineHeight="16px"
                fontSize="12px"
                ml="2px"
              >
                {count}
              </Text>
            </ReactionCount>
          )
        })}
        <ReactionCount
          backgroundColor={config.backgroundColor}
          primaryFontColor={config.primaryFontColor}
          primaryButtonColor={config.primaryButtonColor}
          ml="4px"
          // onClick={}
        >
          <AddReaction
            commentId={commentId}
            customerId={customerId}
            config={config}
            refresh={refresh}
            addReactionsBelow={addReactionsBelow}
          />
        </ReactionCount>
      </Flex>
    </Box>
  )
}
