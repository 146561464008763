import { colors } from '../ui/helpers'

export const SubscribePromptStyles = `
  .subscribe-prompt {
    border-radius: 8px;
    padding: 20px;
    margin: 20px 0;
    text-align: center;
  }

  .subscribe-prompt__description {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .subscribe-prompt__button {
    display: inline-block;
    background-color: ${colors.green};
    color: white;
    padding: 12px 20px;
    border-radius: 400px;
    text-decoration: none;
    font-weight: 700;
  }

  .subscribe-prompt__paywall-box {
    background-color: #f0f0f0;
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
  }

  .subscribe-prompt__paywall-header {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin: 0 0 15px 0;
    color: #333;
  }

  .subscribe-prompt__paywall-content {
    font-size: 14px;
    line-height: 1.5;
    color: #555;
  }
`

class SubscribePrompt {
  constructor({ data, config, api }) {
    this.api = api
    this.data = {
      description: data.description || 'Subscribe to get access to all content.',
      buttonText: data.buttonText || 'Upgrade to paid',
      buttonUrl: data.buttonUrl || config.subscribeUrl || '#',
      paywallText:
        data.paywallText ||
        "Free readers won't be able to read beyond this point. On email, the content will cut off at this point. On web, a paywall will be put in place. Paid subscribers will be able to view all content beyond this point on email and web."
    }
    this.config = config
    this.CSS = {
      wrapper: 'subscribe-prompt',
      description: 'subscribe-prompt__description',
      button: 'subscribe-prompt__button',
      paywallBox: 'subscribe-prompt__paywall-box',
      paywallHeader: 'subscribe-prompt__paywall-header',
      paywallContent: 'subscribe-prompt__paywall-content'
    }
  }

  static get toolbox() {
    return {
      title: 'Paywall',
      icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM20 18H4V6H20V18ZM6 10H18V12H6V10ZM6 14H14V16H6V14Z" fill="black"/></svg>'
    }
  }

  render() {
    const wrapper = document.createElement('div')
    wrapper.classList.add(this.CSS.wrapper)

    const description = document.createElement('p')
    description.classList.add(this.CSS.description)
    description.contentEditable = true
    description.innerHTML = this.data.description

    const button = document.createElement('a')
    button.classList.add(this.CSS.button)
    button.href = this.data.buttonUrl
    button.target = '_blank'
    button.rel = 'noopener noreferrer'
    button.contentEditable = true
    button.textContent = this.data.buttonText

    const paywallBox = document.createElement('div')
    paywallBox.classList.add(this.CSS.paywallBox)

    const paywallHeader = document.createElement('h3')
    paywallHeader.classList.add(this.CSS.paywallHeader)
    paywallHeader.textContent = 'Subscriber Paywall'

    const paywallContent = document.createElement('div')
    paywallContent.classList.add(this.CSS.paywallContent)
    paywallContent.textContent = this.data.paywallText // Changed from innerHTML to textContent
    // Removed contentEditable = true

    paywallBox.appendChild(paywallHeader)
    paywallBox.appendChild(paywallContent)

    wrapper.appendChild(description)
    wrapper.appendChild(button)
    wrapper.appendChild(paywallBox)

    return wrapper
  }

  save(blockContent) {
    const description = blockContent.querySelector(`.${this.CSS.description}`)
    const button = blockContent.querySelector(`.${this.CSS.button}`)

    return {
      description: description.innerHTML,
      buttonText: button.textContent.trim(),
      buttonUrl: button.href // Save the button URL
    }
  }

  static get isReadOnlySupported() {
    return true
  }

  static get sanitize() {
    return {
      description: {},
      buttonText: false,
      buttonUrl: false // Allow saving of the URL
    }
  }
}

export default SubscribePrompt
