import React, { useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { colors } from '../ui/helpers.js'
import { Text } from '../ui/Typography.js'
import { Box, Flex } from '../ui/Layout.js'
import Icon from '../ui/Icon.js'
import api from '../api.js'
import { toast } from 'react-toastify'

const Popover = styled(Flex)`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 20px;
  right: 0;
  width: 186px;
  z-index: 100;
  background-color: white;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
`

const MenuText = styled(Text)`
  font-size: 14px;
  padding: 12px;
  cursor: pointer;
  :hover {
    color: ${colors.blue};
  }
  border-bottom: 1px solid #edf2f7;
`

const OptionsPopover = ({ id, refresh }) => {
  const [showPopover, setShowPopover] = useState(false)
  const popover = useRef(null)
  const navigate = useNavigate()

  const closeMenu = (event) => {
    if (popover && popover.current && !popover.current.contains(event.target)) {
      document.removeEventListener('click', closeMenu)
      setShowPopover(false)
    }
  }

  const showPopoverFunc = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setShowPopover(true)
    document.addEventListener('click', closeMenu)
  }

  const deleteCampaign = async (e) => {
    e.stopPropagation()
    const res = await api.post('/products/delete', { productId: id })
    if (res.data.success) {
      toast('Product deleted')
      refresh()
    } else {
      toast('Error deleting product')
    }
  }

  return (
    <Box position="relative">
      <Box cursor="pointer" onClick={showPopover ? closeMenu : showPopoverFunc}>
        <Icon width={24} height={24} icon="three-dots-grey" />
      </Box>
      {showPopover ? (
        <Popover ref={popover}>
          <MenuText onClick={deleteCampaign} color={colors.error}>
            Delete Product
          </MenuText>
        </Popover>
      ) : null}
    </Box>
  )
}

export default OptionsPopover
