import React, { useState, useEffect, useRef } from 'react'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import { colors, formatSeconds } from './helpers'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from './Typography'
import { Flex, Box, Container } from './Layout'
import Icon from './Icon'
import Button from './Button'

import ModalContainer from './ModalContainer'
import { uniq, difference } from 'ramda'
import { toast } from 'react-toastify'
import { ReactSortable } from 'react-sortablejs'
import { useContent } from '../ContentProvider'

const GrabIcon = styled(Icon)`
  cursor: grab;
`

const TagSelectModal = ({ header, subHeader, inputTags, isOpen, setIsOpen, saveFunction, hasReorder }) => {
  const { recipes, refresh } = useContent()
  const allTags = uniq(
    recipes
      .map((r) => r.tags)
      .flat()
      .filter((t) => t && t.trim())
  )

  const [tags, setTags] = useState(inputTags || [])

  useEffect(() => {
    setTags(inputTags)
  }, [])

  const save = async () => {
    saveFunction(tags)
    refresh()
  }

  return (
    <ModalContainer
      interiorClose
      p="0px"
      width={hasReorder ? '700px' : '600px'}
      autoHeight
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={save}
    >
      <Flex>
        <Flex flexDirection="column" p="24px" pb="0px">
          <H3 mb="0px"> {header || 'Promoted Tags'}</H3>
          <Text light mb="16px" fontWeight="500">
            {subHeader || 'Select tags to promote in search'}
          </Text>

          <Flex flexWrap="wrap" width="auto" maxHeight="310px" overflowY="scroll">
            {allTags
              ?.sort((a, b) => a && b && a?.toLowerCase().localeCompare(b?.toLowerCase()))
              .map((l) => {
                const isSelected = tags.includes(l)
                return (
                  <Flex
                    alignItems="center"
                    backgroundColor={isSelected ? 'rgba(166, 183, 212, 1)' : 'rgba(225, 232, 255, 1)'}
                    width="auto"
                    height="28px"
                    mr="8px"
                    mb="8px"
                    borderRadius="14px"
                    px="16px"
                    key={l}
                    onClick={() => {
                      if (tags.includes(l)) {
                        setTags(uniq(tags).filter((t) => t !== l))
                      } else {
                        setTags(uniq(tags).concat(l))
                      }
                    }}
                    cursor="pointer"
                  >
                    <Text mb="2px" color={isSelected ? 'white' : colors.textLight} fontWeight="500">
                      {l}
                    </Text>
                  </Flex>
                )
              })}
          </Flex>
        </Flex>
        {hasReorder ? (
          <Flex backgroundColor="#F7FAFC" flexDirection="column" p="24px" pb="0px" overflowY="scroll">
            <Text light mb="16px" fontWeight="500">
              Your selections. Drag to reorder.
            </Text>

            <Flex maxHeight="332px" height="332px" overflowY="scroll">
              <ReactSortable
                list={tags}
                setList={(newTags) => {
                  setTags(newTags.map((t) => t.toString()))
                }}
              >
                {tags.map((l) => {
                  return (
                    <Flex key={l} alignItems="center" mb="8px">
                      <GrabIcon mr="8px" icon="drag-circles-grey" width="24px" height="24px" alt="drag icon" />

                      <Flex
                        alignItems="center"
                        backgroundColor={'rgba(225, 232, 255, 1)'}
                        width="auto"
                        height="28px"
                        mr="8px"
                        borderRadius="14px"
                        px="16px"
                        key={l}
                        onClick={() => {
                          if (tags.includes(l)) {
                            setTags(uniq(tags).filter((t) => t !== l))
                          } else {
                            setTags(uniq(tags).concat(l))
                          }
                        }}
                        cursor="pointer"
                      >
                        <Text mr="8px" mb="2px" color={colors.textLight} fontWeight="500">
                          {l}
                        </Text>
                        <Icon mr="-8px" width="14px" height="14px" icon="circle-cross-grey" />
                      </Flex>
                    </Flex>
                  )
                })}
              </ReactSortable>
            </Flex>
          </Flex>
        ) : null}
      </Flex>
      <Flex justifyContent="flex-end" p="16px" py="8px" borderTop="1px solid rgba(237, 242, 247, 1)">
        <Button
          zIndex={1}
          borderColor="white"
          variant="green"
          label="Done"
          width="100px"
          onClick={() => {
            save()
            setIsOpen(false)
          }}
        />
      </Flex>
    </ModalContainer>
  )
}

export default TagSelectModal
