import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { colors } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle, Label } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import formikFormWrapper from '../formikFormWrapper.js'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import baseUrl from '../baseUrl.js'
import PaymentsTab from './PaymentsTab.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import { Td, Tr, HTr, HeaderText, Table, Th } from '../ui/Table.js'
import { currencySymbols } from '../ui/formatters.js'
import ContactsTab from './Contacts.js'
import { DeleteConfirmationModal, CustomerActionsMenu } from './CustomerActions.js'
import { useTheme } from '../ThemeProvider.js'
import Icon from '../ui/Icon.js'
import { mkConfig, generateCsv, download } from 'export-to-csv'
import { useSearchParams } from 'react-router-dom'
import Button from '../ui/Button.js'
import PopoverDropdown from '../ui/PopoverDropdown.js'
import CreateSubscriberModal from './CreateSubscriberModal.js'
import { useCustomerActions } from './CustomerActions.js'

export const InputContainer = styled(Flex)`
  border: 1px solid #cbd5e0;
  height: 36px;
  border-radius: 36px;
  background-color: white;
  overflow: hidden;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  width: 250px;
`

export const InputComponent = styled.input`
  ::-webkit-input-placeholder {
    font-family: 'Inter', sans-serif;
  }
  ::-moz-placeholder {
    font-family: 'Inter', sans-serif;
  }
  :-ms-input-placeholder {
    font-family: 'Inter', sans-serif;
  }
  :-moz-placeholder {
    font-family: 'Inter', sans-serif;
  }
  background: white;
  height: 36px;
  margin: 0px;
  margin-bottom: 0px;
  border-radius: 6px;
  color: white;
  display: inline-block;
  border: none;
  font-size: 14px;
  text-decoration: none;
  color: ${colors.textDark};
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
`

export const FullWidthForm = styled.form`
  margin-left: 16px;
  width: 100%;
`

const TABS = {
  SUBSCRIBERS: 'subscribers',
  CONTACTS: 'contacts',
  PAYMENTS: 'payments'
}

const TabText = styled(Text)`
  ${(props) => !props.isActive && `color: ${colors.textLight};`}
  padding-bottom: 20px;
  ${(props) => props.isActive && `border-bottom: 1px solid ${colors.green};`}
  font-weight: 600;
  margin-right: 30px;
  cursor: pointer;
`

const Tab = ({ onClick, title, isActive }) => {
  return (
    <TabText onClick={onClick} isActive={isActive} light={isActive ? undefined : true}>
      {title}
    </TabText>
  )
}

const Customers = (props) => {
  let { user, setUser } = useAuth()
  console.log(user)
  const [search, setSearch] = useSearchParams()
  const [activeTab, setActiveTabState] = useState(search.get('tab') || TABS.SUBSCRIBERS)
  const [page, setPage] = useState(1)

  const setActiveTab = (tab) => {
    setActiveTabState(tab)
    setSearch({ tab: tab.toLowerCase() })
  }

  return (
    <Flex flexDirection="column" alignItems="center">
      <Flex position="relative" flexDirection="column" width="100%" p="40px" pb="0px">
        <Flex>
          <H1 mb="32px" width="100%">
            Customers
          </H1>
          {user?.stripeConnectAccountId ? (
            <a href="https://dashboard.stripe.com/subscriptions" target="_blank" rel="noopener noreferrer">
              <Button
                position="absolute"
                renderLeftIcon={() => <Icon icon="box-arrow-diagonal-black" width="12px" height="12px" />}
                top="16px"
                right="16px"
                variant="white"
                fontSize="12px"
                backgroundColor="white"
                shadow
                height="36px"
                boxShadow="0px 0px 1px 0px rgba(12, 26, 75, 0.20), 0px 1px 3px 0px rgba(50, 50, 71, 0.10)"
                label="Go to Stripe"
              />
            </a>
          ) : null}
        </Flex>
        <Flex borderBottom="1px solid #E2E8F0">
          <Tab onClick={() => setActiveTab(TABS.SUBSCRIBERS)} title="Paid" isActive={activeTab === TABS.SUBSCRIBERS} />
          <Tab onClick={() => setActiveTab(TABS.CONTACTS)} title="All" isActive={activeTab === TABS.CONTACTS} />
          <Tab onClick={() => setActiveTab(TABS.PAYMENTS)} title="Payments" isActive={activeTab === TABS.PAYMENTS} />
        </Flex>
      </Flex>
      <Flex bg={colors.backgroundGrey} pt="20px" flexDirection="column" alignItems="center">
        {activeTab === TABS.SUBSCRIBERS && <SubscribersTab />}
        {activeTab === TABS.CONTACTS && <ContactsTab />}
        {activeTab === TABS.PAYMENTS && <PaymentsTab />}
      </Flex>
    </Flex>
  )
}

const PAGESIZE = 50
const LineClamp = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const SubscribersTab = (props) => {
  let { user, setUser } = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [allSubscribers, setAllSubscribers] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [tempSearch, setTempSearch] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  let { theme } = useTheme()

  const { deleteModalOpen, setDeleteModalOpen, selectedSubscriber, handleDeleteUser, confirmDelete } = useCustomerActions(() =>
    fetchSubscribers()
  )

  const fetchSubscribers = async () => {
    setIsLoading(true)
    try {
      const res = await api.get(`/customers/get-subscribers`)
      setAllSubscribers(res.data.payload?.sort((a, b) => dayjs(b.billingCycleAnchor).diff(dayjs(a.billingCycleAnchor))))
      setIsLoading(false)
    } catch (e) {
      console.log(e)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchSubscribers()
  }, [])

  // Filter subscribers based on search term
  const filteredSubscribers = allSubscribers.filter((subscriber) => {
    if (!searchTerm) return true

    const searchLower = searchTerm.toLowerCase()
    return (
      subscriber.firstName?.toLowerCase().trim().includes(searchLower) ||
      subscriber.lastName?.toLowerCase().trim().includes(searchLower) ||
      `${subscriber.firstName} ${subscriber.lastName}`?.toLowerCase().trim().includes(searchLower) ||
      subscriber.email?.toLowerCase().trim().includes(searchLower)
    )
  })

  const totalPages = Math.ceil(filteredSubscribers.length / PAGESIZE)
  // Update to use filteredSubscribers instead of allSubscribers
  const paginatedSubscribers = filteredSubscribers.slice((currentPage - 1) * PAGESIZE, currentPage * PAGESIZE)

  const currencySymbol = currencySymbols[theme.plans?.[0]?.currency]

  const renderPageNumbers = () => {
    const pageNumbers = []
    const maxVisiblePages = 5
    const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2))
    const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1)

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Text
          key={i}
          onClick={() => setCurrentPage(i)}
          light={i !== currentPage}
          fontWeight={i === currentPage ? '600' : '500'}
          px="8px"
          cursor="pointer"
        >
          {i}
        </Text>
      )
    }
    return pageNumbers
  }

  useEffect(() => {
    if (searchTerm !== tempSearch) {
      setTempSearch(searchTerm)
    }
  }, [searchTerm])

  const submit = async (e) => {
    e?.preventDefault()
    setCurrentPage(1)
    setSearchTerm(tempSearch)
  }

  return (
    <Flex px="24px">
      {isLoading ? (
        <Flex height="300px" alignItems="center" justifyContent="center">
          <BigDarkSpinner />
        </Flex>
      ) : (
        <Flex flexDirection="column" pb="100px">
          <Flex mb="10px" justifyContent="space-between">
            <InputContainer>
              <Icon icon="search-grey" width={14} height={14} />
              <FullWidthForm onSubmit={submit}>
                <InputComponent placeholder="Search ..." onChange={(e) => setTempSearch(e.target.value)} value={tempSearch} />
              </FullWidthForm>
              {tempSearch || searchTerm ? (
                <Icon
                  cursor="pointer"
                  icon="circle-cross-grey"
                  width={12}
                  height={12}
                  onClick={() => {
                    setSearchTerm('')
                    setTempSearch('')
                  }}
                />
              ) : null}
            </InputContainer>
            <Button
              fontSize="12px"
              key="subscribers-csv"
              label="Export"
              variant="white"
              backgroundColor="white"
              shadow
              height="36px"
              boxShadow="0px 0px 1px 0px rgba(12, 26, 75, 0.20), 0px 1px 3px 0px rgba(50, 50, 71, 0.10)"
              renderLeftIcon={() => <Icon icon="box-arrow-black" width="14px" height="14px" m />}
              onClick={() => {
                const csv = generateCsv(mkConfig({ useKeysAsHeaders: true }))(
                  // Update to use filteredSubscribers for CSV export
                  filteredSubscribers.map((s) => {
                    return {
                      first_name: s.firstName,
                      last_name: s.lastName,
                      date_joined: dayjs(s.billingCycleAnchor).format('YYYY-MM-DD HH:mm:ss'),
                      email: s.email,
                      plan: `${currencySymbol}${(s.subscriptionPrice / 100).toFixed(2)}${
                        s.planType === 'MONTHLY' ? '/m' : '/yr'
                      }`,
                      subscription_price: s.subscriptionPrice / 100,
                      subscription_type: s.planType?.toLowerCase(),
                      status: s.billingCycleAnchor > new Date().toISOString() ? 'Trial' : 'Active'
                    }
                  })
                )
                const dateSuffix = dayjs().format('YYYY-MM-DD_HH-mm-ss')
                download(mkConfig({ useKeysAsHeaders: true, filename: `subscribers_${dateSuffix}.csv` }))(csv)
              }}
            />
          </Flex>
          <Container p="0px" pt="16px">
            <Flex justifyContent="space-between" px="24px" mb="16px">
              <SmallTitle>Users</SmallTitle>
            </Flex>
            <Table>
              <HTr>
                <Th width="220px !important">
                  <HeaderText left>NAME</HeaderText>
                </Th>
                <Th>
                  <HeaderText left>DATE JOINED</HeaderText>
                </Th>
                <Th width="30%">
                  <HeaderText left>EMAIL</HeaderText>
                </Th>
                <Th>
                  <HeaderText left>PLAN</HeaderText>
                </Th>
                <Th>
                  <HeaderText left>STATUS</HeaderText>
                </Th>
                <Th width="45px"></Th>
              </HTr>
              {paginatedSubscribers &&
                paginatedSubscribers.map((subscriber) => {
                  return (
                    <Tr key={subscriber.id} justifyContent="space-between">
                      <Td left>
                        <LineClamp>
                          {subscriber.firstName} {subscriber.lastName}
                        </LineClamp>
                      </Td>
                      <Td left lightLight>
                        {dayjs(subscriber.billingCycleAnchor).format('MMM DD, YYYY')}
                      </Td>
                      <Td left lightLight>
                        {subscriber.email}
                      </Td>
                      <Td left lightLight>
                        {currencySymbol}
                        {(subscriber.subscriptionPrice / 100).toFixed(2)}
                        {subscriber.planType === 'MONTHLY' ? '/m' : '/yr'}
                      </Td>
                      <Td left lightLight>
                        <Flex alignItems="center">
                          <Box
                            mr="12px"
                            width="10px"
                            borderRadius="10px"
                            height="10px"
                            bg={subscriber.isOnTrial ? 'rgba(247, 147, 111, 1)' : 'rgba(102, 203, 159, 1)'}
                          />
                          {subscriber.isOnTrial ? 'Trial' : 'Active'}
                        </Flex>
                      </Td>
                      <Td width="45px">
                        <CustomerActionsMenu subscriber={subscriber} onDeleteClick={handleDeleteUser} />
                      </Td>
                    </Tr>
                  )
                })}
            </Table>

            {!filteredSubscribers?.length ? (
              <>
                <Text light fontSize="16px" pt="120px" mb="8px" fontWeight="500" textAlign="center">
                  {searchTerm ? 'No matching subscribers found' : 'No subscribers yet'}
                </Text>
                <Text light fontSize="14px" pb="120px" textAlign="center">
                  {searchTerm ? 'Try adjusting your search' : "You'll find all your active subscribers here"}
                </Text>
              </>
            ) : null}
            <Flex>
              <Flex mt="16px" mb="16px" justifyContent="space-between">
                <Text fontWeight="300" width="375px" lightLight px="16px" italic>
                  Showing {!filteredSubscribers?.length ? 0 : (currentPage - 1) * PAGESIZE + 1} -{' '}
                  {Math.min(currentPage * PAGESIZE, filteredSubscribers.length)} of {filteredSubscribers?.length}
                </Text>
                <Flex cursor="pointer" justifyContent="center" alignItems="center">
                  <Text
                    onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)}
                    light={currentPage > 1}
                    lightLight={currentPage === 1}
                    fontWeight="500"
                    px="8px"
                    cursor={currentPage > 1 ? 'pointer' : 'default'}
                  >
                    {'<'}
                  </Text>
                  {renderPageNumbers()}
                  <Text
                    onClick={() => currentPage < totalPages && setCurrentPage(currentPage + 1)}
                    light={currentPage < totalPages}
                    lightLight={currentPage === totalPages}
                    fontWeight="500"
                    px="8px"
                    cursor={currentPage < totalPages ? 'pointer' : 'default'}
                  >
                    {'>'}
                  </Text>
                </Flex>
                <Flex width="375px" justifyContent="flex-end" pr="32px"></Flex>
              </Flex>
            </Flex>
          </Container>
          <DeleteConfirmationModal
            isOpen={deleteModalOpen}
            setIsOpen={setDeleteModalOpen}
            onConfirm={confirmDelete}
            subscriberName={`${selectedSubscriber?.firstName} ${selectedSubscriber?.lastName}`}
          />
        </Flex>
      )}
    </Flex>
  )
}

export default Customers
