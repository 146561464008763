import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import { Link, useSearchParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import { colors } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle, Label } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input, TextArea } from '../ui/Input.js'
import Icon from '../ui/Icon.js'
import Button from '../ui/Button.js'
import formikFormWrapper from '../formikFormWrapper.js'
import api from '../api.js'
import { toast } from 'react-toastify'
import { useAuth } from '../Auth.js'
import baseUrl from '../baseUrl.js'
import ToggleRedGreen from '../ui/ToggleTwoOptions.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import TagSelectModal from '../ui/TagSelectModal.js'
import { useContent } from '../ContentProvider.js'
import TagGroupModal from '../ui/TagGroupModal.js'
import { remove, update } from 'ramda'
import ImageUploadModal from '../ui/ImageUploadModal.js'
import ModalContainer from '../ui/ModalContainer.js'
import Checkbox from '../ui/Checkbox.js'
import { motion } from 'framer-motion'
import SelectComponent from '../ui/Select.js'
import CreateSubscriberModal from '../Customers/CreateSubscriberModal.js'

const FormInput = formikFormWrapper(Input)
const FormTextArea = formikFormWrapper(TextArea)

const FloatingPinkBox = styled(Box)`
  width: 108px;
  height: 121px;
  transform: rotate(19.921deg);
  position: absolute;
  right: -43.765px;
  top: -48px;
  z-index: 1;
  background-color: #f4acc4;
  border-radius: 8px;
`

const Settings = (props) => {
  let { user, setUser } = useAuth()
  let { refresh: contentRefresh } = useContent()
  const [isLoading, setIsLoading] = useState(true)
  const [initialLoad, setInitialLoad] = useState(true)
  const [hasStripeAccount, setHasStripeAccount] = useState(false)
  const [hasRequirements, setHasRequirements] = useState(false)
  const [inputTheme, setTheme] = useState()
  const [showPromotedTagsModal, setShowPromotedTagsModal] = useState(false)
  const [showTagGroupsModal, setShowTagGroupsModal] = useState(false)
  const [faviconUploadOpen, setFaviconUploadOpen] = useState(false)
  const [appIconUploadOpen, setAppIconUploadOpen] = useState(false)
  const [commentsImageUrlOpen, setCommentsImageUrlOpen] = useState(false)
  const [feesModalOpen, setFeesModalOpen] = useState(false)
  const [testUsers, setTestUsers] = useState([])
  const [createSubscriberModalOpen, setCreateSubscriberModalOpen] = useState(false)
  const [saveStatus, setSaveStatus] = useState('Save')

  const refresh = async () => {
    const res = await api.get(`/theme/get?organisationId=${user.organisationId}`)
    setTheme(res.data.payload)

    // Fetch test users
    try {
      const testUsersRes = await api.get('/customers/get-test-customers')
      if (testUsersRes.data.success) {
        setTestUsers(testUsersRes.data.payload || [])
      }
    } catch (error) {
      console.error('Failed to fetch test customers:', error)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    contentRefresh()
    refresh()
  }, [])

  useEffect(() => {
    const asyncFunction = async () => {
      const res = await api.get('stripe/check-connect-account-requirements')
      setHasRequirements(res.data.payload.hasRequirements)
      setHasStripeAccount(res.data.payload.hasStripeAccount)
      setInitialLoad(false)
    }
    asyncFunction()
  }, [])

  const createStripeAccount = async () => {
    setIsLoading(true)
    const response = await api.get('/stripe/create-connect-account')
    if (response.data.success) {
      window.location.href = response.data.payload.redirectUrl
    } else {
      setIsLoading(false)
    }
  }

  return (
    <Flex flexDirection="column" alignItems="center">
      <Flex position="relative" flexDirection="column" width="100%" p="40px" pb="0px">
        <Flex>
          <H1 mb="32px" width="100%">
            Settings
          </H1>
        </Flex>
        <Flex flexDirection="column" maxWidth="600px">
          <Container p="24px" mb="40px">
            <Flex justifyContent="space-between" alignItems="center" mb="24px">
              <H3 mb="0">Payment provider</H3>
              <Icon icon="stripe-logo-black" width="76px" height="32px" />
            </Flex>
            {initialLoad ? (
              <Flex alignItems="center" justifyContent="center" height="100px">
                <BigDarkSpinner />
              </Flex>
            ) : (
              <>
                {hasRequirements || !hasStripeAccount ? (
                  <>
                    <Text light mb="22px">
                      {hasRequirements && hasStripeAccount
                        ? 'Continue setting up your stripe account to accept payments.'
                        : 'Connect Stripe as your payments provider to accept payments.'}
                    </Text>
                    <Flex alignItems="center">
                      <Button
                        small
                        width="auto"
                        isLoading={isLoading}
                        variant="green"
                        label="Connect"
                        onClick={() => {
                          if (!hasStripeAccount) {
                            setFeesModalOpen(true)
                            return
                          }
                          createStripeAccount()
                        }}
                      />
                    </Flex>
                  </>
                ) : (
                  <>
                    <Text light mb="22px">
                      You've connected your Stripe account.
                    </Text>
                    <Flex alignItems="center">
                      <a href="https://dashboard.stripe.com/dashboard" target="_blank" rel="noreferrer">
                        <Button small width="auto" isLoading={isLoading} variant="green" label="Go to stripe" />
                      </a>
                    </Flex>
                  </>
                )}
              </>
            )}
          </Container>
          {!isLoading ? (
            <Formik
              initialValues={inputTheme ? inputTheme : {}}
              validate={(values) => {
                const errors = {}

                if (
                  values.instagram &&
                  !(
                    values.instagram.includes('https://instagram.com/') || values.instagram.includes('https://www.instagram.com/')
                  )
                ) {
                  errors.instagram = 'Please enter a valid Instagram URL (https://www.instagram.com/myhandle)'
                }
                if (
                  values.twitter &&
                  !(
                    values.twitter.includes('https://www.x.com/') ||
                    values.twitter.includes('https://x.com/') ||
                    values.twitter.includes('https://www.twitter.com/') ||
                    values.twitter.includes('https://twitter.com/')
                  )
                ) {
                  errors.twitter = 'Please enter a valid X URL (https://x.com/myhandle)'
                }
                if (
                  values.tiktok &&
                  !(values.tiktok.includes('https://www.tiktok.com/@') || values.tiktok.includes('https://tiktok.com/@'))
                ) {
                  errors.tiktok = 'Please enter a valid TikTok URL (https://www.tiktok.com/@myhandle)'
                }
                if (
                  values.facebook &&
                  !(values.facebook.includes('https://www.facebook.com/') || values.facebook.includes('https://facebook.com/'))
                ) {
                  errors.facebook = 'Please enter a valid Facebook URL (https://www.facebook.com/myhandle)'
                }
                return errors
              }}
              onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true)
                try {
                  const res = await api.post('/theme/update-settings', values)
                  setSaveStatus('Saved')
                  setTimeout(() => setSaveStatus('Save'), 2000)
                } catch (e) {
                  console.log(e)
                  setSaveStatus('Error')
                  setTimeout(() => setSaveStatus('Save'), 2000)
                }

                setSubmitting(false)
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => {
                console.log({ values })
                return (
                  <>
                    <Container p="24px" mb="40px">
                      <H3 mb="0">Contact Email</H3>
                      <Text light mb="16px">
                        Provide an email so that customers can get in touch with you about your site, content or plans.
                      </Text>
                      <Field large label="Email" type="email" name="contactEmail" component={FormInput} dark />
                    </Container>
                    <Container p="24px" mb="40px">
                      <H3 mb="0">Social Links</H3>
                      <Text light mb="16px">
                        Add links to your social media. Your social media links will appear in your site footer.
                      </Text>
                      <Field large mb="24px" label="Instagram" name="instagram" component={FormInput} dark />
                      <Field large mb="24px" label="X (Twitter)" name="twitter" component={FormInput} dark />
                      <Field large mb="24px" label="TikTok" name="tiktok" component={FormInput} dark />
                      <Field large label="Facebook" name="facebook" component={FormInput} dark />
                    </Container>
                    {/* CUSTOM CONTENT */}
                    <Container p="24px" mb="40px">
                      <H3 mb="0">Content</H3>
                      <Text light mb="32px">
                        Give a bit more detail on who you are are and what you do:
                      </Text>
                      <Flex alignItems="center" borderTop="1px solid #EDF2F7" pt="32px" pb="32px">
                        <Flex flexDirection="column">
                          <Text fontWeight="700" lineHeight="24px">
                            About page
                          </Text>
                          <Text light lineHeight="24px">
                            Describe who you are and the benefits of joining your Clubb
                          </Text>
                        </Flex>
                        <Flex width="fit-content">
                          <Link to="/custom-content/about">
                            <Button minWidth="0px" variant="white" shadow label="Edit" />
                          </Link>
                        </Flex>
                      </Flex>
                      <Flex alignItems="center" borderTop="1px solid #EDF2F7" pt="32px" pb="32px">
                        <Flex flexDirection="column">
                          <Text fontWeight="700" lineHeight="24px">
                            Welcome email
                          </Text>
                          <Text light lineHeight="24px">
                            Email sent to free subscribers after signing up
                          </Text>
                        </Flex>
                        <Flex width="fit-content">
                          <Link to="/custom-content/welcome-email">
                            <Button minWidth="0px" variant="white" shadow label="Edit" />
                          </Link>
                        </Flex>
                      </Flex>
                      <Flex alignItems="center" borderTop="1px solid #EDF2F7" pt="32px" pb="32px">
                        <Flex flexDirection="column">
                          <Text fontWeight="700" lineHeight="24px">
                            Paid subscriber welcome email
                          </Text>
                          <Text light lineHeight="24px">
                            Email sent to paid subscribers after completing checkout
                          </Text>
                        </Flex>
                        <Flex width="fit-content">
                          <Link to="/custom-content/subscriber-welcome-email">
                            <Button minWidth="0px" variant="white" shadow label="Edit" />
                          </Link>
                        </Flex>
                      </Flex>
                      <Flex alignItems="center" borderTop="1px solid #EDF2F7" pt="32px" pb="16px">
                        <Flex flexDirection="column">
                          <Text fontWeight="700" lineHeight="24px">
                            FAQs
                          </Text>
                          <Text light lineHeight="24px">
                            Answer common questions and upsell features.
                          </Text>
                        </Flex>
                        <Flex width="fit-content">
                          <Link to="/custom-content/faqs">
                            <Button minWidth="0px" variant="white" shadow label="Edit" />
                          </Link>
                        </Flex>
                      </Flex>
                    </Container>
                    <Container p="24px" mb="40px">
                      <H3 mb="16px">Search Settings</H3>
                      <Text fontWeight="600" fontSize="16px" mb="0px" light>
                        Promoted Tags
                      </Text>
                      <Text light mb="16px">
                        Select the tags to promote first in search
                      </Text>
                      <Flex flexWrap="wrap" noScrollBar>
                        {(values.promotedTags || []).map((tag) => {
                          return (
                            <Flex
                              alignItems="center"
                              backgroundColor="rgba(225, 232, 255, 1)"
                              width="auto"
                              height="28px"
                              mr="8px"
                              mb="8px"
                              borderRadius="14px"
                              px="16px"
                              key={tag}
                              cursor="pointer"
                              onClick={() => setShowPromotedTagsModal(true)}
                            >
                              <Text whiteSpace="nowrap" mb="2px" light fontWeight="500">
                                {tag}
                              </Text>
                            </Flex>
                          )
                        })}
                      </Flex>
                      <Text cursor="pointer" light onClick={() => setShowPromotedTagsModal(true)}>
                        + Add a tag
                      </Text>
                      <TagSelectModal
                        hasReorder
                        inputTags={values.promotedTags || []}
                        isOpen={showPromotedTagsModal}
                        setIsOpen={setShowPromotedTagsModal}
                        saveFunction={(tags) => setFieldValue('promotedTags', tags)}
                      />

                      <Flex my="24px" borderBottom="1px solid #EDF2F7" />
                      <Text fontWeight="600" fontSize="16px" mb="0px" light>
                        Tag Groups
                      </Text>
                      <Text light mb="16px">
                        Group tags for quick filtering
                      </Text>
                      {(values.tagGroups || [])
                        .sort((a, b) => {
                          return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                        })
                        .map((tagGroup, i) => {
                          return (
                            <Flex key={tagGroup.name} flexDirection="column" mb="16px">
                              <Text light fontSize="14px" mb="4px">
                                {tagGroup.name}
                              </Text>
                              <Flex flexWrap="wrap" noScrollBar>
                                {tagGroup.tags.map((tag) => {
                                  return (
                                    <Flex
                                      alignItems="center"
                                      backgroundColor="rgba(225, 232, 255, 1)"
                                      width="auto"
                                      height="28px"
                                      mr="8px"
                                      mb="8px"
                                      borderRadius="14px"
                                      px="16px"
                                      key={tag}
                                      cursor="pointer"
                                      onClick={() => setShowTagGroupsModal(tagGroup)}
                                    >
                                      <Text whiteSpace="nowrap" mb="2px" light fontWeight="500">
                                        {tag}
                                      </Text>
                                    </Flex>
                                  )
                                })}
                                <Icon
                                  cursor="pointer"
                                  mt="5px"
                                  icon="pencil-square-grey"
                                  ml="8px"
                                  onClick={() => setShowTagGroupsModal(tagGroup)}
                                />
                              </Flex>
                            </Flex>
                          )
                        })}
                      <Text cursor="pointer" light onClick={() => setShowTagGroupsModal({})}>
                        + Create group
                      </Text>
                      <TagGroupModal
                        inputTags={[]}
                        inputTagGroup={showTagGroupsModal}
                        setIsOpen={setShowTagGroupsModal}
                        saveFunction={(tg) => {
                          if (showTagGroupsModal && showTagGroupsModal.tags) {
                            setFieldValue(
                              'tagGroups',
                              values.tagGroups.map((tagGroup) => {
                                if (tagGroup.name === showTagGroupsModal.name) {
                                  return tg
                                }
                                return tagGroup
                              })
                            )
                          } else {
                            setFieldValue('tagGroups', (values.tagGroups || []).concat(tg))
                          }
                        }}
                        deleteGroup={
                          showTagGroupsModal && showTagGroupsModal.tags
                            ? () =>
                                setFieldValue(
                                  'tagGroups',
                                  values.tagGroups.filter((tagGroup) => {
                                    if (tagGroup.name === showTagGroupsModal.name) {
                                      return false
                                    }
                                    return tagGroup
                                  })
                                )
                            : undefined
                        }
                      />
                      <Flex my="24px" borderBottom="1px solid #EDF2F7" />

                      <Text fontWeight="600" fontSize="16px" mb="8px" light>
                        Show nutrition on recipe cards:
                      </Text>
                      <Flex
                        alignItems="center"
                        mb="8px"
                        cursor="pointer"
                        onClick={() => {
                          if (values.nutritionOnSearch.includes('calories')) {
                            setFieldValue(
                              'nutritionOnSearch',
                              values.nutritionOnSearch.filter((n) => n !== 'calories')
                            )
                          } else {
                            setFieldValue('nutritionOnSearch', values.nutritionOnSearch.concat('calories'))
                          }
                        }}
                      >
                        <Checkbox checked={values.nutritionOnSearch.includes('calories')} />
                        <Text ml="16px" light>
                          Calories
                        </Text>
                      </Flex>
                      <Flex
                        alignItems="center"
                        mb="8px"
                        cursor="pointer"
                        onClick={() => {
                          if (values.nutritionOnSearch.includes('proteins')) {
                            setFieldValue(
                              'nutritionOnSearch',
                              values.nutritionOnSearch.filter((n) => n !== 'proteins')
                            )
                          } else {
                            setFieldValue('nutritionOnSearch', values.nutritionOnSearch.concat('proteins'))
                          }
                        }}
                      >
                        <Checkbox checked={values.nutritionOnSearch.includes('proteins')} />
                        <Text ml="16px" light>
                          Protein
                        </Text>
                      </Flex>
                      <Flex
                        alignItems="center"
                        mb="8px"
                        cursor="pointer"
                        onClick={() => {
                          if (values.nutritionOnSearch.includes('carbohydrates')) {
                            setFieldValue(
                              'nutritionOnSearch',
                              values.nutritionOnSearch.filter((n) => n !== 'carbohydrates')
                            )
                          } else {
                            setFieldValue('nutritionOnSearch', values.nutritionOnSearch.concat('carbohydrates'))
                          }
                        }}
                      >
                        <Checkbox checked={values.nutritionOnSearch.includes('carbohydrates')} />
                        <Text ml="16px" light>
                          Carbohydrates
                        </Text>
                      </Flex>
                      <Flex
                        alignItems="center"
                        mb="8px"
                        cursor="pointer"
                        onClick={() => {
                          if (values.nutritionOnSearch.includes('fat')) {
                            setFieldValue(
                              'nutritionOnSearch',
                              values.nutritionOnSearch.filter((n) => n !== 'fat')
                            )
                          } else {
                            setFieldValue('nutritionOnSearch', values.nutritionOnSearch.concat('fat'))
                          }
                        }}
                      >
                        <Checkbox checked={values.nutritionOnSearch.includes('fat')} />
                        <Text ml="16px" light>
                          Fat
                        </Text>
                      </Flex>
                    </Container>
                    {/* */}
                    <Container p="24px" mb="40px">
                      <H3 mb="0">Features</H3>
                      <Text light mb="16px">
                        Enable or disable certain features
                      </Text>
                      <Flex justifyContent="space-between" alignItems="center" pt="16px" mb="16px">
                        <Text light fontWeight="500">
                          Meal planning
                        </Text>
                        <ToggleRedGreen
                          setSelected={(selected) => {
                            setFieldValue('mealPlanEnabled', selected)
                          }}
                          value1={false}
                          value2={true}
                          customActiveColor="#007D5B"
                          selected={values.mealPlanEnabled}
                        />
                      </Flex>
                      <Flex justifyContent="space-between" alignItems="center" pt="16px" mb="16px">
                        <Text light fontWeight="500">
                          Recipe printing
                        </Text>
                        <ToggleRedGreen
                          setSelected={(selected) => {
                            setFieldValue('recipePrintEnabled', selected)
                          }}
                          value1={false}
                          value2={true}
                          customActiveColor="#007D5B"
                          selected={values.recipePrintEnabled}
                        />
                      </Flex>
                    </Container>
                    <Container p="24px" mb="40px">
                      <H3 mb="0">App</H3>
                      <Text light mb="16px">
                        Let paying subscribers use your Clubb via your own branded app
                      </Text>

                      <Flex justifyContent="space-between" alignItems="center" pt="16px" mb="32px">
                        <Text light fontWeight="500">
                          Enable mobile app
                        </Text>
                        <ToggleRedGreen
                          setSelected={(selected) => {
                            setFieldValue('pwaEnabled', selected)
                          }}
                          value1={false}
                          value2={true}
                          customActiveColor="#007D5B"
                          selected={values.pwaEnabled}
                        />
                      </Flex>

                      <Flex>
                        <Flex justifyContent="center" flexDirection="column">
                          <Text light fontSize="14px" fontWeight="500">
                            Select an app icon for homescreens
                          </Text>
                          <Text mb="16px" fontSize="12px" light>
                            512 x 512 pixels PNG.
                          </Text>
                          {values.appIcon ? (
                            <Box>
                              <Button variant="secondary" label="Change image" onClick={() => setAppIconUploadOpen(true)} />
                            </Box>
                          ) : null}
                        </Flex>
                        {values.appIcon ? (
                          <Box
                            width="140px"
                            minWidth="140px"
                            height="140px"
                            onClick={() => setAppIconUploadOpen(true)}
                            cursor="pointer"
                          >
                            <Image
                              src={values.appIcon + '?width=400&height=400'}
                              width="140px"
                              height="140px"
                              minWidth="140px"
                              objectFit="cover"
                              borderRadius="16px"
                            />
                          </Box>
                        ) : (
                          <Flex
                            borderRadius="16px"
                            width="140px"
                            minWidth="140px"
                            height="140px"
                            cursor="pointer"
                            justifyContent="center"
                            alignItems="center"
                            border="2px dashed #A6B7D4"
                            onClick={() => setAppIconUploadOpen(true)}
                          >
                            <Icon icon="plus-light-grey" width="20px" height="20px" />
                          </Flex>
                        )}
                        <ImageUploadModal
                          isOpen={appIconUploadOpen}
                          setIsOpen={setAppIconUploadOpen}
                          saveFunction={(url) => {
                            setFieldValue('appIcon', url[0])
                            setAppIconUploadOpen(false)
                          }}
                        />
                      </Flex>
                    </Container>
                    <Container p="24px" mb="40px">
                      <H3 mb="0">Community</H3>

                      <Flex justifyContent="space-between" alignItems="center" pt="16px" mb="16px">
                        <Text light fontWeight="500">
                          Enable comments
                        </Text>
                        <ToggleRedGreen
                          setSelected={(selected) => {
                            setFieldValue('commentsEnabled', selected)
                          }}
                          value1={false}
                          value2={true}
                          customActiveColor="#007D5B"
                          selected={values.commentsEnabled}
                        />
                      </Flex>

                      <Flex my="8px" borderBottom="1px solid #EDF2F7" />

                      <Flex justifyContent="space-between" alignItems="center" pt="16px" mb="16px">
                        <Text light fontWeight="500">
                          Enable chat
                        </Text>
                        <ToggleRedGreen
                          setSelected={(selected) => {
                            setFieldValue('chatEnabled', selected)
                          }}
                          value1={false}
                          value2={true}
                          customActiveColor="#007D5B"
                          selected={!!values.chatEnabled}
                        />
                      </Flex>

                      {values.chatEnabled && (
                        <motion.div
                          initial={{ height: 0, opacity: 0 }}
                          animate={{ height: 'auto', opacity: 1 }}
                          exit={{ height: 0, opacity: 0 }}
                          transition={{ duration: 0.3 }}
                        >
                          <Flex justifyContent="space-between" alignItems="center" pt="16px" mb="16px">
                            <Text light fontWeight="500">
                              Allow subscribers to start threads
                            </Text>
                            <ToggleRedGreen
                              setSelected={(selected) => {
                                setFieldValue('chatUsersCanCreateThreads', selected)
                              }}
                              value1={false}
                              value2={true}
                              customActiveColor="#007D5B"
                              selected={!!values.chatUsersCanCreateThreads}
                            />
                          </Flex>
                        </motion.div>
                      )}
                    </Container>
                    <Container p="24px" mb="40px">
                      <H3 mb="0">Creator Profile</H3>

                      <Flex flexDirection="column" pt="16px" mb="32px">
                        <Label mb="8px">Comments and chat name</Label>
                        <Field large name="commentsName" component={FormInput} dark />
                      </Flex>

                      <Flex mb="32px" justifyContent="space-between" alignItems="center">
                        <Label>Add a profile picture</Label>
                        <Flex width="60px" minWidth="60px" height="60px">
                          {values.commentsImageUrl ? (
                            <Box
                              width="60px"
                              minWidth="60px"
                              height="60px"
                              onClick={() => setCommentsImageUrlOpen(true)}
                              cursor="pointer"
                            >
                              <Image
                                src={values.commentsImageUrl + '?width=200&height=200'}
                                width="60px"
                                height="60px"
                                minWidth="60px"
                                objectFit="cover"
                                borderRadius="30px"
                              />
                            </Box>
                          ) : (
                            <Flex
                              borderRadius="30px"
                              width="60px"
                              minWidth="60px"
                              height="60px"
                              cursor="pointer"
                              justifyContent="center"
                              alignItems="center"
                              border="2px dashed #A6B7D4"
                              onClick={() => setCommentsImageUrlOpen(true)}
                            >
                              <Icon icon="plus-light-grey" width="16px" height="16px" />
                            </Flex>
                          )}
                          <ImageUploadModal
                            isOpen={commentsImageUrlOpen}
                            setIsOpen={setCommentsImageUrlOpen}
                            saveFunction={(url) => {
                              setFieldValue('commentsImageUrl', url[0])
                              setCommentsImageUrlOpen(false)
                            }}
                          />
                        </Flex>
                      </Flex>

                      <Flex flexDirection="column">
                        <Label>Connected Subscriber Account & Notifications</Label>
                        <Text light mb="16px" fontSize="12px" lineHeight="130%">
                          Select a subscriber to attach to your creator account. This enables app push notifications to this
                          subscriber for all comments and replies. You can also reply to comments and chats as the creator
                          directly in the app on this account. This must be a subscriber{' '}
                          <Span underline onClick={() => setCreateSubscriberModalOpen(true)} style={{ cursor: 'pointer' }}>
                            created in your Clubb dashboard
                          </Span>
                          , rather than signed up via your website or app.
                        </Text>

                        <SelectComponent
                          options={testUsers.map((user) => ({
                            value: user.id,
                            label: user.name || user.email
                          }))}
                          value={values.adminTestUserId}
                          onChange={(option) => setFieldValue('adminTestUserId', option ? option.value : '')}
                          placeholder="Select option"
                          clearable={true}
                          fullWidth
                        />
                      </Flex>
                    </Container>
                    {/* */}
                    <Container p="24px" mb="40px">
                      <H3 mb="0">Icons</H3>
                      <ImageUploadModal
                        isOpen={faviconUploadOpen}
                        setIsOpen={setFaviconUploadOpen}
                        saveFunction={(url) => {
                          setFieldValue('favicon', url[0])
                          setFaviconUploadOpen(false)
                        }}
                      />
                      <Flex mb="40px">
                        <Flex justifyContent="center" flexDirection="column">
                          <Text light fontSize="14px" fontWeight="500">
                            Select a favicon to display on browser tabs
                          </Text>
                          <Text mb="16px" fontSize="12px" light>
                            Recommended SVG or 32 x 32 pixels PNG.
                          </Text>
                          {values.favicon ? (
                            <Box>
                              <Button variant="secondary" label="Change image" onClick={() => setFaviconUploadOpen(true)} />
                            </Box>
                          ) : null}
                        </Flex>
                        {values.favicon ? (
                          <Box
                            width="140px"
                            minWidth="140px"
                            height="140px"
                            onClick={() => setFaviconUploadOpen(true)}
                            cursor="pointer"
                          >
                            <Image
                              src={values.favicon + '?width=400&height=400'}
                              width="140px"
                              height="140px"
                              minWidth="140px"
                              objectFit="cover"
                              borderRadius="16px"
                            />
                          </Box>
                        ) : (
                          <Flex
                            borderRadius="16px"
                            width="140px"
                            minWidth="140px"
                            height="140px"
                            cursor="pointer"
                            justifyContent="center"
                            alignItems="center"
                            border="2px dashed #A6B7D4"
                            onClick={() => setFaviconUploadOpen(true)}
                          >
                            <Icon icon="plus-light-grey" width="20px" height="20px" />
                          </Flex>
                        )}
                      </Flex>
                    </Container>
                    {/* */}
                    <Container p="24px" mb="40px">
                      <H3 mb="0">SEO Metadata</H3>
                      <Text light mb="16px">
                        Change to custom metadata
                      </Text>
                      <Field mb="24px" large label="Title" name="seoTitle" component={FormInput} dark />
                      <Label>Meta description</Label>
                      <Field mb="24px" large name="seoDescription" component={FormTextArea} dark rows="4" />
                    </Container>
                    {/* */}
                    <Container p="24px" mb="40px">
                      <H3 mb="0">Tracking</H3>
                      <Text light mb="16px">
                        Add your tracking pixels and IDs
                      </Text>
                      <Field
                        mb="24px"
                        large
                        label="Google Analytics (G-XXXXXXXXXX)"
                        name="gaTrackingId"
                        component={FormInput}
                        dark
                      />
                      <Field
                        mb="24px"
                        large
                        label="Facebook Pixel Id (XXXXXXXXXXXXXXXX)"
                        name="facebookPixelId"
                        component={FormInput}
                        dark
                      />
                    </Container>
                    {/* */}
                    <Flex justifyContent="flex-end" alignItems="center" mb="32px">
                      {errors && Object.keys(errors).length > 0 ? <Error mr="16px">Please check form errors</Error> : null}
                      <Button isLoading={isSubmitting} variant="green" label={saveStatus} onClick={handleSubmit} />
                    </Flex>
                    <Flex position="fixed" top="24px" right="24px" justifyContent="flex-end" alignItems="center" mb="32px">
                      {errors && Object.keys(errors).length > 0 ? <Error mr="16px">Please check form errors</Error> : null}

                      <Button isLoading={isSubmitting} variant="green" label={saveStatus} onClick={handleSubmit} />
                    </Flex>
                  </>
                )
              }}
            </Formik>
          ) : null}
        </Flex>
      </Flex>
      <ModalContainer isOpen={feesModalOpen} setIsOpen={setFeesModalOpen} p="0px" width="720px" autoHeight interiorClose>
        <FloatingPinkBox />
        <Flex position="relative" backgroundColor="#FDF4E8" height="100px" py="24px" px="40px" flexDirection="column">
          <H3 mb="8px">A quick note</H3>
          <Text light>Things to know when setting up payments</Text>
        </Flex>
        <Flex flexDirection="column" py="24px" px="40px">
          <Text mb="16px" light>
            When you begin accepting payments, Clubb will collect a 10% share of the revenue. That's what powers us to make Clubb
            awesome.
          </Text>
          <Text mb="32px" light>
            This is exclusive of Stripe's payment processing fees, which vary country by country, starting from 1.5% + 20p in the
            UK and 2.9% + 30¢ in the US. Read more about Stripe's fees{' '}
            <a href="https://stripe.com/pricing" rel="noreferrer" target="_blank">
              <Span underline>here</Span>
            </a>
            .
          </Text>

          <Flex justifyContent="flex-end">
            <Button
              variant="green"
              isLoading={isLoading}
              label="Agree and continue"
              onClick={async () => {
                await createStripeAccount()
                setFeesModalOpen(false)
              }}
            />
          </Flex>
        </Flex>
      </ModalContainer>
      <CreateSubscriberModal
        isOpen={createSubscriberModalOpen}
        setIsOpen={setCreateSubscriberModalOpen}
        onClose={() => refresh()} // Refresh the list of test users after creating a new one
      />
    </Flex>
  )
}

export default Settings
