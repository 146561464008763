import React from 'react'
import styled from 'styled-components'
import { colors } from './helpers'
import { Box, Flex } from './Layout.js'
import { Text } from './Typography'
import Spinner from './Spinner'

import {
  space,
  width,
  color,
  flex,
  order,
  fontSize,
  alignSelf,
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
  borders,
  minWidth,
  minHeight,
  borderRadius,
  size,
  height,
  maxWidth,
  textAlign,
  boxShadow,
  borderColor,
  position,
  display,
  top,
  bottom,
  left,
  right,
  zIndex,
  fontFamily
} from 'styled-system'

const fontForColor = {
  softBlue: colors.textLight,
  secondary: colors.green,
  backgroundGrey: colors.textDark,
  darkBackgroundGrey: colors.textDark,
  blue: 'white',
  error: 'white',
  black: 'white',
  green: 'white',
  white: 'black',
  red: 'white',
  search: colors.textDark
}

const ButtonComponent = styled.button`
  height: ${(props) => (props.large ? '48px' : props.small ? '36px' : '40px')};
  border-radius: 50px;
  border: none;
  ${(props) => props.borderColor && `border: 2px solid ${props.borderColor};`};
  background-color: ${(props) => props.backgroundColorOveride || colors[props.variant]};
  ${(props) => (props.background ? `background: ${props.background};` : '')}
  color: ${(props) => props.fontColorOveride || fontForColor[props.variant]};
  font-size: 18px;
  display: inline-block;
  padding: 0px 20px;
  min-width: 100px;
  ${(props) => props.variant === 'secondary' && `border: 1px solid ${colors.green};`}
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  ${position};
  ${width};
  ${space};
  ${fontFamily};
  ${fontSize};
  ${color};
  ${flex};
  ${order};
  ${alignSelf};
  ${borders};
  ${borderColor};
  ${minWidth};
  ${minHeight};
  ${borderRadius};
  ${size};
  ${height};
  ${textAlign};
  ${maxWidth};
  ${boxShadow};
  ${display};
  ${top};
  ${bottom};
  ${left};
  ${right};
  ${zIndex};
  :hover {
    opacity: 0.9;
  }
  ${(props) => props.isDisabled && `opacity: 0.3; :hover { opacity: 0.3;}`}
  ${(props) =>
    props.shadow
      ? props.boxShadow
        ? props.boxShadow
        : `box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);filter: drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24));`
      : ''}
`

const Label = styled.p`
  ${(props) => props.fontFamily && `font-family: ${props.fontFamily};`}
  font-weight: ${(props) => props.fontWeight || 600};
  font-size: ${(props) => props.fontSize || '14px'};
  line-height: 14px;
`

export default ({
  label,
  isLoading,
  renderLeftIcon,
  isDisabled,
  renderRightIcon,
  large,
  renderCenterIcon,
  fontWeight,
  fontSize,
  fontFamily,
  ...rest
}) => {
  return (
    <ButtonComponent large={large} disabled={isDisabled} isDisabled={isDisabled} {...rest}>
      <Flex justifyContent="center" alignItems="center" width="auto">
        {renderLeftIcon && (
          <Box ml="-8px" width="24px">
            {renderLeftIcon()}
          </Box>
        )}
        {isLoading && <Spinner />}
        {!isLoading && label && (
          <Label fontFamily={fontFamily} fontSize={fontSize} fontWeight={fontWeight}>
            {label}
          </Label>
        )}
        {!isLoading && renderCenterIcon && renderCenterIcon()}
        {renderRightIcon && renderRightIcon()}
      </Flex>
    </ButtonComponent>
  )
}
