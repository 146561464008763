import React, { useState, useEffect, useRef, useCallback } from 'react'
import styled from 'styled-components'
import { Box, Flex } from '../../ui/Layout'

const Popover = styled(Flex)`
  display: flex;
  flex-direction: column;
  position: absolute;
  ${(props) => (props.bottom ? `bottom: ${props.bottomOveride || '32px'};` : `top: ${props.topOveride || '32px'};`)};
  width: ${(props) => props.width || '500px'};
  ${(props) => (props.right ? 'right: 0;' : `left: ${props.leftOveride || '0'};`)};
  box-shadow: ${(props) =>
    props.boxShadowOverride
      ? props.boxShadowOverride
      : '0px 0px 1px 0px rgba(12, 26, 75, 0.1), 0px 4px 20px -2px rgba(50, 50, 71, 0.08)'};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '16px')};
  max-height: 400px;
  overflow-y: scroll;
  background-color: white;
`

const Option = styled(Text)`
  font-size: 14px;
  line-height: 100%;
  padding: 8px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
  padding-top: 12px;
  cursor: pointer;
  border-radius: 6px;
  font-weight: 500;
  :hover {
    color: white;
    background-color: black;
  }
`

const PopoverModal = ({
  config,
  right,
  bottom,
  isOpen,
  setIsOpen,
  width,
  children,
  borderRadius,
  leftOveride,
  bottomOveride,
  topOveride,
  boxShadowOverride
}) => {
  // const [showPopover, setShowPopover] = useState(false)
  const popover = useRef(null)
  const closeMenu = useCallback(() => {
    document.removeEventListener('click', closeMenu)
    setIsOpen(false)
  }, [])

  useEffect(() => {
    if (isOpen) {
      setIsOpen(true)
      document.addEventListener('click', closeMenu)
    } else {
      closeMenu()
    }
  }, [isOpen])

  return (
    <Box position="relative">
      {isOpen ? (
        <Box
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            closeMenu()
          }}
          position="fixed"
          left="0px"
          top="0px"
          width="100%"
          height="100%"
          bg="rgba(0,0,0,0.0)"
          zIndex="99999"
        />
      ) : null}
      {isOpen ? (
        <Popover
          zIndex="999999"
          width={width}
          right={right}
          bottom={bottom}
          ref={popover}
          borderRadius={borderRadius}
          backgroundColor={config.backgroundColor}
          leftOveride={leftOveride}
          bottomOveride={bottomOveride}
          topOveride={topOveride}
          boxShadowOverride={boxShadowOverride}
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </Popover>
      ) : null}
    </Box>
  )
}

export default PopoverModal
