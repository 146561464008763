import { useState, useEffect } from 'react'
import Button from '../ui/Button'
import Icon from '../ui/Icon'
import { Input, TextArea } from '../ui/Input'
import { Box, Flex } from '../ui/Layout'
import { Label, Span, Text } from '../ui/Typography'
import { uniq, update } from 'ramda'
import styled from 'styled-components'
import AddIngredientsModal from './AddIngredientsModal'
import { ReactSortable } from 'react-sortablejs'
import { colors } from '../ui/helpers'
import { v4 as uuidv4 } from 'uuid'
import { useAuth } from '../Auth'

const categoryOrder = [
  'Fruit and Vegetables',
  'Meat, Poultry, Fish',
  'Plant Based Proteins',
  'Dairy',
  'Pasta, Rice & Grains',
  'Herbs & Spices',
  'Baking',
  'Cupboard Staples',
  'Canned & Jarred',
  'Other'
]

const MeasurePopover = styled(Box)`
  background-color: white;
  position: absolute;
  left: 0;
  top: 42px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05);
  overflow-y: scroll;
  flexdirection: column;
  maxheight: 400px;
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
  z-index: 1;
  p:hover {
    background-color: black;
    color: white;
  }
`

const CategoryPopover = styled(Box)`
  background-color: white;
  position: absolute;
  left: 0;
  top: 42px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05);
  overflow-y: scroll;
  flexdirection: column;
  maxheight: 400px;
  width: 150%;
  border-radius: 8px;
  cursor: pointer;
  z-index: 1;
  p:hover {
    background-color: black;
    color: white;
  }
`

const GrabIcon = styled(Icon)`
  cursor: grab;
`

const measures = ['g', 'ml', 'oz', 'cup', 'fl oz', 'tbsp', 'tspn', 'lb']

const FormWithMargin = styled.form`
  margin-top: 32px;
`

const LinkIcon = styled(Icon)`
  position: absolute;
  right: 2px;
  top: 3px;
  cursor: pointer;
  padding: 8px;
  display: none;
`

const LinkPopover = styled(Box)`
  background-color: white;
  position: absolute;
  left: 250px;
  top: 42px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05);
  width: 330px;
  border-radius: 8px;
  z-index: 1;
  padding: 8px;
`

const InputWrapper = styled(Box)`
  position: relative;
  &:hover ${LinkIcon} {
    display: inline-block;
  }

  ${LinkIcon} {
    display: ${(props) => (props.hasLink ? 'inline-block' : 'none')};
  }
`

const SectionRow = ({ i, setIngredients, ingredients, ingredient }) => {
  return (
    <FormWithMargin
      key={i}
      onSubmit={(e) => {
        e.preventDefault()
        if (i === ingredients.length - 1) {
          setIngredients([...ingredients, {}])
          setTimeout(() => document.getElementById(`ingredient-input-${ingredients.length}`).focus(), 1)
        }
      }}
    >
      <input type="submit" style={{ display: 'none' }} />
      <Flex justifyContent="space-between" alignItems="center" mb="16px" mt="16px">
        <GrabIcon mr="8px" icon="drag-circles-grey" width="24px" height="24px" alt="drag icon" />

        <Box width="100%">
          <Input
            placeholder="eg. For the dressing"
            border="1px solid #CBD5E0"
            onChange={(e) => {
              const value = e.target.value
              const newSteps = update(i, { ...ingredient, section: value, isSection: true }, ingredients)
              setIngredients(newSteps)
            }}
            value={ingredient.section}
          />
        </Box>

        <Icon
          onClick={() => {
            const newIngredients = ingredients.filter((_, index) => index !== i)
            setIngredients(newIngredients)
          }}
          ml="8px"
          icon="circle-cross-grey-outline"
          width="16px"
          height="16px"
          cursor="pointer"
        />
      </Flex>
    </FormWithMargin>
  )
}

const Ingredients = ({ ingredients, setIngredients, setPortions, portions }) => {
  const { user } = useAuth()
  const [showMeasurePopover, setShowMeasurePopover] = useState(-1)
  const [showCategoryPopover, setShowCategoryPopover] = useState(-1)
  const [showLinkPopover, setShowLinkPopover] = useState(-1)
  const [showAddIngredient, setShowAddIngredient] = useState(false)
  const hasBeenCategorised = ingredients.some((ingredient) => ingredient.category)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showLinkPopover !== -1 && !event.target.closest('.link-popover-container')) {
        setShowLinkPopover(-1)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [showLinkPopover])

  const handleInputFocus = () => {
    setShowLinkPopover(-1)
  }

  return (
    <>
      <Text mb="24px" mt="8px" color="black" fontSize="18px" fontWeight="600" width="100%">
        Ingredients
      </Text>
      <Label>For:</Label>
      <Input
        dark
        type="number"
        min={0}
        onChange={(e) => {
          const value = e.target.value
          setPortions(value)
        }}
        value={portions}
        width="172px"
        pl="16px !important"
        pr="16px !important"
      />
      <Text lightLight fontSize="12px">
        Number of people / portions
      </Text>
      <Flex justifyContent="space-between" mb="8px" borderBottom="1px solid rgba(237, 242, 247, 1)" pt="24px" pb="8px" mt="24px">
        <Text light fontSize="14px" fontWeight="500" width="100%" mr="16px">
          Ingredients
        </Text>
        <Box width="120px" minWidth="120px" mr="16px">
          <Text light fontSize="14px" fontWeight="500">
            Amount
          </Text>
        </Box>
        <Box width="120px" minWidth="120px" mr={hasBeenCategorised ? '16px' : '16px'}>
          <Text light fontSize="14px" fontWeight="500" width="120px" minWidth="120px">
            Measure
          </Text>
        </Box>
        {hasBeenCategorised ? (
          <Box width="120px" minWidth="120px" mr="24px">
            <Text light fontSize="14px" fontWeight="500">
              Category
            </Text>
          </Box>
        ) : null}
      </Flex>
      <ReactSortable
        list={ingredients}
        setList={(newIngredients) => {
          setIngredients(newIngredients)
        }}
      >
        {ingredients.map((ingredient, i) => {
          if (ingredient.isSection) {
            return <SectionRow key={i} i={i} setIngredients={setIngredients} ingredients={ingredients} ingredient={ingredient} />
          }
          return (
            <form
              key={ingredient.id}
              onSubmit={(e) => {
                e.preventDefault()
                if (i === ingredients.length - 1) {
                  setIngredients([...ingredients, {}])
                  setTimeout(() => document.getElementById(`ingredient-input-${ingredients.length}`).focus(), 1)
                }
              }}
            >
              <input type="submit" style={{ display: 'none' }} />
              <Flex justifyContent="space-between" alignItems="center" mb="16px">
                <GrabIcon mr="8px" icon="drag-circles-grey" width="24px" height="24px" alt="drag icon" />

                <Box width="100%" mr="16px">
                  <InputWrapper className="link-popover-container" hasLink={!!ingredient.link}>
                    <Input
                      width="auto"
                      id={`ingredient-input-${i}`}
                      dark
                      onFocus={handleInputFocus}
                      onChange={(e) => {
                        const value = e.target.value
                        const newSteps = update(i, { ...ingredient, ingredient: value }, ingredients)
                        setIngredients(newSteps)
                      }}
                      value={ingredient.ingredient}
                    />
                    <LinkIcon icon="link-diagonal-grey" width="32px" height="32px" onClick={() => setShowLinkPopover(i)} />
                    {showLinkPopover === i && (
                      <LinkPopover>
                        <Input
                          dark
                          placeholder={`e.g. https://${user.subdomain}.theclubb.co/recipe/...`}
                          onChange={(e) => {
                            const value = e.target.value
                            const newSteps = update(i, { ...ingredient, link: value }, ingredients)
                            setIngredients(newSteps)
                          }}
                          value={ingredient.link || ''}
                        />
                      </LinkPopover>
                    )}
                  </InputWrapper>
                </Box>
                <Box width="120px" mr="16px">
                  <Input
                    px="8px"
                    dark
                    onFocus={handleInputFocus}
                    onChange={(e) => {
                      const value = e.target.value
                      const newSteps = update(i, { ...ingredient, amount: value }, ingredients)
                      setIngredients(newSteps)
                    }}
                    value={ingredient.amount}
                  />
                </Box>
                <Box width="120px" position="relative">
                  <Input
                    px="8px"
                    dark
                    onFocus={() => {
                      handleInputFocus()
                      setShowMeasurePopover(i)
                    }}
                    onBlur={() => setTimeout(() => setShowMeasurePopover(-1), 200)}
                    onChange={(e) => {
                      const value = e.target.value
                      const newSteps = update(i, { ...ingredient, measure: value }, ingredients)
                      setIngredients(newSteps)
                    }}
                    value={ingredient.measure}
                  />
                  {showMeasurePopover === i ? (
                    <MeasurePopover>
                      {measures.map((measure) => {
                        return (
                          <Text
                            onClick={() => {
                              const value = measure
                              const newSteps = update(i, { ...ingredient, measure: value }, ingredients)
                              setIngredients(newSteps)
                            }}
                            px="8px"
                            py="4px"
                            fontWeight="500"
                            key={measure}
                          >
                            {measure}
                          </Text>
                        )
                      })}
                    </MeasurePopover>
                  ) : null}
                </Box>
                {hasBeenCategorised ? (
                  <Box width="120px" position="relative" ml="16px">
                    <Input
                      cursor="pointer"
                      px="8px"
                      dark
                      readOnly
                      onFocus={() => setShowCategoryPopover(i)}
                      onBlur={() => setTimeout(() => setShowCategoryPopover(-1), 200)}
                      onChange={(e) => {
                        const value = e.target.value
                        const newSteps = update(i, { ...ingredient, category: value }, ingredients)
                        setIngredients(newSteps)
                      }}
                      value={ingredient.category}
                    />
                    {showCategoryPopover === i ? (
                      <CategoryPopover>
                        {categoryOrder.map((category) => {
                          return (
                            <Text
                              onClick={() => {
                                const value = category
                                const newSteps = update(i, { ...ingredient, category: value }, ingredients)
                                setIngredients(newSteps)
                                setShowCategoryPopover(-1)
                              }}
                              px="8px"
                              py="4px"
                              fontWeight="500"
                              key={category}
                            >
                              {category}
                            </Text>
                          )
                        })}
                      </CategoryPopover>
                    ) : null}
                  </Box>
                ) : null}
                <Icon
                  onClick={() => {
                    const newIngredients = ingredients.filter((_, index) => index !== i)
                    setIngredients(newIngredients)
                  }}
                  ml="8px"
                  icon="circle-cross-grey-outline"
                  width="16px"
                  height="16px"
                  cursor="pointer"
                />
              </Flex>
            </form>
          )
        })}
      </ReactSortable>

      {ingredients.length === 0 ? (
        <Flex flexDirection="column" alignItems="center" mt="48px">
          <Text light textAlign="center" mb="8px" lineHeight="23px">
            Enter ingredients in bulk and we'll do the rest
          </Text>
          <Box mb="48px">
            <Button label="Quick add" variant="green" onClick={() => setShowAddIngredient(true)} />
          </Box>
          <Flex justifyContent="center" pb="40px" borderBottom="1px solid rgba(237, 242, 247, 1)" mb="16px">
            <Text
              light
              mr="32px"
              cursor="pointer"
              fontWeight="500"
              onClick={() => setIngredients([...ingredients, { id: uuidv4() }])}
            >
              + Add ingredient
            </Text>
            <Text
              light
              cursor="pointer"
              fontWeight="500"
              onClick={() => setIngredients([...ingredients, { id: uuidv4(), section: '', isSection: true }])}
            >
              + Add section heading
            </Text>
          </Flex>
        </Flex>
      ) : (
        <Flex>
          <Text
            mr="32px"
            light
            cursor="pointer"
            fontWeight="500"
            onClick={() => setIngredients([...ingredients, { id: uuidv4() }])}
          >
            + Add ingredient
          </Text>
          <Text
            mr="32px"
            light
            cursor="pointer"
            fontWeight="500"
            onClick={() => setIngredients([...ingredients, { id: uuidv4(), section: '', isSection: true }])}
          >
            + Add section heading
          </Text>
        </Flex>
      )}
      <AddIngredientsModal
        isOpen={showAddIngredient}
        setIsOpen={setShowAddIngredient}
        setIngredients={setIngredients}
        ingredients={ingredients}
      />
    </>
  )
}

export default Ingredients
