import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { colors } from '../ui/helpers'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from '../ui/Typography'
import { Flex, Box, Container } from '../ui/Layout'
import { Input } from '../ui/Input'
import Icon from '../ui/Icon'
import ModalContainer from '../ui/ModalContainer'
import { difference } from 'ramda'
import { useContent } from '../ContentProvider'
import api from '../api'

const BigInput = styled.input`
  border-radius: 8px;
  width: 100%;
  background: ${(props) => {
    if (props.disabled) {
      return '#EDF2F7'
    }
    return props.dark ? '#EDF2F7' : '#ffffff'
  }};
  box-shadow: 0px 1px 2px 0px rgba(50, 50, 71, 0.08), 0px 0px 1px 0px rgba(50, 50, 71, 0.2);
  height: ${(props) => (props.large ? '40px' : '36px')};
  margin: 0px;
  margin-bottom: 8px;
  border-radius: 6px;
  color: white;
  display: inline-block;
  border: none;
  font-size: ${(props) => (props.large ? '15px' : '14px')};
  padding-left: 24px;
  padding-right: 24px;
  text-decoration: none;
  color: ${colors.textDark};
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  ::-webkit-input-placeholder {
    font-family: 'Inter', sans-serif;
  }
  ::-moz-placeholder {
    font-family: 'Inter', sans-serif;
  }
  :-ms-input-placeholder {
    font-family: 'Inter', sans-serif;
  }
  :-moz-placeholder {
    font-family: 'Inter', sans-serif;
  }
  &&:focus {
    padding-left: 22.5px;
    border: 1.5px solid #7895ff;
    box-shadow: 0px 0px 0px 2px rgba(76, 111, 255, 0.3);
  }
`

const FullWidthForm = styled.form`
  width: 100%;
  position: relative;
`

const RemoveTagModal = ({ id, ids, inputAllTags, isOpen, setIsOpen, refresh, isImages, setSelectedIds }) => {
  const { recipes, mealPlans, posts } = useContent()
  const existingTags = [...recipes, ...mealPlans, ...posts]
    .filter((el) => ids.includes(el.id))
    .map((el) => el.tags)
    .flat()

  const [tagsToRemove, setTagsToRemove] = useState([])
  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    setTagsToRemove([])
  }, [isOpen])

  const save = async () => {
    let res
    if (!tagsToRemove?.length) {
      return
    }
    res = await api.post('/content/update-tags', { ids, tagsToAdd: [], tagsToRemove })
    if (res.data.success) {
      refresh()
      setSelectedIds && setSelectedIds([])
    } else {
      refresh()
    }
  }

  return (
    <ModalContainer p="0px" width="500px" autoHeight height="400px" isOpen={isOpen} setIsOpen={setIsOpen} onClose={save} noClose>
      <Flex flexDirection="column" overflow="hidden" height="100%">
        <Flex flexDirection="column" borderBottom={'1px solid #EDF2F7'} px="16px" pt="24px" pb="8px">
          <Text light fontWeight="600" lineHeight="24px" mb="8px">
            {ids ? `${ids.length} selected` : 'Remove tags'}
          </Text>

          <BigInput placeholder="Remove tag..." value={searchValue} autoFocus onChange={(e) => setSearchValue(e.target.value)} />
        </Flex>
        <Flex flexDirection="column" overflowY="scroll">
          {existingTags?.length ? (
            <Flex flexDirection="column" px="16px" pt="16px" pb="8px">
              {difference(existingTags, tagsToRemove)
                .filter((t) => t?.toLowerCase().includes(searchValue.toLowerCase()))
                .map((l) => (
                  <Flex
                    alignItems="center"
                    bg="#425466"
                    height="28px"
                    mr="8px"
                    mb="8px"
                    borderRadius="14px"
                    px="16px"
                    width="fit-content"
                    key={l}
                    onClick={(e) => {
                      e.stopPropagation()
                      setTagsToRemove(tagsToRemove.concat(l))
                    }}
                    cursor="pointer"
                  >
                    <Text color="white" mb="2px" mr="8px" light fontWeight="500">
                      {l}
                    </Text>
                    <Icon width="14px" height="14px" icon="circle-cross-white" />
                  </Flex>
                ))}
            </Flex>
          ) : null}
        </Flex>
      </Flex>
    </ModalContainer>
  )
}

export default RemoveTagModal
