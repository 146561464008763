import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
import { H1, Text, Error } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Icon from '../ui/Icon.js'
import Button from '../ui/Button.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import { Formik, Field } from 'formik'
import formikFormWrapper from '../formikFormWrapper.js'
import { Input, TextArea } from '../ui/Input.js'
import { ReactSortable } from 'react-sortablejs'
import { v4 as uuidv4 } from 'uuid'
import { useTheme } from '../ThemeProvider.js'
import { colors } from '../ui/helpers.js'
import ToggleRedGreen from '../ui/ToggleTwoOptions.js'
const FormInput = formikFormWrapper(Input)
const FormTextArea = formikFormWrapper(TextArea)

const FAQContainer = styled(Container)`
  width: 738px;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 24px;
`

const FAQItem = styled(Box)`
  margin-bottom: 48px;
`

const GrabIcon = styled(Icon)`
  cursor: grab;
`

const FAQs = () => {
  const { user } = useAuth()
  const { theme, refresh: refreshTheme } = useTheme()

  const type = 'faqs'
  const [isLoading, setIsLoading] = useState(true)
  const [initialFAQs, setInitialFAQs] = useState(null)
  const navigate = useNavigate()
  const refresh = async () => {
    const res = await api.get(`/custom-content/get?organisationId=${user.organisationId}&type=${type}`)
    if (res.data.payload) {
      const faqs = res.data.payload

      console.log(faqs)
      if (faqs.content && Array.isArray(faqs.content)) {
        faqs.content = faqs.content.map((item) => ({
          ...item,
          id: item.id || uuidv4()
        }))
      }
      setInitialFAQs(faqs)
    }
    setIsLoading(false)
  }

  const isPublished = theme.useFaqs
  const functionToPublish = async (publishBoolean) => {
    await api.post('/theme/update-partial', { useFaqs: publishBoolean })
    await refreshTheme()
  }

  const submit = async (values) => {
    const res = await api.post('/custom-content/update', {
      content: values.content.map((item) => ({
        ...item,
        id: item.id || uuidv4(),
        chosen: undefined,
        selected: undefined
      })),
      organisationId: user.organisationId,
      type
    })
    refresh()
    return res
  }

  useEffect(() => {
    refresh()
  }, [])

  if (isLoading) {
    return (
      <Flex height="500px" justifyContent="center" alignItems="center">
        <BigDarkSpinner />
      </Flex>
    )
  }

  return (
    <Flex minHeight="100vh" alignItems="center" flexDirection="column" backgroundColor="#F7FAFC">
      <Formik
        initialValues={initialFAQs || { content: [{ question: '', answer: '', id: uuidv4() }] }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true)
          await submit(values)
          setSubmitting(false)
        }}
      >
        {({ values, handleSubmit, isSubmitting, setFieldValue, dirty, errors }) => {
          console.log(values, '!!!!')
          return (
            <>
              <Flex
                minHeight="65px"
                height="65px"
                width="100%"
                alignItems="center"
                justifyContent="space-between"
                backgroundColor="white"
                borderBottom="2px solid #EEF2F7"
                px="24px"
                top="0px"
                zIndex={100}
              >
                <Link to="/settings">
                  <Flex cursor="pointer" width="auto">
                    <Icon mt="4px" mr="13px" width="16px" height="16px" icon="chevron-left-grey" />
                    <Text lightLight fontSize="14px">
                      Back
                    </Text>
                  </Flex>
                </Link>
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  position="absolute"
                  top="20px"
                  left="calc(50% - 100px)"
                  width="200px"
                >
                  <Box
                    width="8px"
                    height="8px"
                    mr="8px"
                    borderRadius="8px"
                    backgroundColor={isPublished ? 'rgba(102, 203, 159, 1)' : 'rgba(247, 147, 111, 1)'}
                  />
                  <Text light fontWeight="500">
                    {isPublished ? 'Published' : 'Draft'}
                  </Text>
                </Flex>
                <Flex width="auto" alignItems="center">
                  <Button
                    isLoading={isSubmitting}
                    variant={!isPublished ? 'secondary' : 'green'}
                    label={'Save'}
                    onClick={async () => {
                      handleSubmit()
                    }}
                  />
                  <Button
                    ml="16px"
                    isLoading={isSubmitting}
                    variant={isPublished ? 'secondary' : 'green'}
                    label={isPublished ? 'Unpublish' : 'Publish'}
                    onClick={async () => {
                      await handleSubmit()
                      await functionToPublish(isPublished ? false : true)
                    }}
                  />
                </Flex>
              </Flex>
              <FAQContainer>
                <Text fontSize="18px" fontWeight="600" lineHeight="normal" mb="8px">
                  FAQs
                </Text>
                <Text light lineHeight="23px" mb="16px">
                  Provide questions and answers for common questions.
                </Text>

                <ReactSortable
                  list={values.content}
                  setList={(newFAQs) => {
                    setFieldValue('content', newFAQs)
                  }}
                >
                  {values.content.map((faq, index) => (
                    <FAQItem key={faq.id || index}>
                      <Flex>
                        <GrabIcon mr="8px" icon="drag-circles-grey" width="24px" height="24px" alt="drag icon" />
                        <Flex flexDirection="column">
                          <Input
                            mb="16px"
                            large
                            value={faq.question}
                            name={`content.${index}.question`}
                            placeholder="Question..."
                            onChange={(e) => {
                              const newFAQs = [...values.content]
                              newFAQs[index].question = e.target.value
                              setFieldValue('content', newFAQs)
                            }}
                            dark
                          />
                          <TextArea
                            mb="16px"
                            large
                            value={faq.answer}
                            name={`content.${index}.answer`}
                            width="100%"
                            placeholder="Answer"
                            onChange={(e) => {
                              const newFAQs = [...values.content]
                              newFAQs[index].answer = e.target.value
                              setFieldValue('content', newFAQs)
                            }}
                            dark
                          />
                          <Flex mb="8px" alignItems="center" justifyContent="flex-end">
                            <Flex alignItems="center" width="auto">
                              <Text light mr="8px" fontSize="13px" fontWeight="500">
                                Show on homepage
                              </Text>
                              <ToggleRedGreen
                                selected={!!faq.showOnHomepage}
                                customActiveColor={colors.green}
                                value1={false}
                                value2={true}
                                setSelected={(x) => {
                                  const newFAQs = [...values.content]
                                  newFAQs[index].showOnHomepage = x
                                  setFieldValue('content', newFAQs)
                                }}
                              />
                            </Flex>
                            <Icon
                              icon="bin-dark-dark-grey"
                              ml="16px"
                              width="16px"
                              height="16px"
                              cursor="pointer"
                              onClick={() => {
                                const newFAQs = [...values.content]
                                newFAQs.splice(index, 1)
                                setFieldValue('content', newFAQs)
                                handleSubmit()
                              }}
                            />
                          </Flex>
                        </Flex>
                      </Flex>
                    </FAQItem>
                  ))}
                </ReactSortable>

                <Text
                  cursor="pointer"
                  light
                  variant="secondary"
                  fontWeight="500"
                  onClick={() => {
                    setFieldValue('content', [...values.content, { question: '', answer: '', id: uuidv4() }])
                  }}
                >
                  + Add FAQ
                </Text>
              </FAQContainer>
            </>
          )
        }}
      </Formik>
    </Flex>
  )
}

export default FAQs
