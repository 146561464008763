/* eslint-disable no-case-declarations */
import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { colors, formatSeconds } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import Icon from '../ui/Icon.js'
import Button from '../ui/Button.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import ModalContainer from '../ui/ModalContainer.js'
import { uniq, difference, intersection } from 'ramda'
import Tooltip from '../ui/Tooltip.js'
import ReactTooltip from 'react-tooltip'
import Search from './Search.js'
import { useModal } from '../Modal.js'
import PopoverDropdown from '../ui/PopoverDropdown.js'
import { useContent } from '../ContentProvider.js'
import MealPlanCard from './MealPlanCard.js'
import PostCard from './PostCard.js'
import SelectableCard from './SelectableCard.js'
import RemoveTagModal from './RemoveTagModal.js'
import TagsModal from '../ui/TagsModalV2.js'
import ListView from './ListView.js'
import GridView from './GridView.js'

const ImageContainer = styled(Flex)`
  border-radius: 8px 8px 0px 0px;
`

const VideoImage = styled.img`
  width: 100%;
  object-fit: cover;
  height: 100%;
`

const Grid = styled(Flex)`
  display: grid;
  grid-gap: 22px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  @media (min-width: 1800px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  @media (min-width: 2200px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`

const Gradient = styled(Flex)`
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 80%, rgba(0, 0, 0, 0.3) 100%);
`

const GradientReverse = styled(Flex)`
  border-radius: 8px 8px 0px 0px;
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 80%, rgba(0, 0, 0, 0.3) 100%);
`

const ContainerWithHover = styled(Container)`
  box-shadow: none;
  background-color: transparent;
  border: ${(props) => (props.isSelected ? `4px solid rgba(120, 149, 255, 1)` : '4px solid #F5F8FA')};
  a {
    height: 100%;
  }
  // &&:hover {
  //   .hover {
  //     visibility: visible;
  //   }
  // }
  .hover {
    visibility: hidden;
  }
`

export const SelectedContainer = styled(Flex)`
  z-index: 2;
  background-color: ${colors.green};
  bottom: 24px;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 800px;
  position: fixed;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
`

export const EmptyState = ({ user }) => {
  return (
    <Flex pt="24px">
      <Container width="320px" alignItems="center" p="8px" pb="24px">
        <Flex mb="24px" justifyContent="center" alignItems="center" bg={colors.beige} height="187px" borderRadius="16px">
          <Icon width="50px" height="50px" icon="list-check-black" />
        </Flex>

        <H3 textAlign="center" mb="24px">
          Create your first recipe
        </H3>
        <Text light textAlign="center" mb="24px">
          No time like the present - let's get started, {user.firstName}
        </Text>
        <Link to="/recipe">
          <Button width="auto" variant="green" label="Get started" />
        </Link>
      </Container>
    </Flex>
  )
}

const PAGESIZE = 200

export const CONTENT_TYPES = {
  RECIPE: 'RECIPE',
  MEAL_PLAN: 'MEAL_PLAN',
  POST: 'POST',
  BOTH: 'BOTH'
}

const Content = (props) => {
  let { user } = useAuth()
  const { recipes, mealPlans, posts, recipeStats, refresh } = useContent()
  let navigate = useNavigate()

  const [page, setPage] = useState(1)

  const [hasLoaded, setHasLoaded] = useState(false)
  const [searchTerm, setSearchTerm] = useState()
  const [searchParams, setSearchParams] = useSearchParams()
  const [showRemoveTagModal, setShowRemoveTagModal] = useState(false)
  const [showPublished, setShowPublishedState] = useState(
    searchParams.get('showPublished') === 'true' || !searchParams.get('showPublished')
  )
  const [selectedTags, setSelectedTags] = useState([])
  const [contentType, setContentType] = useState(CONTENT_TYPES.BOTH)
  const [selectedIds, setSelectedIds] = useState([])
  const [showTagsModal, setShowTagsModal] = useState(false)
  const inputAllTags = uniq(
    [...recipes, ...mealPlans]
      .map((el) => el.tags)
      .flat()
      .filter((el) => el)
      .sort()
  )
  const [view, setView] = useState(() => {
    const savedView = localStorage.getItem('contentViewStyle')
    return savedView || 'grid'
  })
  const [sortOption, setSortOption] = useState('newest')

  useEffect(() => {
    localStorage.setItem('contentViewStyle', view)
  }, [view])

  const setShowPublished = (showPublished) => {
    setShowPublishedState(showPublished)
    setSearchParams({ showPublished })
  }

  const content = [...recipes, ...mealPlans, ...posts].sort((a, b) =>
    showPublished ? (a.publishedAt > b.publishedAt ? -1 : 1) : a.createdAt > b.createdAt ? -1 : 1
  )

  useEffect(() => {
    const func = async () => {
      await refresh()
      setHasLoaded(true)
    }
    func()
  }, [])

  const contentToShow = content
    .filter((v) => {
      // First, filter by search term
      if (searchTerm) {
        try {
          if (!(v.name && v.name.toLowerCase().includes(searchTerm.toLowerCase()))) {
            return false
          }
        } catch (e) {
          console.log(e)
          return false
        }
      }

      // Then, filter by published status
      if (!(showPublished ? v.published : !v.published)) {
        return false
      }

      if (contentType === CONTENT_TYPES.RECIPE && v.type !== CONTENT_TYPES.RECIPE) {
        return false
      }

      if (contentType === CONTENT_TYPES.MEAL_PLAN && v.type !== CONTENT_TYPES.MEAL_PLAN) {
        return false
      }

      if (contentType === CONTENT_TYPES.POST && v.type !== CONTENT_TYPES.POST) {
        return false
      }

      // Finally, filter by selected tags
      if (selectedTags.length > 0) {
        const contentTags = v.tags || []
        return selectedTags.some((tag) => contentTags.includes(tag))
      }

      return true
    })
    .sort((a, b) => {
      // First handle published/unpublished sorting
      if (a.published && !b.published) {
        return -1
      }
      if (!a.published && b.published) {
        return 1
      }

      let aDate = a.publishedAt || a.sendAt || a.createdAt
      let bDate = b.publishedAt || b.sendAt || b.createdAt
      let aName = (a.name || a.title || '').toLowerCase()
      let bName = (b.name || b.title || '').toLowerCase()

      // Then handle the selected sort option
      switch (sortOption) {
        case 'newest':
          return aDate > bDate ? -1 : 1

        case 'oldest':
          return aDate < bDate ? -1 : 1

        case 'az':
          return aName.localeCompare(bName)

        case 'za':
          return bName.localeCompare(aName)

        case 'saved':
          // Use favouriteCount from recipeStats
          const aStats = a.type === CONTENT_TYPES.RECIPE ? recipeStats[a.id] : null
          const bStats = b.type === CONTENT_TYPES.RECIPE ? recipeStats[b.id] : null
          const aSaves = aStats ? parseInt(aStats.favouriteCount) : 0
          const bSaves = bStats ? parseInt(bStats.favouriteCount) : 0
          return bSaves - aSaves

        case 'planned':
          // Use mealPlanCount from recipeStats
          const aPlanStats = a.type === CONTENT_TYPES.RECIPE ? recipeStats[a.id] : null
          const bPlanStats = b.type === CONTENT_TYPES.RECIPE ? recipeStats[b.id] : null
          const aPlanned = aPlanStats ? parseInt(aPlanStats.mealPlanCount) : 0
          const bPlanned = bPlanStats ? parseInt(bPlanStats.mealPlanCount) : 0
          return bPlanned - aPlanned

        default:
          return 0
      }
    })

  const handleSelect = (idOrIds) => {
    setSelectedIds((prev) => {
      if (Array.isArray(idOrIds)) {
        // Handle select/deselect all
        return idOrIds
      }
      // Handle single selection
      if (prev.includes(idOrIds)) {
        return prev.filter((selectedId) => selectedId !== idOrIds)
      }
      return [...prev, idOrIds]
    })
  }

  const addTags = async (tags) => {
    console.log('addTags', tags)
    let res = await api.post('/content/update-tags', { ids: selectedIds, tagsToAdd: tags, tagsToRemove: [] })
    if (res.data.success) {
      refresh()
      setSelectedIds([])
    }
  }

  return (
    <Flex>
      <Flex position="relative" flexDirection="column" width="100%" p="40px">
        <Flex justifyContent="space-between">
          <H1 mb="32px" width="auto">
            Content
          </H1>

          <Box position="absolute" top="16px" right="16px">
            <PopoverDropdown
              width="170px"
              right
              options={[
                { label: 'Recipe', onClick: () => navigate('/recipe') },
                { label: 'Meal Plan', onClick: () => navigate('/meal-plan') },
                { label: 'Post', onClick: () => navigate('/post/editor') }
              ]}
              renderCustomLauncher={() => (
                <Button
                  renderRightIcon={() => <Icon ml="8px" icon="chevron-down-white" width={10} height={10} />}
                  position="absolute"
                  top="0px"
                  right="0px"
                  label="Create"
                  variant="green"
                />
              )}
            />
          </Box>
        </Flex>
        <Search
          showPublished={showPublished}
          setShowPublished={setShowPublished}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          setPage={setPage}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          contentType={contentType}
          setContentType={setContentType}
          countPublished={content.filter((v) => v.published).length}
          countDraft={content.filter((v) => !v.published).length}
          view={view}
          setView={setView}
          setSortOption={setSortOption}
          sortOption={sortOption}
        />
        {!hasLoaded && !content.length ? (
          <Flex justifyContent="center" pt="200px">
            <BigDarkSpinner />
          </Flex>
        ) : null}
        {hasLoaded ? (
          <>
            {view === 'grid' ? (
              <GridView
                content={contentToShow}
                page={page}
                PAGESIZE={PAGESIZE}
                selectedIds={selectedIds}
                handleSelect={handleSelect}
                user={user}
              />
            ) : (
              <ListView
                content={contentToShow}
                selectedIds={selectedIds}
                handleSelect={handleSelect}
                page={page}
                PAGESIZE={PAGESIZE}
              />
            )}
          </>
        ) : null}

        {contentToShow && contentToShow.length > PAGESIZE ? (
          <Flex mt="32px" mb="100px" justifyContent="space-between">
            <Text fontWeight="500" width="300px" light>
              Showing {(page - 1) * PAGESIZE + 1} -{' '}
              {page * PAGESIZE > contentToShow.length ? contentToShow.length : page * PAGESIZE} of {contentToShow.length}
            </Text>
            <Flex cursor="pointer" justifyContent="center">
              {page - 1 !== 0 ? (
                <Text
                  onClick={() => {
                    setPage(page - 1)
                  }}
                  light
                  fontWeight="500"
                  px="4px"
                >
                  {'<'}
                </Text>
              ) : null}
              {Array.from({ length: Math.ceil(contentToShow.length / PAGESIZE) }, (v, i) => i).map((i) => {
                return (
                  <Text
                    onClick={() => {
                      setPage(i + 1)
                    }}
                    light
                    key={i}
                    fontWeight={i === page - 1 ? '700' : '500'}
                    px="4px"
                  >
                    {i + 1}
                  </Text>
                )
              })}
              {page !== Math.ceil(contentToShow.length / PAGESIZE) ? (
                <Text
                  onClick={() => {
                    setPage(page + 1)
                  }}
                  light
                  fontWeight="500"
                  px="4px"
                >
                  {'>'}
                </Text>
              ) : null}
            </Flex>
            <Box width="300px" />
          </Flex>
        ) : null}
      </Flex>

      {selectedIds.length > 0 && (
        <SelectedContainer>
          <Flex ml="24px">
            <Text fontWeight="500" color="white" mr="24px">
              {`${selectedIds.length} selected`}
            </Text>
          </Flex>
          <Flex alignItems="center" width="auto">
            <Button
              mr="8px"
              fontSize="12px"
              renderLeftIcon={() => <Icon icon="plus-white" width="12px" height="12px" />}
              borderColor="white"
              variant="black"
              label="Add Tag"
              width="117px"
              small
              onClick={() => setShowTagsModal(true)}
              background="transparent"
            />
            <Button
              mr="8px"
              fontSize="12px"
              borderColor="white"
              variant="black"
              label="Remove Tag"
              width="117px"
              small
              onClick={() => setShowRemoveTagModal(true)}
              background="transparent"
            />
            <Text onClick={() => setSelectedIds([])} fontSize="12px" cursor="pointer" mr="16px" fontWeight="500" color="white">
              Cancel
            </Text>
          </Flex>
        </SelectedContainer>
      )}
      <RemoveTagModal
        id={selectedIds[0]}
        ids={selectedIds}
        inputAllTags={inputAllTags}
        isOpen={showRemoveTagModal}
        setIsOpen={setShowRemoveTagModal}
        refresh={refresh}
        setSelectedIds={setSelectedIds}
      />
      <TagsModal
        ids={selectedIds}
        inputTags={[]}
        isOpen={showTagsModal}
        setIsOpen={setShowTagsModal}
        saveFunction={addTags}
        clearTagsOnSave={true}
      />
    </Flex>
  )
}

export default Content
