import React from 'react'
import styled from 'styled-components'
import { Flex, Box } from '../../ui/Layout.js'
import { Text } from '../../ui/Typography.js'
import FeatureSvgs from './featureSvgs.js'

const FeatureImageContainer = styled(Flex)`
  position: relative;
  width: 280px;
  height: 292px;
  min-width: 280px;
  min-height: 292px;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.primaryButtonColor};
  border-radius: 16px;
  overflow: hidden;
  border: 1px solid ${(props) => props.primaryButtonColor};

  svg {
    width: 100%;
    height: 100%;
  }
`

const TextOverlay = styled(Flex)`
  position: absolute;
  bottom: 0px;
  left: 0px;
  flex-direction: column;
  padding: 24px;
  z-index: 1;
`

const FeatureTitle = styled(Text)`
  font-family: ${(props) => props.primaryFontFamily || 'inherit'};
  color: ${(props) => props.primaryButtonFontColor || '#0E380B'};
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 4px;
`

const FeatureSubtitle = styled(Text)`
  font-family: ${(props) => props.secondaryFontFamily || 'inherit'};
  color: ${(props) => props.primaryButtonFontColor || '#0E380B'};
  font-weight: 400;
  font-size: 14px;
  min-height: 38px;
  line-height: 19px;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
`

const FeatureImage = ({ type, title, subtitle, config }) => {
  return (
    <FeatureImageContainer primaryButtonColor={config.primaryButtonColor} backgroundColor={config.backgroundColor}>
      {FeatureSvgs[type](config)}

      <TextOverlay>
        <FeatureTitle primaryButtonFontColor={config.primaryButtonFontColor} primaryFontFamily={config.primaryFontFamily}>
          {title}
        </FeatureTitle>
        {subtitle && (
          <FeatureSubtitle
            primaryButtonFontColor={config.primaryButtonFontColor}
            secondaryFontFamily={config.secondaryFontFamily}
          >
            {subtitle}
          </FeatureSubtitle>
        )}
      </TextOverlay>
    </FeatureImageContainer>
  )
}

export default FeatureImage
