import React, { useState } from 'react'
import styled from 'styled-components'
import { H3, Text } from '../ui/Typography.js'
import { Flex } from '../ui/Layout.js'
import Button from '../ui/Button.js'
import Icon from '../ui/Icon.js'
import PopoverDropdown from '../ui/PopoverDropdown.js'
import ModalContainer from '../ui/ModalContainer.js'
import { colors } from '../ui/helpers.js'
import api from '../api.js'

export const useCustomerActions = (onSuccess) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [selectedSubscriber, setSelectedSubscriber] = useState(null)

  const handleDeleteUser = (subscriber) => {
    setSelectedSubscriber(subscriber)
    setDeleteModalOpen(true)
  }

  const confirmDelete = async () => {
    console.log('selectedSubscriber', selectedSubscriber)
    try {
      await api.post(`/customer/admin-delete`, {
        customerId: selectedSubscriber.id
      })
      onSuccess?.()
    } catch (e) {
      console.error('Error deleting user:', e)
    }
  }

  return {
    deleteModalOpen,
    setDeleteModalOpen,
    selectedSubscriber,
    handleDeleteUser,
    confirmDelete
  }
}

export const DeleteConfirmationModal = ({ isOpen, setIsOpen, onConfirm, subscriberName }) => {
  return (
    <ModalContainer isOpen={isOpen} setIsOpen={setIsOpen} autoHeight width="400px" height="auto" p="24px">
      <Flex bg="white" borderRadius="8px" flexDirection="column" width="100%">
        <H3 mb="16px">Delete User</H3>
        <Text mb="24px" light>
          Are you sure you want to delete {subscriberName}?
        </Text>
        <Text mb="24px" light>
          This action will cancel any active subscriptions and the user will lose access immediately. This cannot be undone.
        </Text>
        <Flex width="100%" justifyContent="flex-end" gap="12px">
          <Button mr="16px" variant="grey" label="Cancel" onClick={() => setIsOpen(false)} width="120px" />
          <Button
            variant="red"
            label="Delete"
            onClick={() => {
              onConfirm()
              setIsOpen(false)
            }}
            width="120px"
          />
        </Flex>
      </Flex>
    </ModalContainer>
  )
}

export const CustomerActionsMenu = ({ subscriber, onDeleteClick }) => {
  return (
    <PopoverDropdown
      width="180px"
      top="16px"
      noHover
      right
      renderCustomLauncher={() => <Icon icon="three-dots-grey" minWidth="16px" width="16px" height="16px" cursor="pointer" />}
      options={[
        ...(subscriber.stripeCustomerId
          ? [
              {
                renderLeftIcon: () => <Icon icon="arrow-right-angle-black" width="14px" height="14px" mx="8px" />,
                label: 'View in Stripe',
                onClick: () => window.open(`https://dashboard.stripe.com/customers/${subscriber.stripeCustomerId}`, '_blank')
              }
            ]
          : []),
        {
          color: colors.red,
          renderLeftIcon: () => <Icon icon="bin-red" width="14px" height="14px" mx="8px" />,
          label: 'Delete User',
          onClick: () => onDeleteClick(subscriber)
        }
      ]}
    />
  )
}
