'use client'

import styled from 'styled-components'
import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import React from 'react'
// import Reactions, { AddReaction } from './Reactions'
import hexToRgba from 'hex-to-rgba'
import { Text } from '../ui/Typography'
import { Box, Flex } from '../ui/Layout'
import Icon from '../ui/Icon'
import Image from '../ui/Image'
import Reactions, { AddReaction } from './Reactions'
import PopoverDropdown from '../ui/PopoverDropdown'
import api from '../api'

dayjs.extend(calendar)

const CommentCircle = styled.div`
  margin-right: 8px;
  width: 30px;
  height: 30px;
  min-width: 30px;
  border-radius: 50%;
  background-color: ${(props) => props.primaryButtonColor};
  color: ${(props) => props.primaryButtonFontColor};
  font-family: ${(props) => props.primaryFontFamily};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  ${(props) =>
    props.chatProfilePicture &&
    `
    background-image: url(${props.chatProfilePicture + '?width=100&height=100'});
    background-size: cover;
    background-position: center;
    background-color: transparent;
  `}

  ${(props) =>
    props.isCreator &&
    props.commentsImageUrl &&
    `
    background-image: url(${props.commentsImageUrl + '?width=100&height=100'});
    background-size: cover;
    background-position: center;
    background-color: transparent;
  `}
`

const ImagePreviewOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`

const PreviewImage = styled.img`
  max-width: 90vw;
  max-height: 90vh;
  object-fit: contain;
`

const PreviewCloseButton = styled(Flex)`
  position: fixed;
  top: 24px;
  right: 24px;
  width: 40px;
  height: 40px;
  border-radius: 45px;
  background-color: rgba(91, 91, 91, 0.4);
  backdrop-filter: blur(0px);
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
`

const EmojiButton = styled.button`
  background: none;
  border: none;
  font-size: 20px;
  padding: 8px;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.2);
  }
`

const LinkText = styled.a`
  color: inherit;
  text-decoration: underline !important;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`

const ReactionPopover = styled(Box)`
  background: white;
  border-radius: 24px;
  padding: 4px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  gap: 4px;
`

const ReplyProfileCircle = styled(CommentCircle)`
  width: 20px;
  height: 20px;
  min-width: 20px;
  font-size: 12px;
  margin-right: -8px;
  border: 1px solid ${(props) => hexToRgba(props.secondaryFontColor, 0.5)};
`

const ReplyProfilesContainer = styled(Flex)`
  margin-right: 8px;
`

const MenuButton = styled(Box)`
  position: absolute;
  top: 0;
  right: -20px;
  width: auto;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s ease;
`

const CommentContainer = styled(Flex)`
  position: relative;
  width: auto;

  &:hover ${MenuButton} {
    opacity: 1;
  }
`

export default function Comment({
  comment,
  config,
  user,
  commentOnClick,
  replyOnClick,
  reactions,
  refresh,
  previousCommentDate,
  addReactionsBelow,
  onDelete
}) {
  const [previewImage, setPreviewImage] = React.useState(null)

  const shouldShowDate = () => {
    if (!previousCommentDate) return true
    const hoursDifference = dayjs(comment.createdAt).diff(dayjs(previousCommentDate), 'hour')
    return hoursDifference >= 6
  }

  const renderTextWithLinks = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g
    const parts = text.split(urlRegex)

    return parts.map((part, i) => {
      if (part.match(urlRegex)) {
        return (
          <LinkText key={i} href={part} target="_blank" rel="noopener noreferrer" onClick={(e) => e.stopPropagation()}>
            {part}
          </LinkText>
        )
      }
      return part
    })
  }

  const renderReplyProfiles = () => {
    if (!comment.firstMessages || comment.firstMessages.length === 0) return null

    return (
      <ReplyProfilesContainer>
        {comment.firstMessages.slice(0, 3).map((message, index) => (
          <ReplyProfileCircle
            key={index}
            chatProfilePicture={message.chatProfilePicture}
            primaryButtonColor={config.primaryButtonColor}
            primaryButtonFontColor={config.primaryButtonFontColor}
            primaryFontFamily={config.primaryFontFamily}
            secondaryFontColor={config.secondaryFontColor}
          >
            {!message.chatProfilePicture && (message.chatName || message.firstName)
              ? (message.chatName || message.firstName)[0].toUpperCase()
              : ''}
          </ReplyProfileCircle>
        ))}
      </ReplyProfilesContainer>
    )
  }

  const renderMenuButton = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 13C9.5 13.8284 8.82843 14.5 8 14.5C7.17157 14.5 6.5 13.8284 6.5 13C6.5 12.1716 7.17157 11.5 8 11.5C8.82843 11.5 9.5 12.1716 9.5 13ZM9.5 8C9.5 8.82843 8.82843 9.5 8 9.5C7.17157 9.5 6.5 8.82843 6.5 8C6.5 7.17157 7.17157 6.5 8 6.5C8.82843 6.5 9.5 7.17157 9.5 8ZM9.5 3C9.5 3.82843 8.82843 4.5 8 4.5C7.17157 4.5 6.5 3.82843 6.5 3C6.5 2.17157 7.17157 1.5 8 1.5C8.82843 1.5 9.5 2.17157 9.5 3Z"
        fill="black"
      />
    </svg>
  )

  return (
    <Flex flexDirection="column" width="auto">
      {comment.parentId !== comment.threadId ? null : shouldShowDate() ? (
        <Text fontSize="13px" mb="16px" textAlign="center">
          {dayjs(comment.createdAt).calendar(null, {
            sameDay: '[Today at] HH:mm',
            lastDay: '[Yesterday at] HH:mm',
            lastWeek: 'D MMMM [at] HH:mm',
            sameElse: 'D MMMM [at] HH:mm'
          })}
        </Text>
      ) : null}
      {comment.imageUrls && comment.imageUrls.length > 0 ? (
        <Flex pl="48px" flexWrap="wrap" width="100%">
          {comment.imageUrls.map((imageUrl, index) => (
            <Flex
              key={index}
              width={['calc(50% - 4px)', 'calc(33.33% - 11px)']}
              maxWidth="158px"
              mr={[index % 2 !== 1 ? '8px' : '0', index % 3 !== 2 ? '8px' : '0']}
              mb="8px"
            >
              <Box width="100%" paddingBottom="100%" position="relative">
                <Image
                  borderRadius="16px"
                  width="100%"
                  height="100%"
                  objectFit="cover"
                  src={imageUrl + '?width=400&height=400'}
                  onClick={() => setPreviewImage(imageUrl)}
                  cursor="pointer"
                  position="absolute"
                  top="0"
                  left="0"
                />
              </Box>
            </Flex>
          ))}
        </Flex>
      ) : null}

      {previewImage && (
        <ImagePreviewOverlay onClick={() => setPreviewImage(null)}>
          <PreviewCloseButton onClick={() => setPreviewImage(null)} mt="env(safe-area-inset-top)">
            <Icon width="14px" height="14px" icon="close-cross-white" />
          </PreviewCloseButton>
          <PreviewImage src={previewImage} onClick={(e) => e.stopPropagation()} alt="Preview" />
        </ImagePreviewOverlay>
      )}

      <CommentContainer mb={commentOnClick ? '24px' : '16px'}>
        {onDelete ? (
          <MenuButton>
            <PopoverDropdown
              noHover
              width="120px"
              top="8px"
              px="0px"
              py="0px"
              right
              renderCustomLauncher={renderMenuButton}
              options={[
                {
                  label: 'Delete',
                  onClick: () => onDelete && onDelete(comment.id)
                }
              ]}
            />
          </MenuButton>
        ) : null}

        <CommentCircle
          isCreator={comment.isCreator}
          commentsImageUrl={config.commentsImageUrl}
          chatProfilePicture={comment.chatProfilePicture}
          backgroundColor={config.backgroundColor}
          primaryButtonColor={config.primaryButtonColor}
          primaryButtonFontColor={config.primaryButtonFontColor}
          primaryFontFamily={config.primaryFontFamily}
        >
          {(() => {
            if (config.commentsImageUrl && comment.isCreator) {
              return ''
            }
            if (comment.chatProfilePicture) {
              return ''
            }
            if (comment.firstName) {
              return comment.firstName[0].toUpperCase()
            }
            if (config.organisationName) {
              return config.organisationName[0].toUpperCase()
            }
            return ''
          })()}
        </CommentCircle>
        <Flex flexDirection="column" position="relative">
          <Reactions
            commentId={comment.id}
            customerId={comment.customerId}
            reactions={reactions}
            refresh={refresh}
            user={user}
            config={config}
            addReactionsBelow={addReactionsBelow}
          />

          <Flex
            flexDirection="column"
            py="12px"
            px="16px"
            borderRadius="16px"
            mb="8px"
            onClick={commentOnClick ? commentOnClick : replyOnClick}
            cursor={commentOnClick || replyOnClick ? 'pointer' : 'default'}
            backgroundColor={'#F5F8FA'}
          >
            <Text fontWeight="600" mb="8px" lineHeight="normal">
              {comment.isCreator
                ? config.commentsName || config.organisationName || ''
                : comment.chatName
                ? comment.chatName
                : comment.firstName
                ? comment.firstName + ' ' + comment.lastName
                : ''}
            </Text>
            <Text light lineHeight="130%" fontSize="14px" wordBreak="break-word" whiteSpace="pre-wrap">
              {renderTextWithLinks(comment.comment)}
            </Text>
          </Flex>
          <Flex alignItems="center" justifyContent="space-between">
            {commentOnClick ? (
              <Flex alignItems="center" cursor={'pointer'} onClick={replyOnClick}>
                <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15 12C15.2974 12 15.5385 11.7761 15.5385 11.5V6.7C15.5385 5.31929 14.3331 4.2 12.8462 4.2L2.29997 4.2L5.90384 0.853554C6.11412 0.658291 6.11412 0.341709 5.90384 0.146446C5.69355 -0.0488157 5.35262 -0.0488157 5.14234 0.146446L0.61926 4.34645C0.408977 4.54171 0.408977 4.85829 0.61926 5.05355L4.92695 9.05355C5.13723 9.24882 5.47817 9.24882 5.68845 9.05355C5.89873 8.85829 5.89873 8.54171 5.68845 8.34645L2.29997 5.2L12.8462 5.2C13.7383 5.2 14.4615 5.87157 14.4615 6.7V11.5C14.4615 11.7761 14.7026 12 15 12Z"
                    fill={config.secondaryFontColor}
                  />
                </svg>
                {comment.replyCount && comment.replyCount > 0 ? (
                  <Flex alignItems="center" width="auto" ml="10px">
                    {renderReplyProfiles()}
                    <Text light fontSize="13px" ml="10px" whiteSpace="nowrap">
                      {comment.replyCount} {comment.replyCount === 1 ? 'reply' : 'replies'}
                    </Text>
                  </Flex>
                ) : (
                  <Text light fontSize="13px" ml="10px">
                    Reply
                  </Text>
                )}
              </Flex>
            ) : (
              <Flex />
            )}
          </Flex>
        </Flex>
      </CommentContainer>
    </Flex>
  )
}
