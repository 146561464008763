import React, { useState } from 'react'
import styled from 'styled-components'
import { Flex, Box } from '../../ui/Layout.js'
import { LineClamp, Text } from '../../ui/Typography.js'
import Button from '../../ui/Button.js'
import ModalContainer from '../../ui/ModalContainer.js'
import Icon from '../../ui/Icon.js'
import { ReactSortable } from 'react-sortablejs'
import ToggleTwoOptions from '../../ui/ToggleTwoOptions.js'
import { colors } from '../../ui/helpers.js'
import { Input, TextArea } from '../../ui/Input.js'
import { useTheme } from '../../ThemeProvider.js'

const TestimonialCard = styled(Flex)`
  height: 60px;
  padding: 16px;
  margin-bottom: 8px;
  cursor: pointer;
  border-radius: 6px;
  box-shadow: 0px 0px 1px 0px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  background-color: white;
  &:hover {
    background-color: ${(props) => (props.isActive ? 'rgba(235, 242, 250, 1)' : '#F7FAFC')};
  }
`

const GrabIcon = styled(Icon)`
  cursor: grab;
`

const FixedFooter = styled(Box)`
  position: sticky;
  bottom: 0;
  background-color: white;
  z-index: 1;
  border-top: 1px solid #e4ecf7;
  height: 64px;
`

export const testimonials = [
  {
    id: 1,
    name: 'John Doe',
    testimonial: "This app has completely transformed how I cook - it's the best tool I've ever used!"
  }
]

const TestimonialsSection = ({ isOpen, setIsOpen, section, sections, setSections, sectionIndex }) => {
  const { theme } = useTheme()

  const [editingTestimonial, setEditingTestimonial] = useState(null)
  const [showEditView, setShowEditView] = useState(false)

  const handleToggle = (id) => {
    const updatedTestimonials = section.testimonials.map((testimonial) =>
      testimonial.id === id ? { ...testimonial, isActive: !testimonial.isActive } : testimonial
    )

    const newSections = [...sections]
    newSections[sectionIndex] = { ...section, testimonials: updatedTestimonials }
    setSections(newSections)
  }

  const handleSave = () => {
    setIsOpen(false)
  }

  const handleTestimonialClick = (testimonial) => {
    setEditingTestimonial(testimonial)
    setShowEditView(true)
  }

  const handleTestimonialUpdate = (field, value) => {
    setEditingTestimonial({
      ...editingTestimonial,
      [field]: value
    })
  }

  const handleBackToList = () => {
    setShowEditView(false)
    setEditingTestimonial(null)
  }

  const handleAddNewTestimonial = () => {
    const newTestimonial = {
      id: Date.now().toString(), // Generate a unique ID
      name: '',
      testimonial: '',
      isActive: true
    }

    setEditingTestimonial(newTestimonial)
    setShowEditView(true)
  }

  const handleDeleteTestimonial = (id) => {
    const updatedTestimonials = section.testimonials.filter((testimonial) => testimonial.id !== id)

    const newSections = [...sections]
    newSections[sectionIndex] = { ...section, testimonials: updatedTestimonials }
    setSections(newSections)
  }

  const saveTestimonialEdit = () => {
    const updatedTestimonials = [...(section.testimonials || [])]

    // Check if we're editing an existing testimonial or adding a new one
    const existingIndex = updatedTestimonials.findIndex((t) => t.id === editingTestimonial.id)

    if (existingIndex >= 0) {
      // Update existing testimonial
      updatedTestimonials[existingIndex] = editingTestimonial
    } else {
      // Add new testimonial
      updatedTestimonials.push(editingTestimonial)
    }

    const newSections = [...sections]
    newSections[sectionIndex] = { ...section, testimonials: updatedTestimonials }
    setSections(newSections)

    setShowEditView(false)
    setEditingTestimonial(null)
  }

  return (
    <ModalContainer width="640px" autoHeight isOpen={isOpen} setIsOpen={setIsOpen} p="0px" interiorClose>
      {!showEditView ? (
        <Flex flexDirection="column" width="100%" maxHeight="600px" height="600px">
          <Box position="sticky" top="0" bg="white" zIndex="1" borderBottom="1px solid #E4ECF7" minHeight="64px" height="64px">
            <Flex alignItems="center" height="100%">
              <Text fontSize="18px" fontWeight="600" px="24px" lineHeight="normal">
                Testimonials
              </Text>
            </Flex>
          </Box>

          <Flex flexDirection="column" px="24px" overflow="auto" pt="16px" height="calc(100% - 64px - 63px)">
            <ReactSortable
              list={section && section.testimonials ? section.testimonials : []}
              setList={(newTestimonials) => {
                const newSections = [...sections]
                newSections[sectionIndex] = { ...section, testimonials: newTestimonials }
                setSections(newSections)
              }}
              animation={200}
            >
              {section &&
                section.testimonials &&
                section.testimonials.map((testimonial) => {
                  return (
                    <TestimonialCard
                      key={testimonial.id}
                      alignItems="center"
                      justifyContent="space-between"
                      onClick={() => handleTestimonialClick(testimonial)}
                    >
                      <Flex alignItems="center" flex={1}>
                        <GrabIcon mr="16px" icon="drag-circles-grey" width="24px" height="24px" alt="drag icon" />
                        <Flex flexDirection="column" flex={1}>
                          <LineClamp lines={1} fontSize="13px" fontWeight="600">
                            {testimonial.testimonial}
                          </LineClamp>
                        </Flex>
                      </Flex>
                      <Flex alignItems="center" width="auto" ml="32px">
                        <Icon
                          icon="bin-dark-dark-grey"
                          width="16px"
                          height="16px"
                          onClick={(e) => {
                            e.stopPropagation()
                            handleDeleteTestimonial(testimonial.id)
                          }}
                          cursor="pointer"
                        />
                        <Icon icon="chevron-right-black" width="16px" height="16px" ml="16px" />
                      </Flex>
                    </TestimonialCard>
                  )
                })}
            </ReactSortable>

            <Text
              light
              color={colors.textLight}
              onClick={handleAddNewTestimonial}
              cursor="pointer"
              fontWeight="600"
              mt="8px"
              mb="24px"
            >
              + Add a new testimonial
            </Text>
          </Flex>

          <FixedFooter>
            <Flex justifyContent="flex-end" height="100%" alignItems="center" px="24px">
              <Button width="100%" height="46px" borderRadius="8px" variant="green" label="Done" onClick={handleSave} />
            </Flex>
          </FixedFooter>
        </Flex>
      ) : (
        <Flex flexDirection="column" width="100%" maxHeight="600px" height="600px">
          <Box position="sticky" top="0" bg="white" zIndex="1" borderBottom="1px solid #E4ECF7" minHeight="64px" height="64px">
            <Flex alignItems="center" height="100%">
              <Icon
                icon="chevron-left-black"
                width="16px"
                height="16px"
                ml="24px"
                mr="16px"
                cursor="pointer"
                onClick={handleBackToList}
              />
              <Text fontSize="18px" fontWeight="600" lineHeight="normal">
                Edit Testimonial
              </Text>
            </Flex>
          </Box>

          <Flex flexDirection="column" p="24px" height="calc(100% - 64px - 63px)">
            <Input
              dark
              label="Name"
              placeholder="Customer name"
              value={editingTestimonial ? editingTestimonial.name : ''}
              onChange={(e) => handleTestimonialUpdate('name', e.target.value)}
              mb="16px"
            />
            <TextArea
              dark
              label="Testimonial"
              placeholder="Customer testimonial"
              value={editingTestimonial ? editingTestimonial.testimonial : ''}
              onChange={(e) => handleTestimonialUpdate('testimonial', e.target.value)}
              mb="16px"
              rows={5}
            />
          </Flex>

          <FixedFooter>
            <Flex justifyContent="flex-end" height="100%" alignItems="center" px="24px">
              <Button width="100%" height="46px" borderRadius="8px" variant="green" label="Done" onClick={saveTestimonialEdit} />
            </Flex>
          </FixedFooter>
        </Flex>
      )}
    </ModalContainer>
  )
}

export default TestimonialsSection
