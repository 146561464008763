import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Flex } from '../ui/Layout.js'
import { LineClamp, Text } from '../ui/Typography.js'
import Image from '../ui/Image.js'
import ProductPicker from '../Products/ProductPicker.js'
import { useAuth } from '../Auth.js'
import api from '../api.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import dayjs from 'dayjs'
import { ReactSortable } from 'react-sortablejs'
import Icon from '../ui/Icon.js'

const ProductDisplay = styled(Flex)`
  margin-bottom: 8px;
  cursor: pointer;
  border-radius: 6px;
  box-shadow: 0px 0px 1px 0px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  &:hover {
    background-color: rgba(235, 242, 250, 1);
  }
`

const GrabIcon = styled(Icon)`
  cursor: grab;
`

const ProductSection = ({ section, sections, setSections, sectionIndex }) => {
  const { user } = useAuth()
  const [isOpen, setIsOpen] = useState(false)
  const [products, setProducts] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    fetchProducts()
  }, [isOpen])

  const fetchProducts = async () => {
    setIsLoading(true)
    try {
      const res = await api.get(`/products/get?organisationId=${user.organisationId}`)
      setProducts(res.data.payload?.sort((a, b) => dayjs(b.created_at).diff(dayjs(a.created_at))))
    } catch (e) {
      console.log(e)
    }
    setIsLoading(false)
  }

  const selectedProducts =
    section?.productIds && products?.length > 0
      ? section.productIds.map((id) => products.find((p) => Number(p.id) === Number(id))).filter((p) => p)
      : []

  const save = (selectedProducts) => {
    const newSections = sections.map((s, i) => (i === sectionIndex ? { ...s, productIds: selectedProducts.map((p) => p.id) } : s))
    setSections(newSections)
  }

  return (
    <>
      {isLoading ? (
        <Flex alignItems="center" justifyContent="center" height="100%">
          <BigDarkSpinner />
        </Flex>
      ) : (
        <>
          {selectedProducts.length > 0 && (
            <Flex flexDirection="column" mb="16px">
              <ReactSortable
                list={selectedProducts || []}
                setList={(newProducts) => {
                  const newSections = sections.map((s, i) =>
                    i === sectionIndex ? { ...s, productIds: newProducts.map((p) => p.id) } : s
                  )
                  setSections(newSections)
                }}
              >
                {selectedProducts.map((product) => (
                  <ProductDisplay onClick={() => setIsOpen(true)} key={product?.id} p="8px" alignItems="center">
                    <GrabIcon mr="8px" icon="drag-circles-grey" width="24px" height="24px" alt="drag icon" />
                    <Image src={product?.imageUrl} width="48px" height="48px" objectFit="cover" borderRadius="4px" mr="12px" />
                    <Flex flexDirection="column">
                      <LineClamp lines={1} light fontWeight="600" fontSize="13px" lineHeight="20px">
                        {product?.name}
                      </LineClamp>
                    </Flex>
                  </ProductDisplay>
                ))}
              </ReactSortable>
            </Flex>
          )}
          <Text cursor="pointer" light onClick={() => setIsOpen(true)}>
            + Select Products
          </Text>
          <ProductPicker
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            selectedProducts={selectedProducts}
            saveFunction={save}
            products={products}
          />
        </>
      )}
    </>
  )
}

export default ProductSection
